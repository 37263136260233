
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "src/app/common/providers/auth.service";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./logout-confirmation.html",
  styleUrls: ["./logout-confirmation.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LogoutConfirmationComponent implements OnInit, OnDestroy {
  hasPendingMessages = false;
  logoImage: SafeResourceUrl;
  currentTheme: string = "default";

  constructor(private mailService: MailService,
              private auth: AuthService,
              private translate: TranslateService,
              private domSanitizer: DomSanitizer,
              private dialog: MatDialogRef<LogoutConfirmationComponent>
              ) {
    const currentName = localStorage.getItem("theme") || environment.theme;
    if (currentName === "dfb") {
      this.currentTheme = "dfb";
    } else {
      this.currentTheme = "default";
    }
    this.logoImage = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtils.getFullUrl("/assets/icon/logout-moblie.png"));
    const pendingMessages = localStorage.getItem("pendingMessages");
    if (pendingMessages) {
      const messages = JSON.parse(pendingMessages);
      if (messages.length > 0) {
        this.hasPendingMessages = true;
      }
    }

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  logout() {
    if (this.mailService.showOfflineMessage()) {
      return;
    }

    this.auth.logoutWebAndApp();
    this.dialog.close();
  }

  cancel(): void {
    this.dialog.close();
  }

}
