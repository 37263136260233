
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="edit-appointment">
        <div class="desktop" *ngIf="!isMobileView">
                <div class="header">
                    <div></div>
                    <div class="center">
                        <span *ngIf="data.isNewAppointment">{{ 'CALENDARS.NEW_APPOINTMENT_LBL' | translate }}</span>
                        <span *ngIf="!data.isNewAppointment && !data.isNewAppointment && !isForwardAppointment">{{ 'CALENDARS.EDIT_APPOINTMENT_LBL' | translate }}</span>
                        <span *ngIf="!data.isNewAppointment && isForwardAppointment">{{ 'CALENDARS.FORWARD_APPOINTMENT_LBL' | translate }}</span>
                    </div>
                    <div class="right">
                        <button mat-icon-button aria-label="icon-button" onclick="this.blur()" (click)="isRequestResponsesEnabled()" [matMenuTriggerFor]="desktopMoreMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <button mat-icon-button aria-label="icon-button" onclick="this.blur()" (click)="closeAppointmentDialog()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-menu #desktopMoreMenu="matMenu" class="cal-dropdown-menu more-menu-item">
                            <div mat-menu-item (click)="$event.stopPropagation();">
                                <mat-checkbox [(ngModel)]="isRsvp" [disabled]="rsvpDisabled">{{ 'REQUEST_RESPONSES' | translate }}</mat-checkbox>
                            </div>
                        </mat-menu>
                    </div>
                </div>
                <div class="content">
                    <div class="element">
                        <div class="lbl">
                            <mat-icon>title</mat-icon>
                        </div>
                        <div class="item">
                            <mat-form-field class="title-field">
                                <input matInput placeholder="{{ 'CALENDARS.ADD_TITLE_LBL' | translate }}"
                                    [style.font-size.px]="'24'" [(ngModel)]="appointment.name" [disabled]="isProposeNewTime" (input)="valueChange()">
                            </mat-form-field>
                            <span class="title-access-time-location">
                                <mat-icon>access_time</mat-icon>
                                <mat-icon>room</mat-icon>
                            </span>
                        </div>
                    </div>
                    <div class="element" *ngIf="isForwardAppointment || isProposeNewTime">
                        <div class="lbl">
                            <mat-icon>group</mat-icon>
                        </div>
                        <div class="item">
                            <div class="auto-complete-div">
                                <vp-autocomplete [libraryChips]="true" [place]="toLBL" [isFocused]=false #toAttendeeAutoComplete>
                                </vp-autocomplete>
                            </div>
                            <div class="button-align">
                                <button mat-icon-button class="add-icon-btn" onclick="this.blur()" (click)="openDesktopToAttendeeDialog()">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="lable-new-item">
                        <div class="first-div"></div>
                        <div class="left">{{ 'CALENDARS.APPOINTMENT_STARTS_LBL' | translate }}:</div>
                        <div class="right" *ngIf="isShowAppointmentTimeZone">{{ 'CALENDARS.TIME_ZONE_LBL' | translate }}:</div>
                    </div>
                    <div class="element bottom-10">
                        <div class="lbl">
                                <mat-icon>access_time</mat-icon>
                        </div>
                        <div class="item margin-bottom-class date-picker-div">
                            <div class="left-div">
                                <div>
                                    <button id="apptComposeStartDateButton" class="cal-button-arrow-drop-down appt-btn"
                                        (click)="startDate.open();valueChange();" [disabled]="isForwardAppointment">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="startDate"
                                            [formControl]="startDateFormControl"
                                            (dateChange)="handleStartDateChanges($event)"/>
                                        <span
                                        class="cal-mat-date-picker-span" *ngIf="browserLang === 'en'">{{ startDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <span class="cal-mat-date-picker-span" *ngIf="browserLang === 'de'">{{ startDateFormControl.value | date: "dd.LL.yyyy": "": "de" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #startDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                                <div *ngIf="!appointment.allDay">
                                    <div class="cal-compose-row-content" fxLayout="row" fxLayoutAlign="center center"
                                        fxFlex="140px">
                                        <div class="appt-time-input-block" [ngClass]="{ 'invalid-time' : !validStartTime }">
                                         <input id="apptComposeStartTimeButton" class="appt-time-input"
                                          type="text"
                                          [ngClass]="{'is-warning-date': isPastDate}"
                                          [readOnly]="isForwardAppointment"
                                          [value]="browserLang === 'en' ? (startTimeControl | date: 'hh:mm a':'': browserLang) : (startTimeControl | date: 'HH:mm':'': browserLang)"
                                          [placeholder]="browserLang === 'en' ? 'HH:MM a' : 'HH:MM'"
                                          (input)="onChangeTime($event.target.value, 'start')">
                                         <mat-icon class="arrow-down" [matMenuTriggerFor]="startTimePointMenu" (menuOpened)="triggeredMenuControlFocus(true)" (menuClosed)="triggeredMenuControlFocus(false)">arrow_drop_down</mat-icon>
                                       </div>
                                    </div>
                                </div>
                                <div *ngIf="isShowAppointmentTimeZone" class="margin-left-15">
                                    <button [matMenuTriggerFor]="startTimeZoneMenu" class="appt-btn" [disabled]="isForwardAppointment">
                                        <span>{{startTimeZone.value}}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lable-new-item">
                        <div class="first-div"></div>
                        <div class="left">{{ 'CALENDARS.APPOINTMENT_ENDS_LBL' | translate }}:</div>
                        <div class="right" *ngIf="isShowAppointmentTimeZone">{{ 'CALENDARS.TIME_ZONE_LBL' | translate }}:</div>
                    </div>
                    <div class="element bottom-10">
                        <div class="lbl">
                        </div>
                        <div class="item margin-bottom-class date-picker-div">
                            <div class="left-div">
                                <div>
                                    <button id="apptComposeEndDateButton" class="cal-button-arrow-drop-down appt-btn"
                                        (click)="endDate.open();valueChange();" [disabled]="isForwardAppointment">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="endDate"
                                            [formControl]="endDateFormControl" (dateChange)="handleEndDateChanges($event)" />
                                        <span
                                        class="cal-mat-date-picker-span" *ngIf="browserLang === 'en'">{{ endDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <span
                                        class="cal-mat-date-picker-span" *ngIf="browserLang === 'de'">{{ endDateFormControl.value | date: "dd.LL.yyyy": "": "de" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #endDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                                <div *ngIf="!appointment.allDay">
                                    <div class="cal-compose-row-content" fxLayout="row" fxLayoutAlign="center center"
                                        fxFlex="140px">

                                        <div class="appt-time-input-block" [ngClass]="{ 'invalid-time' : !validEndTime }">
                                            <input id="apptComposeStartTimeButton" class="appt-time-input"
                                             type="text"
                                             [ngClass]="{'is-warning-date': isPastDate}"
                                             [readOnly]="isForwardAppointment"
                                             [value]="browserLang === 'en' ? (endTimeControl | date: 'hh:mm a':'': browserLang) : (endTimeControl | date: 'HH:mm':'': browserLang)"
                                             [placeholder]="browserLang === 'en' ? 'HH:MM a' : 'HH:MM'"
                                             (input)="onChangeTime($event.target.value, 'end')">
                                            <mat-icon class="arrow-down" [matMenuTriggerFor]="endTimePointMenu" (menuOpened)="triggeredMenuControlFocus(true)" (menuClosed)="triggeredMenuControlFocus(false)">arrow_drop_down</mat-icon>
                                          </div>
                                    </div>
                                </div>
                                <div *ngIf="isShowAppointmentTimeZone" class="margin-left-15">
                                    <button [matMenuTriggerFor]="endTimeZoneMenu" class="appt-btn" [disabled]="isForwardAppointment">
                                        <span>{{endTimeZone.value}}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                    </button>
                                </div>
                                <div class="margin-left-15">
                                    <mat-checkbox [ngModel]="appointment.allDay" (change)="changeAllDay($event)" [disabled]="isForwardAppointment">
                                        {{ 'CALENDARS.ALL_DAY_TITLE' | translate }}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="element past-date" *ngIf="isPastDate">
                        <div class="lbl">
                        </div>
                        <div class="item">
                                {{ 'CALENDARS.APPOINTMENT_PAST_DATE_WARNING_MSG' | translate }}
                        </div>
                    </div>

                    <div class="element past-date" *ngIf="appointment.neverSent">
                        <div class="lbl">
                        </div>
                        <div class="item">
                                {{ 'CALENDARS.NEVER_SENT_INVITATION' | translate }}
                        </div>
                    </div>
                    <ng-container *ngIf="!isVNCTalkCall">
                    <div class="lable-new-item">
                        <div class="first-div"></div>
                        <div class="left notifyme">{{ 'CALENDARS.REPEAT_LBL' | translate }}:</div>
                        <div class="right">{{ 'CALENDARS.NOTIFY_ME_LBL' | translate }}:</div>
                    </div>
                    <div class="element bottom-10">
                        <div class="lbl">
                            <mat-icon>refresh</mat-icon>
                        </div>
                        <div class="item">
                            <div class="left-div repeat-reminder-desktop">
                                <div>
                                    <button [matMenuTriggerFor]="repeatMenu" class="appt-btn" [disabled]="data.disableRepeat" [disabled]="isForwardAppointment || isProposeNewTime || disableRepeat">
                                        <span *ngIf="appointment.recur === 'NON'">{{ 'CALENDARS.DOES_NOT_REPEAT' | translate }}</span>
                                        <span *ngIf="appointment.recur === 'DAI'">{{ 'CALENDARS.EVERY_DAY' | translate }}</span>
                                        <span *ngIf="appointment.recur === 'WEE'">{{ 'CALENDARS.EVERY_WEEK' | translate }}</span>
                                        <span *ngIf="appointment.recur === 'MON'">{{ 'CALENDARS.EVERY_MONTH' | translate }}</span>
                                        <span *ngIf="appointment.recur === 'YEA'">{{ 'CALENDARS.EVERY_YEAR' | translate }}</span>
                                        <span *ngIf="appointment.recur === 'CUSTOM'">{{ 'CALENDARS.CUSTOM_LBL' | translate }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                    </button>
                                </div>
                                <div class="margin-left-110">
                                    <mat-icon style="color: #888888;">alarm</mat-icon>
                                </div>
                                <div class="margin-left-15">
                                    <button class="appt-btn" [matMenuTriggerFor]="reminderList" [disabled]="isForwardAppointment || isProposeNewTime">
                                        <span>{{alarmReminderOption?.text}}
                                            {{ "CALENDARS." + alarmReminderOption?.group | translate }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                    </button>
                                </div>
                                <div class="margin-left-15 font-colored-text configure" (click)="openConfigureReminder()">
                                    {{ 'CALENDARS.CONFIGURE_LBL' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="element" *ngIf="appointment.recur !== 'NON' && appointment.recur !== 'CUSTOM'">
                            <div class="lbl">

                            </div>
                            <div class="item">
                                <span class="blurb-data" (click)="openCustomRepeatDialog()">{{ 'PREFERENCES.CUSTOMIZE_LBL' | translate }}</span>
                            </div>
                    </div>
                    <div class="element" *ngIf="recurringData !== '' && recurringData !== undefined">
                        <div class="lbl">

                        </div>
                        <div class="item">
                            <span class="blurb-data" (click)="openCustomRepeatDialog()">{{blurb}}</span>
                        </div>
                    </div>
                    </ng-container>
                    <div *ngIf="!isVNCTalkCall" class="lable-new-item">
                        <div class="first-div"></div>
                        <div class="left">{{ 'CALENDARS.PROJECT_LBL' | translate }}:</div>
                        <div class="right"></div>
                    </div>
                    <div *ngIf="!isVNCTalkCall" class="element">
                        <div class="lbl" style="align-items: flex-start;">
                            <mat-icon>work</mat-icon>
                        </div>
                        <div class="item margin-bottom-class">
                            <div class="margin-right-15">
                                <button [matMenuTriggerFor]="freeBusyMenu" class="appt-btn" [disabled]="isForwardAppointment || isProposeNewTime">
                                    <span [class]="'cal-display-bg cal-color-' + appointment?.fb"></span>
                                    <span *ngIf="appointment?.fb === 'O'">{{'CALENDARS.OUT_OF_OFFICE' | translate }}</span>
                                    <span *ngIf="appointment?.fb === 'T'">{{'CALENDARS.TENTATIVE' | translate }}</span>
                                    <span *ngIf="appointment?.fb === 'B'">{{'CALENDARS.BUSY' | translate }}</span>
                                    <span *ngIf="appointment?.fb === 'F'">{{'CALENDARS.FREE' | translate }}</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                            </div>
                            <div class="margin-right-15">
                                <button [matMenuTriggerFor]="folderList" class="appt-btn" [disabled]="isForwardAppointment || isProposeNewTime">
                                    <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-blank"
                                        [style.color]="getFolderColorById(appointment?.ciFolder)"></mat-icon>
                                    <span>{{mailService?.getFolderNameKey(getFolderNameById(appointment?.ciFolder), CalendarConstants?.SYSTEM_FOLDERS, true)}}</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                            </div>
                            <div>
                                <mat-checkbox [ngModel]="appointment.class === 'PRI' ? true : false"
                                    (change)="changeClass($event)" [disabled]="isForwardAppointment || isProposeNewTime">{{ 'CALENDARS.PRIVATE_LBL' | translate }}</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="element" *ngIf="!!prefCalendarreminderEmail && prefCalendarreminderEmail !== ''">
                        <div class="lbl" style="align-items: flex-start;">
                            <mat-icon></mat-icon>
                        </div>
                        <div class="item margin-bottom-class">
                            <div class="margin-right-15">
                                <mat-checkbox [(ngModel)]="isPrefCalendarReminderEmail">{{'EMAIL_LBL' | translate }} ({{prefCalendarreminderEmail}})</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="element">
                        <div class="lbl">
                            <mat-icon>group</mat-icon>
                        </div>
                        <div class="item">
                            <div class="auto-complete-div">
                                <vp-autocomplete [libraryChips]="true" formId="attendeeAutoComplete" [otherEmails]="optionalAutoComplete?.getSelectedEmail()"  (added)="onAddAttendee($event)"  (removed)="onRemoveAttendee($event)"  [place]="attendeeLbl" [isFocused]=false #attendeeAutoComplete>
                                </vp-autocomplete>
                            </div>
                            <div class="button-align">
                                <button mat-icon-button class="add-icon-btn" onclick="this.blur()" (click)="openDesktopAttendeeDialog()">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="element">
                        <div class="lbl">
                            <mat-icon>group_add</mat-icon>
                        </div>
                        <div class="item">
                            <div class="auto-complete-div">
                                <vp-autocomplete [libraryChips]="true" formId="optionalAutoComplete" [otherEmails]="attendeeAutoComplete?.getSelectedEmail()"  (added)="onAddAttendee($event)"  (removed)="onRemoveAttendee($event)"  [isFocused]=false [place]="optionalAttendeeslbl" #optionalAutoComplete>
                                </vp-autocomplete>
                                <div *ngIf="isConflicted" class="conflict-error">{{ 'ATTENDEE_CONFLICT' | translate }}</div>
                            </div>
                            <div class="button-align">
                                <button mat-icon-button class="add-icon-btn" onclick="this.blur()" (click)="openDesktopOptionalAttendeeDialog()">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="element">
                        <div class="item">
                               <!-- VNCTALK MEETING -->
                            <div class="vnctalk-call-wrapper" *ngIf="isOnline">
                              <div class="vnctalk-call-section without-margin">
                                <div class="vnctalk-label">{{ 'VNCTALK_CALL' | translate }}</div>
                                <div></div>
                                <div class="vnctalk-toggle">
                                  <vnc-toggle-switch size="s" [class.disabled]="meetingJid" (changeEvent)="isVNCTalkCall = $event.checked" [checked]="isVNCTalkCall"></vnc-toggle-switch>
                                </div>
                              </div>
                              <div class="input-fields">
                                <vnc-input *ngIf="isVNCTalkCall && meetingJid" type="icon-filled"
                                  (trailingIconClicked)="copyCallURL()" [trailingIconTemplate]="copyIconTemplate"
                                  [form]="urlControl" label="{{ 'URL' | translate }}"
                                  placeholder="{{ 'URL' | translate }}" [showLabel]="true"></vnc-input>
                                <vnc-input *ngIf="isVNCTalkCall" type="icon-filled"
                                  (trailingIconClicked)="passwordControl.patchValue('')"
                                  [trailingIconTemplate]="passwordControl.value ? trailingIconTemplate : null"
                                  [form]="passwordControl" label="{{ 'COMMON.PASSWORD' | translate }}"
                                  placeholder="{{ 'COMMON.PASSWORD' | translate }}" [showLabel]="true"></vnc-input>
                              </div>

                          </div>
                          <!-- END VNCTALK MEETING -->
                          <ng-template #copyIconTemplate>

                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19 5c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2zm0 2H8v14h11V7zm-4.064-5a1 1 0 0 1 .117 1.993L14.936 4H7.333c-1.235 0-2.246.96-2.328 2.174L5 6.334v11.683a1 1 0 0 1-1.993.116L3 18.017V6.333a4.333 4.333 0 0 1 4.123-4.328L7.333 2h7.603z" fill="#8B96A0" fill-rule="evenodd"/>
                            </svg>

                          </ng-template>
                          <ng-template #trailingIconTemplate>
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
                                </g>
                            </svg>
                          </ng-template>
                        </div>
                    </div>

                    <div *ngIf="!isVNCTalkCall" class="element">
                        <div class="lbl">
                            <mat-icon>today</mat-icon>
                        </div>
                        <div class="item center-alignment">
                            <div class="margin-right-15 gray-color">
                                {{ 'SUGGESTED_TIMES' | translate }}
                            </div>
                            <div class="margin-right-15">
                                <div class="suggest-time font-colored-text upload-div" *ngIf="!showTimeSuggestion" (click)="showSuggestion();showTimeSuggestion=(isOnline ? true : showTimeSuggestion)">{{ 'SUGGEST_A_TIME' | translate }}</div>
                                <div class="suggest-time font-colored-text upload-div" *ngIf="showTimeSuggestion" (click)="showTimeSuggestion=false">{{ 'HIDE_SUGGESTION' | translate }}</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isVNCTalkCall" class="element" [style.display]="showTimeSuggestion ? 'flex' : 'none'">
                        <div class="lbl">
                        </div>
                        <div class="item">
                            <vp-schedule-assistant
                            [requiredAttendees]="requiredAttendees"
                            [currentUser]="currentUser" [timezone]="getTimeZoneValue(startTimeZone.key)"
                            [isAllDay]="appointment.allDay"
                            [browserLang]="browserLang"
                            (selectedSuggested)="selectSuggestedTime($event)"
                            (changeLocation)="changeLocation($event)"
                            (calendarTimeChanged)="calendarTimeChangedOnDatePicker($event)"></vp-schedule-assistant>
                        </div>
                    </div>

                    <div *ngIf="!isVNCTalkCall" class="element">
                        <div class="lbl">
                            <mat-icon>room</mat-icon>
                        </div>
                        <div class="item">
                            <div class="auto-complete-div">
                                <vp-calendar-equipment-autocomplete id="locationAutoComplete" (added)="onAddAttendee($event, 'LOCATION')" (removed)="onRemoveAttendee($event)" type="Location" #locationAutoComplete place="{{ 'LOCATION' | translate }}"></vp-calendar-equipment-autocomplete>
                                <div class="suggest-location font-colored-text upload-div" (click)="openLocationSuggestion()">{{ 'CALENDARS.SUGGEST_LOCATION' | translate }}</div>
                            </div>
                            <div class="button-align" *ngIf="iszimbraFeatureGalEnabled">
                                <button mat-icon-button class="add-icon-btn" (click)="openLocationDialog()" [ngStyle]="{ display: !hideResourceCalendar ? 'block' : 'none' }">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isVNCTalkCall" class="element">
                        <div class="lbl">
                        </div>
                        <div class="item">
                            <div *ngIf="locationConflict" class="conflict-error">{{ 'LOCATION_CURRENT_CONFLICTS' | translate }}</div>
                        </div>
                    </div>

                    <div class="element" *ngIf="iszimbraFeatureGalEnabled" [ngStyle]="{ display: !hideResourceCalendar ? 'flex' : 'none' }">
                        <div class="lbl">
                            <mat-icon>tv</mat-icon>
                        </div>
                        <div class="item">
                            <div class="width-100">
                                <vp-calendar-equipment-autocomplete (added)="onAddAttendee($event, 'EQUIPMENT')" (removed)="onRemoveAttendee($event)" type="Equipment" #equipmentAutoComplete [place]="equipmentlbl"></vp-calendar-equipment-autocomplete>
                            </div>
                            <div class="button-align" *ngIf="iszimbraFeatureGalEnabled">
                                <button mat-icon-button class="add-icon-btn" (click)="openEquipmentDialog()" [disabled]="isProposeNewTime">
                                    <mat-icon [disabled]="isProposeNewTime">add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Attendee status-->
                    <div class="element" *ngIf="appointment.at && appointment.at.length > 0">
                        <div class="lbl ateendee-display-lbl">
                            <mat-icon>group</mat-icon>
                        </div>
                        <div class="item attendee-display">
                            <div *ngFor="let attendee of appointment.at" class="attendee-display-item">
                                <div *ngIf="appointment?.or.a !== attendee.a" class="user-avatar-attendee">
                                    <div class="avatar-attendee">
                                        <vp-avatar [type]="'mail-chip-avatar'" [user]="{firstLastCharacters: attendee.a.charAt(0), color: '#317bbc', email: attendee.a}"></vp-avatar>
                                    </div>
                                    <div class="avatar-email">
                                            {{attendee.a}}
                                    </div>
                                    <div class="attendee-status">
                                        <span *ngIf="attendee.ptst && attendee.ptst === 'NE'">
                                            <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-alert"></mat-icon>
                                            ({{ 'CALENDARS.NEEDS_ACTIONS' | translate }})
                                        </span>
                                        <span *ngIf="attendee.ptst && attendee.ptst === 'AC'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-check"></mat-icon> ({{ 'CALENDARS.ACCEPT_LBL' | translate }})</span>
                                        <span *ngIf="attendee.ptst && attendee.ptst === 'DE'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-remove"></mat-icon> ({{ 'CALENDARS.DECLINED_LBL' | translate }})</span>
                                        <span *ngIf="attendee.ptst && attendee.ptst === 'TE'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-question"></mat-icon>({{ 'CALENDARS.TENTETIVE_LBL' | translate }})</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isVNCTalkCall" class="element upload-margin">
                        <div class="lbl">
                            <mat-icon>attach_file</mat-icon>
                        </div>
                        <div class="item">
                            <div>
                            </div>
                            <div class="font-colored-text upload-div" (click)="handleUploadClick();">
                                <span *ngIf="selectedUploadFiles.length === 0" class="no-files-attached">{{ 'CALENDARS.NO_FILES_ATTACHED_LBL' | translate }}</span>
                                <span>{{ 'CALENDARS.UPLOAD_LBL' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isVNCTalkCall" class="element add-upload-file-div">
                            <div class="lbl">
                            </div>
                            <div class="item">
                                <div class="browse-file-list">
                                        <input class="hide" #mobileAttachmentUpload (click)="mobileAttachmentUpload.value = null" (change)="uploadMobileAttachment(mobileAttachmentUpload.files)" type="file" [hidden]="true" multiple>
                                    <div class="form-group" *ngFor="let item of selectedUploadFiles; let j = index;">
                                        {{item.name}}<button mat-icon-button aria-label="icon-button" (click)="removeMobileAttach(j)" name="browser_span_{{j}}">
                                                <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div *ngIf="attachedFileList.length > 0" class="attachment-list">
                        <div *ngFor="let file of attachedFileList; let i= index; trackBy: trackByIndex">
                            <mat-icon (click)="removeAttachment(i)">close</mat-icon> {{file.filename}}
                            ({{ (file.s && file.s !== 0 ) ? (file.s | vpFormatFileSize : false) : "0 kb" }}) <span
                                (click)="downloadAttachment(file)"
                                class="download_link">{{ 'CALENDARS.DOWNLOAD_LBL' | translate }}</span>
                        </div>
                    </div>

                    <div *ngIf="!isVNCTalkCall" class="element">
                        <div class="lbl">
                            <mat-icon>today</mat-icon>
                        </div>
                        <div class="item center-alignment">
                            <div class="margin-right-15 gray-color">
                                {{ 'SCHEDULER' | translate }}
                            </div>
                            <div class="margin-right-15">
                                <div class="font-colored-text upload-div" (click)="toggleScheduler()">
                                  <span *ngIf="!showScheduler">{{ 'SHOW' | translate }}</span>
                                  <span *ngIf="showScheduler">{{ 'HIDE' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!isVNCTalkCall" class="element scheduler-view">
                        <div class="lbl">
                        </div>
                        <div class="item">
                            <vp-scheduler (conflict)="onConflict($event)" [style.display]="showScheduler?'block':'none'" style="width: 100%;"></vp-scheduler>
                        </div>
                    </div>

                    <div class="element">
                        <div class="lbl">
                            <mat-icon>edit</mat-icon>
                        </div>
                        <div class="item center-alignment">
                            <div class="margin-right-15 gray-color">
                                {{ 'CALENDARS.DESCRIPTION_LBL' | translate }}
                            </div>
                            <div class="margin-right-15 margin-left-110 checkbox-formatting">
                                <mat-checkbox (change)="editorChange($event)" [(ngModel)]="htmlEditor">
                                    {{ 'CALENDARS.USE_HTML_FORMATTING' | translate }}</mat-checkbox>
                            </div>
                            <div class="margin-right-15" (click)="toggleSpelling()">
                                <mat-icon>spellcheck</mat-icon>
                            </div>
                            <div *ngIf="foundMisspelled" class="misspelling-area">
                                <div class="spelling-total" *ngIf="spelling && spelling.length > 0">({{ spelling.length }} {{ 'MISSPELLING' | translate }})</div>
                                <div class="spelling-actions">
                                  <span class="spelling-action" (click)="resumeEditing()">{{ 'RESUME_EDITING' |translate }}</span>&nbsp;|&nbsp;<span class="spelling-action" (click)="checkAgain()">{{ 'CHECK_AGAIN' |translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="element">
                        <div class="lbl">
                        </div>
                        <div class="item">
                            <div class="html_editor" *ngIf="!isPlainTextFormat">
                                <quill-editor [style]="{height: '237px'}" [modules]="editorModules"
                                    placeholder="{{'CALENDARS.ADD_DESCRIPTION' | translate}}" [bounds]="'.html_editor'"
                                    [(ngModel)]="appointmentBodyData" (onContentChanged)="changeEditor()"></quill-editor>
                            </div>
                            <div class="plaintext_editor plain_text_editor" *ngIf="isPlainTextFormat">
                                <div [style.display]="foundMisspelled ? 'block' : 'none'" class="spell-check-plaintext" style="min-height: 300px;">
                                </div>
                                <mat-form-field [style.display]="!foundMisspelled ? 'block' : 'none'">
                                    <textarea matInput name="quill_editor" cdkAutosizeMinRows="10" cdkAutosizeMaxRows="10"
                                        [(ngModel)]="appointmentBodyData" cdkTextareaAutosize></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="desktop-footer" *ngIf="!isVNCTalkCall">
                    <span *ngIf="!isForwardAppointment && !isProposeNewTime">
                        <button [disabled]="(isVNCTalkCall && isPastDate) || !validStartTime || !validEndTime" [ngClass]="{'disable-send' : (isPastDate && isVNCTalkCall) || !validStartTime || !validEndTime }" mat-button *ngIf="attendeeAutoComplete?.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete?.getSelectedEmail().length > 0 || optionalAutoComplete?.getSelectedEmail().length > 0"
                                (click)="sendAppointment()">
                                {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
                        </button>
                        <button [disabled]="(isVNCTalkCall && isPastDate) || !validStartTime || !validEndTime" [ngClass]="{'disable-send' : (isPastDate && isVNCTalkCall) || !validStartTime || !validEndTime}" mat-button *ngIf="!(attendeeAutoComplete?.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete?.getSelectedEmail().length > 0 || optionalAutoComplete?.getSelectedEmail().length > 0)" (click)="saveAppointment()">
                                {{ 'CALENDARS.SAVE_BTN_LBL' | translate }}
                        </button>
                    </span>
                    <button [disabled]="(isVNCTalkCall && isPastDate) || !validStartTime || !validEndTime" [ngClass]="{'disable-send' : (isPastDate && isVNCTalkCall) || !validStartTime || !validEndTime}" mat-button (click)="sendForwardAppointment()" *ngIf="isForwardAppointment">
                        {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
                    </button>
                    <button [disabled]="(isVNCTalkCall && isPastDate) || !validStartTime || !validEndTime" *ngIf="isProposeNewTime" [ngClass]="{'disable-send' : (isPastDate && isVNCTalkCall) || !validStartTime || !validEndTime}" mat-button (click)="sendProposeNewTime()">
                        {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
                    </button>
                </div>
                <div class="desktop-footer" *ngIf="isVNCTalkCall">
                    <button mat-button (click)="cancel()">
                      {{ 'CANCEL' | translate }}
                    </button>
                    <button mat-button [disabled]="(isVNCTalkCall && isPastDate) || !validStartTime || !validEndTime" [ngClass]="{'disable-send' : (isPastDate && isVNCTalkCall) || !validStartTime || !validEndTime}" *ngIf="attendeeAutoComplete?.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete?.getSelectedEmail().length > 0 || optionalAutoComplete?.getSelectedEmail().length > 0"
                            (click)="createOrUpdateMeeting()">
                            {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
                    </button>
                </div>
            </div>



    <!-- Mobile view -->
    <div class="mobile" *ngIf="isMobileView">
        <div class="mobile-header">
            <div>
                <button mat-icon-button onclick="this.blur()" (click)="closeAppointmentDialog()">
                    <mat-icon>close</mat-icon>
                </button>
                <span class="header-lbl"
                    *ngIf="data.isNewAppointment">{{ 'CALENDARS.NEW_APPOINTMENT_LBL' | translate }}</span>
                <span class="header-lbl"
                    *ngIf="!data.isNewAppointment">{{ 'CALENDARS.EDIT_APPOINTMENT_LBL' | translate }}</span>
            </div>
            <div>
                <button mat-icon-button (click)="isRequestResponsesEnabled()" [matMenuTriggerFor]="mobileMoreOption">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
        </div>
        <div class="content">
            <div class="element">
                <div class="lbl">
                    <mat-icon>title</mat-icon>
                </div>
                <div class="item">
                    <mat-form-field class="title-field">
                        <input matInput placeholder="{{ 'CALENDARS.ADD_TITLE_LBL' | translate }}"
                            [(ngModel)]="appointment.name" class="input-title-field" [disabled]="isProposeNewTime" (input)="valueChange()">
                    </mat-form-field>
                </div>
            </div>
            <div class="element" *ngIf="isForwardAppointment || isProposeNewTime">
                <div class="lbl" (click)="openMobileToAttendeeDialog()">
                    <mat-icon>group</mat-icon>
                </div>
                <div class="item">
                    <div>
                        <vp-autocomplete [libraryChips]="true" [place]="toLBL" [isFocused]=false #toAttendeeAutoComplete>
                        </vp-autocomplete>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="element">
                <div class="lbl">
                    <mat-icon>access_time</mat-icon>
                </div>
                <div class="item">
                    <div>
                        <button id="apptComposeStartDateButton"
                            class="cal-button-arrow-drop-down appt-btn time-button-background"
                            (click)="startDate.open();valueChange();" [disabled]="isForwardAppointment">
                            <input class="cal-mat-date-picker-input" [matDatepicker]="startDate"
                                [formControl]="startDateFormControl" (dateChange)="handleStartDateChanges($event)" />
                            <span class="cal-mat-date-picker-span" *ngIf="browserLang === 'en'">{{ startDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                            <span class="cal-mat-date-picker-span" *ngIf="browserLang === 'de'">{{ startDateFormControl.value | date: "dd.LL.yyyy": "": "de" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                            <mat-datepicker #startDate [touchUi]=true></mat-datepicker>
                        </button>
                    </div>
                    <div *ngIf="!appointment.allDay">
                        <div class="cal-compose-row-content" fxLayout="row" fxLayoutAlign="center center"
                            fxFlex="140px">
                            <button id="apptComposeStartTimeButton"
                                (menuOpened)="triggeredMenuControlFocus(true)" (menuClosed)="triggeredMenuControlFocus(false)"
                                class="cal-button-arrow-drop-down appt-btn time-button-background"
                                [matMenuTriggerFor]="startTimePointMenu" [disabled]="isForwardAppointment" [ngClass]="{'is-warning-date': isPastDate}">
                                <span *ngIf="browserLang === 'en'">{{ startTimeControl | date: "hh:mm a": "": browserLang }}</span>
                                <span *ngIf="browserLang === 'de'">{{ startTimeControl | date: "HH:mm": "": browserLang }}</span>
                                <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="element">
                <div class="lbl">
                </div>
                <div class="item">
                    <div>
                        <button id="apptComposeEndDateButton"
                            class="cal-button-arrow-drop-down appt-btn time-button-background" (click)="endDate.open();valueChange();" [disabled]="isForwardAppointment">
                            <input class="cal-mat-date-picker-input" [matDatepicker]="endDate"
                                [formControl]="endDateFormControl" (dateChange)="handleEndDateChanges($event)" />
                            <span class="cal-mat-date-picker-span" *ngIf="browserLang === 'en'">{{ endDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                            <span class="cal-mat-date-picker-span" *ngIf="browserLang === 'de'">{{ endDateFormControl.value | date: "dd.LL.yyyy": "": "de" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                            <mat-datepicker #endDate [touchUi]=true></mat-datepicker>
                        </button>
                    </div>
                    <div *ngIf="!appointment.allDay">
                        <div class="cal-compose-row-content" fxLayout="row" fxLayoutAlign="center center"
                            fxFlex="140px">
                            <button id="apptComposeStartTimeButton"
                                (menuOpened)="triggeredMenuControlFocus(true)" (menuClosed)="triggeredMenuControlFocus(false)"
                                class="cal-button-arrow-drop-down appt-btn time-button-background"
                                [matMenuTriggerFor]="endTimePointMenu" [disabled]="isForwardAppointment">
                                <span *ngIf="browserLang === 'en'">{{ endTimeControl | date: "hh:mm a":"" : browserLang }}</span>
                                <span *ngIf="browserLang === 'de'">{{ endTimeControl | date: "HH:mm":"" : browserLang }}</span>
                                <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="element past-date" *ngIf="isPastDate">
                <div class="lbl">
                </div>
                <div class="item">
                        {{ 'CALENDARS.APPOINTMENT_PAST_DATE_WARNING_MSG' | translate }}
                </div>
            </div>
            <div class="element">
                <div class="lbl">
                </div>
                <div class="item">
                    <div class="margin-right-15 font-colored-text">
                        <!-- {{ 'CALENDARS.SUGGEST_A_TIME' | translate}} -->
                    </div>
                </div>
            </div>

            <div class="element bottom-10 bottom-10" *ngIf="isShowAppointmentTimeZone">
                <div class="lbl">
                    <!-- <mat-icon>refresh</mat-icon> -->
                </div>
                <div class="item">
                    <div>
                        <button [matMenuTriggerFor]="startTimeZoneMenu" class="appt-btn without-button-border" [disabled]="isForwardAppointment">
                            <span class="mobile-wrape-timezone">{{startTimeZone.value}}</span>
                            <mat-icon class="timezone-icon">arrow_drop_down</mat-icon>
                        </button>
                    </div>
                    <div class="margin-right-15 margin-left-15">{{ 'CALENDARS.TO_TEXT_LBL' | translate }}</div>
                    <div>
                        <button [matMenuTriggerFor]="endTimeZoneMenu" class="appt-btn without-button-border" [disabled]="isForwardAppointment">
                            <span class="mobile-wrape-timezone">{{endTimeZone.value}}</span>
                            <mat-icon class="timezone-icon">arrow_drop_down</mat-icon>
                        </button>
                    </div>

                </div>
            </div>

            <div class="element">
                <div class="lbl">
                </div>
                <div class="item justify-between">
                    <div>
                        {{ 'CALENDARS.ALL_DAY_TITLE' | translate }}
                    </div>
                    <div>
                        <mat-slide-toggle [checked]="appointment.allDay" (change)="changeAllDay($event)" [disabled]="isForwardAppointment">
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="!isVNCTalkCall">
            <div  class="element bottom-10">
                <div class="lbl">
                    <mat-icon>refresh</mat-icon>
                </div>
                <div class="item">
                    <button [matMenuTriggerFor]="repeatMenu" class="appt-btn without-button-border"
                        [disabled]="data.disableRepeat || isForwardAppointment || isProposeNewTime">
                        <span *ngIf="appointment.recur === 'NON'">{{ 'CALENDARS.DOES_NOT_REPEAT' | translate }}</span>
                        <span *ngIf="appointment.recur === 'DAI'">{{ 'CALENDARS.EVERY_DAY' | translate }}</span>
                        <span *ngIf="appointment.recur === 'WEE'">{{ 'CALENDARS.EVERY_WEEK' | translate }}</span>
                        <span *ngIf="appointment.recur === 'MON'">{{ 'CALENDARS.EVERY_MONTH' | translate }}</span>
                        <span *ngIf="appointment.recur === 'YEA'">{{ 'CALENDARS.EVERY_YEAR' | translate }}</span>
                        <span *ngIf="appointment.recur === 'CUSTOM'">{{ 'CALENDARS.CUSTOM_LBL' | translate }}</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                </div>
            </div>
            <div class="element" *ngIf="appointment.recur !== 'NON' && appointment.recur !== 'CUSTOM'">
                    <div class="lbl">

                    </div>
                    <div class="item">
                        <span class="blurb-data" (click)="openCustomRepeatDialog()">{{ 'PREFERENCES.CUSTOMIZE_LBL' | translate }}</span>
                    </div>
            </div>

            <div class="element bottom-10">
                <div class="lbl">
                </div>
                <div class="item">
                    <span class="blurb-data" (click)="openCustomRepeatDialog()">{{blurb}}</span>
                </div>
            </div>
            <mat-divider></mat-divider>
            </ng-container>
            <div class="element">
                <div class="lbl" (click)="openMobileAttendeeDialog()">
                    <mat-icon>group</mat-icon>
                </div>
                <div class="item">
                    <div>
                        <vp-autocomplete [libraryChips]="true" formId="attendeeAutoComplete" [otherEmails]="optionalAutoComplete?.getSelectedEmail()"  (added)="onAddAttendee($event)"  (removed)="onRemoveAttendee($event)" [place]="attendeeLbl" [isFocused]=false #attendeeAutoComplete>
                        </vp-autocomplete>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="element">
                <div class="lbl" (click)="openMobileOptionalAttendeeDialog()">
                    <mat-icon>group_add</mat-icon>
                </div>
                <div class="item">
                    <div>
                        <vp-autocomplete [libraryChips]="true" formId="optionalAutoComplete" [otherEmails]="attendeeAutoComplete?.getSelectedEmail()" (added)="onAddAttendee($event)"  (removed)="onRemoveAttendee($event)" [isFocused]=false [place]="optionalAttendeeslbl" #optionalAutoComplete>
                        </vp-autocomplete>
                        <div *ngIf="isConflicted" class="conflict-error">{{ 'ATTENDEE_CONFLICT' | translate }}</div>
                    </div>

                </div>
            </div>

             <div class="element">
                <div class="lbl">
                </div>
                <div class="item justify-between">
                    <div>
                        {{ 'VNCTALK_CALL' | translate }}
                    </div>
                    <div>
                        <mat-slide-toggle [checked]="isVNCTalkCall"
                            (change)="isVNCTalkCall = $event.checked" [disabled]="meetingJid"></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div class="element">
                <div class="lbl">
                </div>
                <div class="item justify-between">
                    <vnc-input *ngIf="isVNCTalkCall && meetingJid" type="icon-filled"
                        (trailingIconClicked)="copyCallURL()" [trailingIconTemplate]="copyIconTemplate"
                        [form]="urlControl" label="{{ 'URL' | translate }}"
                        placeholder="{{ 'URL' | translate }}" [showLabel]="true" class="full-width"></vnc-input>
                    <vnc-input *ngIf="isVNCTalkCall" type="icon-filled"
                        (trailingIconClicked)="passwordControl.patchValue('')"
                        [trailingIconTemplate]="passwordControl.value ? trailingIconTemplate : null"
                        [form]="passwordControl" label="{{ 'COMMON.PASSWORD' | translate }}"
                        placeholder="{{ 'COMMON.PASSWORD' | translate }}" [showLabel]="true"  class="full-width"></vnc-input>
                </div>
            </div>


            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall" ></mat-divider>
            <!-- <div class="element" *ngIf="!isVNCTalkCall">
                    <vnc-input *ngIf="isVNCTalkCall && meetingJid" type="icon-filled"
                    (trailingIconClicked)="copyCallURL()" [trailingIconTemplate]="copyIconTemplate"
                    [form]="urlControl" label="{{ 'URL' | translate }}"
                    placeholder="{{ 'URL' | translate }}" [showLabel]="true" class="full-width"></vnc-input>
                    <vnc-input *ngIf="isVNCTalkCall" type="icon-filled"
                    (trailingIconClicked)="passwordControl.patchValue('')"
                    [trailingIconTemplate]="passwordControl.value ? trailingIconTemplate : null"
                    [form]="passwordControl" label="{{ 'COMMON.PASSWORD' | translate }}"
                    placeholder="{{ 'COMMON.PASSWORD' | translate }}" [showLabel]="true" class="full-width"></vnc-input>
            </div> -->

            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"  ></mat-divider>
            <div class="element" [class.hide-mobile-calendar-element]="isVNCTalkCall" >
                <div class="lbl">
                    <mat-icon>room</mat-icon>
                </div>
                <div class="item">
                    <div>
                        <vp-calendar-equipment-autocomplete id="locationAutoComplete" (added)="onAddAttendee($event, 'LOCATION')" (removed)="onRemoveAttendee($event)" type="Location" #locationAutoComplete [place]="locationPlaceholder"></vp-calendar-equipment-autocomplete>
                    </div>
                </div>
            </div>


            <div class="element" [class.hide-mobile-calendar-element]="isVNCTalkCall"  [ngStyle]="{ display: !hideResourceCalendar ? 'flex' : 'none' }">
                <div class="lbl">
                </div>
                <div class="item">
                    <div class="margin-right-15 font-colored-text margin-location-mobile-suggestion" (click)="openLocationDialog()">
                        {{ 'CALENDARS.SUGGEST_A_LOCATION' | translate}}
                    </div>
                </div>
            </div>

            <div class="element" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                </div>
                <div class="item">
                    <div *ngIf="locationConflict" class="conflict-error">{{ 'LOCATION_CURRENT_CONFLICTS' | translate }}</div>
                </div>
            </div>
            <mat-divider *ngIf="!hideResourceCalendar" [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

            <div class="element" [ngStyle]="{ display: !hideResourceCalendar ? 'flex' : 'none' }">
                <div class="lbl">
                    <mat-icon>tv</mat-icon>
                </div>
                <div class="item">
                    <vp-calendar-equipment-autocomplete (added)="onAddAttendee($event, 'EQUIPMENT')" (removed)="onRemoveAttendee($event)" type="Equipment" #equipmentAutoComplete [place]="equipmentlbl"></vp-calendar-equipment-autocomplete>
                </div>
            </div>
            <div class="element mobile-equipment-text-div" [ngStyle]="{ display: !hideResourceCalendar ? 'flex' : 'none' }">
                <div class="lbl">
                </div>
                <div class="item" (click)="openEquipmentDialog()">
                    <span class="font-colored-text">{{ 'CALENDARS.FIND_EQUIPMENT_LBL' | translate }}</span>
                </div>
            </div>
            <div *ngIf="locationConflict" class="conflict-error" [class.hide-mobile-calendar-element]="isVNCTalkCall">{{ 'LOCATION_CURRENT_CONFLICTS' | translate }}</div>
            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

            <div class="element"  [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                    <mat-icon>work</mat-icon>
                </div>
                <div class="item margin-bottom-class">
                    <div class="margin-right-15">
                        <button [matMenuTriggerFor]="freeBusyMenu" class="appt-btn without-button-border" [disabled]="isForwardAppointment">
                            <span [class]="'cal-display-bg cal-color-' + appointment?.fb"></span>
                            <span *ngIf="appointment?.fb === 'O'">{{'CALENDARS.OUT_OF_OFFICE' | translate }}</span>
                            <span *ngIf="appointment?.fb === 'T'">{{'CALENDARS.TENTATIVE' | translate }}</span>
                            <span *ngIf="appointment?.fb === 'B'">{{'CALENDARS.BUSY' | translate }}</span>
                            <span *ngIf="appointment?.fb === 'F'">{{'CALENDARS.FREE' | translate }}</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="element" *ngIf="!isVNCTalkCall">
                <div class="lbl">
                </div>
                <div class="item justify-between">
                    <div>
                        {{ 'CALENDARS.PRIVATE_LBL' | translate }}
                    </div>
                    <div>
                        <mat-slide-toggle [checked]="appointment.class === 'PRI' ? true : false"
                            (change)="changeClass($event)" [disabled]="isForwardAppointment || isProposeNewTime"></mat-slide-toggle>
                    </div>
                </div>
            </div>


            <div class="element" *ngIf="!!prefCalendarreminderEmail && prefCalendarreminderEmail !== ''"  [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                </div>
                <div class="item justify-between reminder-email">
                    <div>
                        <mat-checkbox [(ngModel)]="isPrefCalendarReminderEmail">{{'EMAIL_LBL' | translate }} ({{prefCalendarreminderEmail}})</mat-checkbox>
                    </div>
                </div>
            </div>

            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

            <div class="element bottom-20" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                    <mat-icon>today</mat-icon>
                </div>
                <div class="item">
                    <button [matMenuTriggerFor]="folderList" class="appt-btn without-button-border" [disabled]="isForwardAppointment">
                            <mat-icon class="mdi-16px margin-left calendar_selection_mobile" fontSet="mdi" fontIcon="mdi-calendar-blank"
                                [style.color]="getFolderColorById(appointment?.ciFolder)"></mat-icon>
                            <span>{{appointment?.key}}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                </div>
            </div>

            <div class="element upload-margin" [class.hide-mobile-calendar-element]="isVNCTalkCall" *ngIf="attachedFileList.length > 0 || selectedUploadFiles.length > 0">
                <div class="lbl">
                    <mat-icon>attach_file</mat-icon>
                </div>
                <div class="item">
                    <div>
                    </div>
                    <div class="font-colored-text upload-div">
                            {{ 'CALENDARS.UPLOAD_LBL' | translate }}
                    </div>
                </div>
            </div>

            <div class="element add-upload-file-div" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                     <!-- <mat-icon>attach_file</mat-icon> -->
                </div>
                <div class="item">
                    <div class="browse-file-list">
                            <input class="hide" #mobileAttachmentUpload (click)="mobileAttachmentUpload.value = null" (change)="uploadMobileAttachment(mobileAttachmentUpload.files)" type="file" [hidden]="true" multiple>
                        <div class="form-group" *ngFor="let item of selectedUploadFiles; let j = index;">
                            <span>{{item.name}}</span><button mat-icon-button aria-label="icon-button" (click)="removeMobileAttach(j)" name="browser_span_{{j}}">
                                    <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="attachedFileList.length > 0" class="attachment-list" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                    <div *ngFor="let file of attachedFileList; let i= index; trackBy: trackByIndex">
                        <span class="name-attach">{{file.filename}}</span>
                        ({{ (file.s && file.s !== 0 ) ? (file.s | vpFormatFileSize : false) : "0 kb" }}) <span
                            (click)="downloadAttachment(file)"
                            class="download_link"><mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-download"></mat-icon></span>
                            <mat-icon (click)="removeAttachment(i)">close</mat-icon>
                    </div>
            </div>

                <mat-divider *ngIf="appointment.at && appointment.at.length > 0" [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

                <!-- Attendee status-->
            <div class="element" [class.hide-mobile-calendar-element]="isVNCTalkCall" *ngIf="appointment.at && appointment.at.length > 0 && appointment?.or.a === currentUser?.email">
                <div class="lbl ateendee-display-lbl">
                    <mat-icon>group</mat-icon>
                </div>
                <div class="item attendee-display">
                    <div *ngFor="let attendee of appointment.at" class="attendee-display-item">
                        <div class="user-avatar-attendee">
                            <div class="avatar-attendee">
                                <vp-avatar [type]="'mail-chip-avatar'" [user]="{firstLastCharacters: attendee.a.charAt(0), color: '#317bbc', email: attendee.a}"></vp-avatar>
                            </div>
                            <div class="avatar-email">
                                    {{attendee.a}}
                            </div>
                            <div class="attendee-status">
                                <span *ngIf="attendee.ptst && attendee.ptst === 'NE'">
                                    <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-alert"></mat-icon>
                                    {{ 'CALENDARS.NEEDS_ACTIONS' | translate }}
                                </span>
                                <span *ngIf="attendee.ptst && attendee.ptst === 'AC'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-check"></mat-icon> {{ 'CALENDARS.ACCEPT_LBL' | translate }}</span>
                                <span *ngIf="attendee.ptst && attendee.ptst === 'DE'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-remove"></mat-icon> {{ 'CALENDARS.DECLINED_LBL' | translate }}</span>
                                <span *ngIf="attendee.ptst && attendee.ptst === 'TE'"> <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-question"></mat-icon>{{ 'CALENDARS.TENTETIVE_LBL' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

            <div class="element bottom-10 " [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                    <mat-icon>alarm</mat-icon>
                </div>
                <div class="item justify-between">
                    <div>
                        <button class="appt-btn without-button-border" [matMenuTriggerFor]="reminderList" [disabled]="isForwardAppointment || isProposeNewTime">
                            <span>{{alarmReminderOption?.text}}
                                {{ "CALENDARS." + alarmReminderOption?.group | translate }}</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                    </div>
                    <div class="margin-left-15 font-colored-text">
                        <!-- {{ 'CALENDARS.CONFIGURE_LBL' | translate }} -->
                    </div>
                </div>
            </div>
            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>

            <div class="element" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                    <mat-icon>today</mat-icon>
                </div>
                <div class="item center-alignment">
                    <div class="margin-right-15 gray-color">
                        {{ 'SCHEDULER' | translate }}
                    </div>
                    <div class="margin-right-15">
                        <div class="font-colored-text upload-div" (click)="toggleScheduler()">
                          <span *ngIf="!showScheduler">{{ 'SHOW' | translate }}</span>
                          <span *ngIf="showScheduler">{{ 'HIDE' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="element scheduler-view" [class.hide-mobile-calendar-element]="isVNCTalkCall">
                <div class="lbl">
                </div>
                <div class="item">
                    <vp-scheduler (conflict)="onConflict($event)" [style.display]="showScheduler?'block':'none'" style="width: 100%;overflow: auto;"></vp-scheduler>
                </div>
            </div>

            <mat-divider [class.hide-mobile-calendar-element]="isVNCTalkCall"></mat-divider>


            <div class="element">
                <div class="lbl">
                </div>
                <div class="item justify-between">
                    <div>
                        {{ 'CALENDARS.USE_HTML_FORMATTING' | translate }}
                    </div>
                    <div>
                        <mat-slide-toggle [checked]="htmlEditor" (change)="editorChange($event)"></mat-slide-toggle>

                    </div>
                </div>
            </div>
            <div class="html_editor" *ngIf="!isPlainTextFormat && isMobileView">
                <quill-editor [style]="{height: '237px'}" [modules]="editorModules"
                    placeholder="{{'CALENDARS.ADD_DESCRIPTION' | translate}}" [bounds]="'.html_editor'"
                    [(ngModel)]="appointmentBodyData"></quill-editor>
            </div>
            <div class="plaintext_editor" *ngIf="isPlainTextFormat">
                <mat-form-field>
                    <textarea matInput name="quill_editor" cdkAutosizeMinRows="10" cdkAutosizeMaxRows="10"
                        [(ngModel)]="appointmentBodyData" cdkTextareaAutosize
                        placeholder="{{'CALENDARS.ADD_DESCRIPTION' | translate}}"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="mobile-send-save-btn"  *ngIf="!isVNCTalkCall">
            <span *ngIf="!isForwardAppointment && !isProposeNewTime">
                <button [disabled]="isVNCTalkCall && isPastDate" mat-button [ngClass]="{'disable-send' : isPastDate && isVNCTalkCall}" *ngIf="attendeeAutoComplete.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete.getSelectedEmail().length > 0 || optionalAutoComplete.getSelectedEmail().length > 0"
                        (click)="sendAppointment()">
                        {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
                </button>
                <button [disabled]="isVNCTalkCall && isPastDate" mat-button [ngClass]="{'disable-send' : isPastDate && isVNCTalkCall}" *ngIf="!(attendeeAutoComplete.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete.getSelectedEmail().length > 0 || optionalAutoComplete.getSelectedEmail().length > 0)" (click)="saveAppointment()">
                        {{ 'CALENDARS.SAVE_BTN_LBL' | translate }}
                </button>
            </span>
            <button [disabled]="isVNCTalkCall && isPastDate" mat-button [ngClass]="{'disable-send' : isPastDate && isVNCTalkCall}" (click)="sendForwardAppointment()" *ngIf="isForwardAppointment">
                {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
            </button>
            <button [disabled]="isVNCTalkCall && isPastDate" *ngIf="isProposeNewTime" [ngClass]="{'disable-send' : isPastDate && isVNCTalkCall}" mat-button (click)="sendProposeNewTime()">
                {{ 'CALENDARS.SEND_BTN_LBL' | translate }}
            </button>
        </div>
        <div class="mobile-send-save-btn"  *ngIf="isVNCTalkCall">
            <button mat-button (click)="cancel()">
                {{ 'CANCEL' | translate }}
              </button>
              <button [disabled]="isVNCTalkCall && isPastDate" [ngClass]="{'disable-send' : isPastDate && isVNCTalkCall}" mat-button *ngIf="attendeeAutoComplete.getSelectedEmail().length > 0 || equipmentAutoComplete?.getSelectedEmail().length > 0 || locationAutoComplete.getSelectedEmail().length > 0 || optionalAutoComplete.getSelectedEmail().length > 0"
                      (click)="createOrUpdateMeeting()">
                      {{ 'ADD' | translate }}
              </button>
        </div>
    </div>
</div>
<mat-menu id="menuControlStartTimeOption" #startTimePointMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.startTimePointOptions"
        (click)="handleStartTimeChanges(timepoint)"
        [class.active]="browserLang === 'en' ? (timepoint | date: 'hh:mm a') === (startTimeControl | date: 'hh:mm a') : (timepoint | date: 'HH:mm') === (startTimeControl | date: 'HH:mm')"
        [isFocus]="(timepoint | date: 'hh:mm') === (startTimeControl | date: 'hh:mm')"
        >
        <span *ngIf="browserLang === 'en'">{{ timepoint | date: "hh:mm a" }}</span>
        <span *ngIf="browserLang === 'de'">{{ timepoint | date: "HH:mm" }}</span>
    </button>
</mat-menu>

<mat-menu id="menuControlStartTimeOption" #endTimePointMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.endTimePointOptions"
        (click)="handleEndTimeChanges(timepoint)"
        [class.active]="browserLang === 'en' ? (timepoint | date: 'hh:mm a') === (endTimeControl | date: 'hh:mm a') : (timepoint | date: 'HH:mm') === (endTimeControl | date: 'HH:mm')"
        [isFocus]="(timepoint | date: 'hh:mm') === (endTimeControl | date: 'hh:mm')"
        >
        <span *ngIf="browserLang === 'en'">{{ timepoint | date: "hh:mm a" }}</span>
        <span *ngIf="browserLang === 'de'">{{ timepoint | date: "HH:mm" }}</span>
    </button>
</mat-menu>
<mat-menu id="menuControlRepeatOption" #repeatMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let repeatOption of calendarComposeView.repeatOptions"
        (click)="handleCalendarRepeatChanges(repeatOption)">
        <span>{{ "CALENDARS." + repeatOption.text | translate }}</span>
    </button>
    <button mat-menu-item (click)="openCustomRepeatDialog()">
        {{ 'CALENDARS.CUSTOM_LBL' | translate }}
    </button>
</mat-menu>
<mat-menu #freeBusyMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let option of displayOptions"
        (click)="handleFreeBusyChangesChanges(option)">
        <span [class]="'cal-display-bg cal-color-' + option.value.toString()"></span>
        <span>{{ "CALENDARS." + option.text | translate }}</span>
    </button>
</mat-menu>
<mat-menu #folderList="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let option of allFolders" (click)="changeFolder(option)">
        <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-blank"
            [style.color]="option.folderColor">
        </mat-icon>
        <span>{{option.key}}</span>
    </button>
</mat-menu>
<mat-menu id="menuControlAlarmOption" #reminderList="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let alarm of alaramList" (click)="handleSetAlarmChanges(alarm)">
        <span>{{ alarm.text + " " }} {{ "CALENDARS." + alarm.group | translate }}</span>
    </button>
</mat-menu>
<mat-menu #mobileMoreOption="matMenu" class="mobile-create-edit-drop-down">
    <button mat-menu-item (click)="handleUploadClick();" [disabled]="isForwardAppointment">
        <mat-icon class="mdi-18px margin-left" fontSet="mdi" fontIcon="mdi-paperclip"></mat-icon>
        <span>{{ 'CALENDARS.ATTACH_FILE_LBL' | translate }}</span>
    </button>
    <button mat-menu-item (click)="printAppointment()">
        <mat-icon class="mdi-18px margin-left" fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
        <span>{{ 'CALENDARS.PRINT' | translate }}</span>
    </button>
    <div mat-menu-item (click)="$event.stopPropagation();">
        <mat-checkbox [disabled]="rsvpDisabled">{{ 'REQUEST_RESPONSES' | translate }}</mat-checkbox>
    </div>
</mat-menu>
<mat-menu  #startTimeZoneMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let timeZoneItem of timeZone"
        (click)="handleStartTimeZoneChanges(timeZoneItem)">
        <span>{{ timeZoneItem.value }}</span>
    </button>
</mat-menu>
<mat-menu  #endTimeZoneMenu="matMenu" class="cal-dropdown-menu">
    <button vpAutoFocus mat-menu-item *ngFor="let timeZoneItem of timeZone"
        (click)="handleEndTimeZoneChanges(timeZoneItem)">
        <span>{{ timeZoneItem.value }}</span>
    </button>
</mat-menu>
<vp-suggest-location-dialog (locationSelect)="onLocationSelect($event)" #vpSuggestLocation cdkDragBoundary="#edit-calender-appointment-dialog" style="position: absolute; transform: translate3d(556px, -265px, 0px)" cdkDrag></vp-suggest-location-dialog>
<div class="suggestions-list" style="visibility: hidden">
    <ul *ngIf="suggestions && suggestions.length > 0">
        <li  *ngFor="let suggestion of suggestions" (click)="$event.stopPropagation();selectCorrectWord(suggestion)">{{ suggestion }}</li>
        <span class="suggestions-separator"></span>
        <li (click)="$event.stopPropagation();ignoreWord()">{{ 'IGNORE' | translate }}</li>
        <li (click)="$event.stopPropagation();addWord()">{{ 'ADD' | translate }}</li>
    </ul>
</div>
