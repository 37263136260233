
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule, AuthGuard } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { SharedModule } from "./shared/shared.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./app.effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { reducers, reset } from "./reducers";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { AvailableRoutes } from "./common";
import { ResponsiveService } from "./common/providers/responsive.service";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { HttpModule } from "@angular/http";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorService } from "./common/providers/error-service";
import { ToastService } from "./common/providers/toast.service";
import { HttpService } from "./services/http.service";
import { MailUtils } from "./mail/utils/mail-utils";
import { HeaderService } from "./services/header.service";
import { SubFolderComponent } from "./mail/shared/components/mobile-folders/sub-folders.component";
import { SubFolderMenuComponent } from "./mail/shared/components/mobile-folders-menu/sub-folders-menu.component";
import { MobileBriefcaseComponent } from "./briefcase/components/mobile-briefcase/mobile-briefcase.component";
import { MobileTagsComponent } from "./mail/shared/components/mobile-tags/mobile-tags.component";
import { MailBroadcaster } from "./common/providers/mail-broadcaster.service";
import { SharedCalendarService } from "src/app/calendar/service/shared-calendar-service";
import { MailService } from "./mail/shared/services/mail-service";
import { MailFolderRepository } from "./mail/repositories/mail-folder-repository";
import { ConversationRepository } from "./mail/repositories/conversation.repository";
import { RefineSearchDialogComponent } from "./mail/shared/components/refine-search/refine-search-dialog";
import { NgxHotkeysModule } from "ngx-hotkeys-vnc";
import { ShortcutDialogComponent } from "./mail/shared/components/shortcut-dialog/shortcut-dialog.component";
import { GeneralSettingsDialogComponent } from "./mail/shared/components/general-settings-dialog/general-settings-dialog.component";
import { ChangelogDialogComponent } from "./mail/shared/components/changelog-dialog/changelog-dialog.component";
import { AboutDialogComponent } from "./mail/shared/components/about-dialog/about-dialog.component";
import { ServicedeskDialogComponent } from "./mail/shared/components/servicedesk-dialog/servicedesk-dialog.component";
import { HelpFaqDialogComponent } from "./mail/shared/components/help-faq-dialog/help-faq-dialog.component";
import { MobileAboutDialogComponent } from "./mail/shared/components/mobile-about-dialog/mobile-about-dialog.component";
import { ContextMenuModule } from "ngx-contextmenu";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { VNCNotificationsService, VNCNotificationComponent, VNCNotificationsComponent } from "./shared/components/notifications";
import { PreferenceService } from "./preference/shared/services/preference.service";
import { PreferenceRepository } from "./preference/repositories/preference.repository";
import { VNCAuthenticationComponent } from "./shared/components/vnc-authentication";
import { TabsComponent } from "./shared/components/tabs/tabs.component";
import { SearchTabsComponent } from "./shared/components/search-tabs/search-tabs.component";
import { CommonRepository } from "./mail/repositories/common-repository";
import { MailFolderOprationDialogComponent } from "./mail/mail-folder-operation-dialog/mail-folder-operation-dialog.component";
import { ColorControlDialogComponent } from "./mail/color-control-dialog/color-control-dialog.component";
import { ConfirmDialogComponent } from "./mail/confirm-dialog/confirm-dialog.component";
import { CreateFolderComponent } from "./mail/create-folder/create-folder.component";
import { MoveFolderDialogComponent } from "./mail/shared/components/move-items/move-folder-dialog.component";
import { ColorChromeModule } from "ngx-color/chrome";
import { CreateFilterComponent } from "./shared/components/create-filter/create-filter.component";
import { MailFolderComponent } from "./shared/components/mail-folder-dialog/mail-folder-dialog.component";
import { LegalNoticeDialogComponent } from "./mail/shared/components/legal-notice-dialog/legal-notice-dialog.component";
import { CreateTagComponent } from "./mail/create-tag/create-tag.component";
import { SelectAddressesDialogComponent } from "./mail/select-addresses-dialog/select-addresses-dialog.component";
import { ElectronService } from "./services/electron.service";
import { EditFolderPropertiesComponent } from "./mail/shared/components/folder-edit-properties/folder-edit-properties-dialog.component";
import { ShareFolderRevokeComponent } from "./mail/shared/components/share-folder-revoke-dialog/share-folder-revoke-dialog.component";
import { ShareFolderComponent } from "./mail/shared/components/share-folder/share-folder.component";
import { MobileTagListOperationComponent } from "./mail/shared/components/tag-list-operation/tag-list-operation.component";
import { InstallAppDialogComponent } from "./shared/components/install-app/install-app.component";
import { MessageRepository } from "./mail/repositories/message.respository";
import { SendReadReceiptDialogComponent } from "./mail/shared/components/send-read-receipt-dialog/send-read-receipt-dialog.component";
import { FilesStorageService } from "./services/files-storage.service";
import { DesktopChangePasswordComponent } from "./mail/shared/components/desktop-change-password/desktop-change-password.component";
import { MobileChangePasswordComponent } from "./mail/shared/components/mobile-change-password/mobile-change-password.component";
import { MailHistoryDialogComponent } from "./mail/shared/components/maill-history-dialog/mail-history-dialog.component";
import { OutOfOfficeAlertComponent } from "./mail/shared/components/out-of-office-alert-dialog/out-of-office-alert.component";
import { AcceptShareFolderComponent } from "./mail/shared/components/accept-share-folder/accept-share-folder.component";
import { AppService } from "./services/app.service";
import { AttachEmailDialogComponent } from "./mail/shared/components/attach-email-dialog/attach-email-dialog.component";
import { MobileSignatureDialogComponent } from "./mail/shared/components/mobile-signature-dialog/mobile-signature-dialog.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import * as Raven from "@sentry/browser";
import * as ElectronSentry from "@sentry/electron";
import { RewriteFrames as RewriteFramesIntegration } from "@sentry/integrations";
// import * as Raven from "raven-js";
import { environment } from "src/environments/environment";
import { SuccessService } from "./common/providers/sucess-service";
import { ContactBroadcaster } from "./common/providers/contact-broadcaster.service";
import { ContactService } from "./contacts/services/contact-service";
import { ContactsFolderRepository } from "./contacts/repositories/contact-folder.repository";
import { ContactsRepository } from "./contacts/repositories/contacts-repository";
import { MessageTranslatorService } from "./contacts/services/message-translator-service";
import * as Sentry from "sentry-cordova";
import { BriefcaseFolderRepository } from "./briefcase/repositories/briefcase-folder.repository";
import { BriefcaseRepository } from "./briefcase/repositories/briefcase.repository";
import {
  MobileSelectAddressDialogComponent
} from "./mail/shared/components/mobile-select-address-dialog/mobile-select-address-dialog.component";
import { SearchFolderComponent } from "./mail/shared/components/search-folder/search-folder.component";
import { AppointmentReminderDialogComponent } from "./shared/components/appointment-reminder/appointment-reminder.component";
import { CalendarRepository } from "./calendar/repositories/calendar.repository";
import { httpInterceptorProviders } from "./shared/http-interceptors";
import { DragDropParticipantService } from "./mail/shared/services/drag-drop-participant.service";
import { AcceptShareFromMailComponent } from "src/app/mail/shared/components/accept-share-from-mail/accept-share-from-mail.component";
import { TFAService } from "./services/tfa.service";
import { TFARepository } from "./services/tfa.repository";
import { AddDelegateDialogComponent } from "src/app/shared/components/add-delegate-dialog/add-delegate-dialog.component";
import { ActivityStreamComponent } from "./shared/components/activity-stream/activity-stream.component";
import { RenameSearchComponent } from "src/app/shared/components/rename-search/rename-search.component";
import { EditSearchPropertiesComponent } from "src/app/shared/components/search-edit-properties/search-edit-properties-dialog.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LocalStorageService } from "./services/local-storage.service";
import { MailConstants } from "./common/utils/mail-constants";
import { ProfileDetailDialogComponent } from "src/app/shared/components/profile-detail-dialog/profile-detail-dialog.component";
import { VNCActionWheelMenuService, GlobalSearchModule, GlobalSearchService, VncLibraryModule } from "vnc-library";

export function HttpLoaderFactory(http: HttpClient) {
  const bUrl = MailUtils.getBaseUrl();
  const path = bUrl.endsWith("/") ? "assets/i18n/" : "/assets/i18n/";
  return new TranslateHttpLoader(http, MailUtils.getBaseUrl() + path, ".json");
}


export class RavenErrorHandler extends ErrorHandler {
  handleError(error) {
    console.log("[SentryErrorHandler] handleError", error);
    super.handleError(error);
    try {
      console.log("[SentryErrorHandler] captureException", error);
      Raven.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

export class ElectronErrorHandler extends ErrorHandler {
  handleError(error) {
    console.log("[SentryErrorHandler] handleError", error);
    super.handleError(error);
    try {
      console.log("[SentryErrorHandler] captureException", error);
      ElectronSentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

export class SentryErrorHandler extends ErrorHandler {
  handleError(error) {
    console.log("[SentryErrorHandler] handleError", error);
    super.handleError(error);
    try {
      console.log("[SentryErrorHandler] captureException", error);
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

let EXTRA_PROVIDERS = [];
let rootPath = "";
try {
  let oRootPath = window.location.pathname.split("app.asar")[0] + "app.asar";
  rootPath = oRootPath;
  console.log("rootpath: ", rootPath);
} catch (error) {
  console.log("error rootpath: ", error);
}
console.log("[environment.production]", environment.production);
// disable sentry init for production builds
if (!environment.production) {
  if (environment.production && !environment.isCordova && !environment.isElectron) {
    console.log("sentry root Raven");
    Raven.init({
        dsn: MailConstants.SENTRY_DSN,
        release: "web-3.0.6.9483",
        sendClientReports: false,
        autoSessionTracking: false
      });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: RavenErrorHandler }];
  } else if (environment.isCordova) {
    Sentry.init({
      dsn: MailConstants.SENTRY_DSN,
      release: "mobile-3.0.6.9483",
      enableNative: false
    });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];
  } else if (environment.isElectron) {
    console.log("sentry root electron, rootPath: ", rootPath);
    Raven.init({
        dsn: MailConstants.SENTRY_DSN,
        release: "electron-3.0.6.9483",
        sendClientReports: false,
        autoSessionTracking: false,
        integrations: [new RewriteFramesIntegration({
          iteratee: (frame) => {
            if (!!frame.filename) {
              frame.filename = frame.filename.split("app.asar")[1];
            }
            return frame;
          }
        })]
      });
    EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: ElectronErrorHandler }];
  }
}

export class BaluHammerConfig extends HammerGestureConfig {
  overrides = {
      pan: {
           direction: 6
    },
    pinch: {
        enable: false
    },
    rotate: {
        enable: false
    }
};
}

@NgModule({
    declarations: [
        TabsComponent,
        SearchTabsComponent,
        AppComponent,
        HeaderComponent,
        PageNotFoundComponent,
        SubFolderComponent,
        SubFolderMenuComponent,
        MobileBriefcaseComponent,
        RefineSearchDialogComponent,
        ShortcutDialogComponent,
        GeneralSettingsDialogComponent,
        ChangelogDialogComponent,
        AboutDialogComponent,
        ServicedeskDialogComponent,
        HelpFaqDialogComponent,
        MobileAboutDialogComponent,
        VNCAuthenticationComponent,
        VNCNotificationComponent,
        VNCNotificationsComponent,
        MailFolderOprationDialogComponent,
        ColorControlDialogComponent,
        ConfirmDialogComponent,
        CreateFolderComponent,
        RenameSearchComponent,
        AddDelegateDialogComponent,
        CreateTagComponent,
        MoveFolderDialogComponent,
        SearchFolderComponent,
        CreateFilterComponent,
        ActivityStreamComponent,
        MailFolderComponent,
        LegalNoticeDialogComponent,
        MobileTagsComponent,
        ShareFolderComponent,
        EditFolderPropertiesComponent,
        EditSearchPropertiesComponent,
        ShareFolderRevokeComponent,
        MobileTagListOperationComponent,
        InstallAppDialogComponent,
        SendReadReceiptDialogComponent,
        SelectAddressesDialogComponent,
        DesktopChangePasswordComponent,
        MobileChangePasswordComponent,
        MailHistoryDialogComponent,
        OutOfOfficeAlertComponent,
        AcceptShareFolderComponent,
        AcceptShareFromMailComponent,
        AttachEmailDialogComponent,
        MobileSignatureDialogComponent,
        MobileSelectAddressDialogComponent,
        ProfileDetailDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        // NoopAnimationsModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        HttpClientModule,
        HttpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        FlexLayoutModule,
        ContextMenuModule.forRoot({
            autoFocus: true
        }),
        SharedModule.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers: [reset], runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            } }),
        EffectsModule.forRoot([AppEffects]),
        NgxHotkeysModule.forRoot({disableCheatSheet: true}),
        ColorChromeModule,
        GlobalSearchModule.forRoot({
          serverURL: localStorage.getItem("serverURL") !== null && localStorage.getItem("serverURL") !== "null" ? localStorage.getItem("serverURL") : "",
          searchAPI: "",
          mailAPI: "/api/mailSearch",
          searchTalkAPI: "",
          tagAPI: "",
          mailTagAPI: "/api/getTagList",
          contactFilterAPI: "",
          savedSearchListAPI: "/api/queries",
          saveSearchAPI: "/api/queries",
          environment: {
            isCordova: environment.isCordova,
            isElectron: environment.isElectron
          },
          data: {buildHash: environment.isElectron ? window.electron.blueimpMD5 : md5},
          token: localStorage.getItem("token")
        }),
        ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production, registrationStrategy: "registerImmediately" }),
        VncLibraryModule,
        !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],

    ],
    providers: [
        httpInterceptorProviders,
        Title,
        AuthService,
        ConfigService,
        AuthGuard,
        AvailableRoutes,
        ResponsiveService,
        ErrorService,
        ToastService,
        HttpService,
        HeaderService,
        FilesStorageService,
        LocalStorageService,
        MailBroadcaster,
        SharedCalendarService,
        MailService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: BaluHammerConfig
        },
        MailFolderRepository,
        ConversationRepository,
        CommonRepository,
        VNCNotificationsService,
        PreferenceService,
        PreferenceRepository,
        DatePipe,
        MessageRepository,
        ElectronService,
        ErrorService,
        SuccessService,
        ResponsiveService,
        ContactBroadcaster,
        MessageTranslatorService,
        ContactService,
        ContactsFolderRepository,
        ContactsRepository,
        BriefcaseFolderRepository,
        BriefcaseRepository,
        AppService,
        CalendarRepository,
        DragDropParticipantService,
        TFAService,
        TFARepository,
        GlobalSearchService,
        VNCActionWheelMenuService,
        ...EXTRA_PROVIDERS
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})

export class AppModule {
}
