<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-dialog" [ngStyle]="customStyles">
  <div class="vnc-dialog_header" *ngIf="!hideHeader"  [class.hide-body]="hideBody">
    <ng-container *ngIf="headerText">{{ 'headerText' | translate }}</ng-container>
    <ng-content select="[dialog-title]"></ng-content>
    <span></span>
    <div class="close-btn">
      <div class="vnc-icon-container" (click)="close()">
        <svg matTooltip="{{ 'CLOSE' | translate }}" (click)="close()" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.95 5.05a1 1 0 0 1 0 1.414L13.414 12l5.536 5.536a1 1 0 0 1-1.414 1.414L12 13.414 6.464 18.95a1 1 0 1 1-1.414-1.414L10.586 12 5.05 6.464A1 1 0 0 1 6.464 5.05L12 10.586l5.536-5.536a1 1 0 0 1 1.414 0z"
            fill="#8B96A0" fill-rule="evenodd" />
        </svg>
      </div>
    </div>
  </div>
  <div class="vnc-dialog_body" [class.hide-header]="hideHeader" [class.hide-footer]="hideFooter">
    <ng-content select="[dialog-body]"></ng-content>
  </div>
  <div class="vnc-dialog_footer" [ngClass]="{ 'dfb-theme' : currentTheme == 'dfb'}" *ngIf="!hideFooter"  [class.hide-body]="hideBody">
    <ng-content select="[dialog-footer]"></ng-content>
  </div>
</div>
