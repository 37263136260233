
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<vnc-top-bar
  (closedAutocomplete)="onCloseAutocomplete()"
  recentSearchLabel="{{ 'RECENT_SEARCHES'| translate }}"
  noRecentSearchLabel="{{ 'NO_RECENT_SEARCHES' | translate }}"
  [style.display]="!isMobileScreen ? 'block':'none'"
  [enableAutoComplete]="searchFor === 'mail'"
  [showSearchGlobalFilterButton]="false"
  (click)="switchApplication($event)">

  <vnc-top-bar-apps-button tabindex="0"  *ngIf="!hideAppsMenu" appswitch="false" (click)="switchApps($event)"></vnc-top-bar-apps-button>

  <!-- Title/logo -->
  <span vnc-top-bar-title class="app-title" (click)="switchApp = false; navigatorTo('/')" >
    <img *ngIf="appLogoDesktop" class="vnc_logo" [src]="appLogoDesktop" [ngClass]="{'is-blue-theme': isBlueTheme}"/>
    <div *ngIf="!isDFBTheme && !srcLogo" class="logo-title" [ngClass]="{'apply-left-border': isDFBTheme}" (click)="switchApp = false; navigatorTo('/')" [innerHTML]="textLogo"></div>
    <img *ngIf="!isDFBTheme && srcLogo" class="logo-title vnc-logo-def" [src]="srcLogo"/>
  </span>
  <span vnc-top-bar-title *ngIf="isDFBTheme">
    <div class="logo-title" [ngClass]="{'apply-left-border': isDFBTheme}" (click)="switchApp = false; navigatorTo('/')" [innerHTML]="textLogo"></div>
  </span>

  <!-- advanced contacts search -->
  <div vnc-top-bar-search-actions class="header-top-bar-search-action" *ngIf="isContactModule" >
    <button>
      <vnc-icon-container isClickable="false" removeClickAnimation="true" (click)="showSearchPopover()">
        <vnc-icon type="product" name="filter-stripe-new" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
      </vnc-icon-container>
    </button>

    <div *ngIf="showAdvancedSearchPopover" (click)="showAdvancedSearchPopover = false" class="popover-overlay"></div>
    <section [hidden]="!showAdvancedSearchPopover" class="contacts-advanced-search-wrapper">
      <vp-advanced-contact-search (onClose)="showAdvancedSearchPopover = false"></vp-advanced-contact-search>
    </section>
  </div>

  <!-- email search filter -->
  <div vnc-top-bar-search-actions class="header-top-bar-search-action double-btn" >
    <button disableRipple #searchOptionsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="filteroptions" >
      <vnc-icon-container isClickable="false" removeClickAnimation="true" (click)="openSearchOptionsMenu()" >
        <vnc-icon *ngIf="searchFor === 'mail'" type="product" name="mail-new" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
        <vnc-icon *ngIf="searchFor === 'contact'" type="product" name="users-new" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
        <vnc-icon *ngIf="searchFor === 'briefcase'" type="product" name="portfolio-new" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
        <vnc-icon *ngIf="searchFor === 'calendar'" type="product" name="date-today-new" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
      </vnc-icon-container>
      <vnc-icon-container isClickable="false" removeClickAnimation="true" (click)="openSearchOptionsMenu()" >
        <vnc-icon type="product" name="arrow-drop-down" [width]="menuIconSide" [height]="menuIconSide" [color]="'white'" [hoverColor]="'white'"></vnc-icon>
      </vnc-icon-container>
    </button>

    <!-- search-options-menu -->
    <mat-menu backdropClass="search-options-backdrop1" class="header-menu-common search-tab-select-menu" #filteroptions="matMenu">

      <button mat-menu-item class="menu-item" [ngClass]="{'dfb-theme' : currentTheme === 'dfb', 'vnc-blue-theme' : (currentTheme === 'default' || !currentTheme)}" [class.selected]="searchFor === 'mail'" (click)="setSearchFor('mail')" >
        <vnc-icon-container isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="mail-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
        <span>{{ 'MAIL' | translate}}</span>
        <vnc-icon-container *ngIf="searchFor === 'mail'"  isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="check" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
      </button>

      <button mat-menu-item class="menu-item" [ngClass]="{'dfb-theme' : currentTheme === 'dfb', 'vnc-blue-theme' : (currentTheme === 'default' || !currentTheme)}" [class.selected]="searchFor === 'contact'" (click)="setSearchFor('contact')" *ngIf="isHideContactSearchSelectionOption">
        <vnc-icon-container isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="users-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
        <span>{{ 'CONTACTS' | translate}}</span>
        <vnc-icon-container *ngIf="searchFor === 'contact'" isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="check" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
      </button>

      <button mat-menu-item class="menu-item" [ngClass]="{'dfb-theme' : currentTheme === 'dfb', 'vnc-blue-theme' : (currentTheme === 'default' || !currentTheme)}" [class.selected]="searchFor === 'briefcase'" (click)="setSearchFor('briefcase')" *ngIf="config.isZimbraBriefcaseEnabled">
        <vnc-icon-container isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="portfolio-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
        <span>{{ 'BRIEFCASES_LBL' | translate}}</span>
        <vnc-icon-container *ngIf="searchFor === 'briefcase'" isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="check" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
      </button>

      <button mat-menu-item class="menu-item" [ngClass]="{'dfb-theme' : currentTheme === 'dfb', 'vnc-blue-theme' : (currentTheme === 'default' || !currentTheme)}" [class.selected]="searchFor === 'calendar'" (click)="setSearchFor('calendar')" >
        <vnc-icon-container isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="date-today-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
        <span>{{ 'CALENDARS.CALENDAR_FOLDER' | translate}}</span>
        <vnc-icon-container *ngIf="searchFor === 'calendar'" isClickable="false" removeClickAnimation="true">
          <vnc-icon type="product" name="check" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
        </vnc-icon-container>
      </button>

      <mat-divider></mat-divider>

      <div class="mat-menu-item label-checkbox-item" (click)="$event.stopPropagation()">
        <span>{{ 'INCLUDE_SHARED_ITEMS' | translate}}</span>
        <vnc-toggle-switch [size]="'s'" [checked]="includeSharedItems" (changeEvent)="includeSharedItems = $event.checked"></vnc-toggle-switch>
      </div>

    </mat-menu>
  </div>

  <!-- notification button -->
  <div class="notification-icon" vnc-top-bar-icon-button>
    <vnc-icons-notification [size]="1" [isDisabled]="true" *ngIf="!isDFBTheme" vnc-top-bar-icon-button mat-icon-button></vnc-icons-notification>
  </div>

  <!--[isDisabled]="!currentUser?.email"-->
  <vnc-icons-panel id="right-bar-toggle-handle" matTooltipPosition="above" matTooltip="{{'INFO' | translate }}" vnc-top-bar-icon-button  [isActive]="rightSidebarExpanded" *ngIf="!isDFBTheme" [size]="1" (clicked)="toggleSidebar()"></vnc-icons-panel>

  <vnc-top-bar-user-button [defaultAvatar]="'assets/images/profile.png'" [userImage]="avatarURL" mat-button [matMenuTriggerFor]="headerMenuNew"></vnc-top-bar-user-button>
  <span vnc-top-bar-icon-button *ngIf="domainSpecificLogoAvailable" class="domain-logo">
    <img [src]="domainSpecificUserLogo" alt="domainLogo">
  </span>
</vnc-top-bar>

<mat-toolbar color="primary" [ngClass.xs]="{'mobile-search': searchMode}"
  [style.display]="isMobileScreen ? 'block':'none'"
  [class.with-search]="isMobileSearch" [class.is-list-view]="isListView && isHandset">
  <!-- desktop header old -->
  <!-- <mat-toolbar-row fxShow.lt-sm="false" fxLayout="row" class="desktop-header">
    <div fxLayout="row" fxFlex="40%" fxLayoutGap="16px" fxLayoutAlign="start center">
      <button *ngIf="!hideAppsMenu" mat-icon-button (click) = "siwtchAppMenuOption()">
        <mat-icon>apps</mat-icon>
      </button>
      <img class="vnc_logo" [src]="appLogoDesktop" [ngClass]="{'is-blue-theme': isBlueTheme}"/>
      <div class="logo-title" [ngClass]="{'apply-left-border': isDFBTheme}" (click)="switchApp = false; navigatorTo('/')" [innerHTML]="textLogo">

      </div>
    </div>

    <div fxLayout="row" fxFlex="36%" (click)="switchApp = false;" fxLayoutAlign="start" class="search-bar">

      <input matInput type="text" (focus)="searchFocus()" (focusout)="searchFocusOut()" [(ngModel)]="searchKeyword" (keyup)="searchOnKeyUp()" (keyup.enter)="search()" placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}"
      *ngIf="searchFor === 'mail'" [matAutocomplete]="autoSearch" />
      <input matInput type="text" (focus)="searchFocus()" (focusout)="searchFocusOut()" [(ngModel)]="searchKeyword" (keyup.enter)="search()" placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}"
      *ngIf="searchFor !== 'mail'" />

      <button id="clearSearchBtn" mat-button *ngIf = "searchKeyword" (click)="clearSearchText()">
        <mat-icon>close</mat-icon>
      </button>

      <button id="searchBtn" mat-icon-button (click)="search()">
        <mat-icon>search</mat-icon>
      </button>

      <mat-menu backdropClass="search-options-backdrop" class="searchOptions" id="searchOptions" #searchOptions="matMenu">
        <button (click)="setSearchFor('mail')" id="searchMailOption" mat-menu-item [class.selected]="searchFor === 'mail'">
            <mat-icon>mail</mat-icon>
          <span>{{ 'MAIL' | translate}}</span>
        </button>
        <button (click)="setSearchFor('contact')" id="searchContactOption" mat-menu-item [class.selected]="searchFor === 'contact'" *ngIf="isHideContactSearchSelectionOption"> <mat-icon>person</mat-icon> <span>{{ 'CONTACTS' | translate}}</span></button>
        <button *ngIf="config.isZimbraBriefcaseEnabled" (click)="setSearchFor('briefcase')" id="searchBriefcaseOption" mat-menu-item [class.selected]="searchFor === 'briefcase'"> <mat-icon>work</mat-icon> <span>{{ 'BRIEFCASES_LBL' | translate }}</span></button>
        <button (click)="setSearchFor('calendar')" id="searchCalendarOption" mat-menu-item [class.selected]="searchFor === 'calendar'"> <mat-icon>date_range</mat-icon> <span>{{ 'CALENDARS.CALENDAR_FOLDER' | translate }}</span></button>
        <mat-divider></mat-divider>
        <button id="sharedItemOption" mat-menu-item (click)="$event.preventDefault();setIncludeSharedItems()">
          <mat-checkbox inputId="includeSharedItems" id="includeSharedItemsCheckbox" [(ngModel)]="includeSharedItems"></mat-checkbox>
          <span>{{ 'INCLUDE_SHARED_ITEMS' | translate}}</span>
        </button>
      </mat-menu>

      <button id="searchOptionsMenu" mat-icon-button [matMenuTriggerFor]="searchOptions">
        <div class="search-option-wrapper">
            <div class="search-option-icon">
                <mat-icon *ngIf="searchFor === 'mail'">mail</mat-icon>
                <mat-icon *ngIf="searchFor === 'contact'">person</mat-icon>
                <mat-icon *ngIf="searchFor === 'briefcase'">work</mat-icon>
                <mat-icon *ngIf="searchFor === 'calendar'">date_range</mat-icon>
                <mat-icon id="sharedIcon" *ngIf="includeSharedItems">share_variant</mat-icon>
            </div>
            <mat-icon id="dropDownIcon">arrow_drop_down</mat-icon>
        </div>
      </button>

    </div>
    <div *ngIf="isContactModule" (click)="switchApp = false;" fxLayoutAlign="start center">
      <div *ngIf="showAdvancedSearchPopover" (click)="showAdvancedSearchPopover = false" class="popover-overlay"> </div>
     <div *ngIf="isContactModule" class="header-filter-button">
      <mat-icon (click)="showSearchPopover()">tunes</mat-icon>
       <section [hidden]="!showAdvancedSearchPopover" class="advance-search-wrapper">
         <vp-advanced-contact-search (onClose)="showAdvancedSearchPopover = false"></vp-advanced-contact-search>
       </section>
     </div>
     <div *ngIf="showAdvancedSearchPopover" (click)="showAdvancedSearchPopover = false" class="popover-overlay"> </div>
    </div>


    <div fxLayout="row" fxFlex="35%" (click)="switchApp = false;" fxLayoutAlign="end center" fxLayoutGap="8px">
      <span class="username dfb-theme-username" *ngIf="currentUser && isDFBTheme" [matMenuTriggerFor]="headerMenuQuote" #trigger="matMenuTrigger" (click)="trigger.openMenu()">{{ currentUser?.fullName || currentUser?.firstName + " " + currentUser?.lastName }}</span>
      <span class="username default-theme-username" *ngIf="currentUser && !isDFBTheme" [matMenuTriggerFor]="headerMenuQuote" #trigger="matMenuTrigger" (click)="trigger.openMenu()">{{ currentUser?.fullName || currentUser?.firstName + " " + currentUser?.lastName | titlecase}}</span>
      <vp-avatar *ngIf="currentUser && avatarURL && displayHeaderAvatar" [type]="'desktop-profile'" [matMenuTriggerFor]="headerMenu" [user]="{firstLastCharacters: getFirstLastCharacters(currentUser), email: currentUser.email}"> </vp-avatar>
      <img class="avatar-image" *ngIf = "displayHeaderAvatar && (!currentUser || !avatarURL)" src="assets/images/profile.png" height="40px" [matMenuTriggerFor]="headerMenu" />
      <span *ngIf="domainSpecificLogoAvailable" class="domain-logo">
        <img [src]="domainSpecificUserLogo" alt="domainLogo">
      </span>
      <button mat-icon-button [matMenuTriggerFor]="headerSettingsMenu">
        <mat-icon>settings</mat-icon>
      </button>

    </div>

  </mat-toolbar-row> -->
  <!-- mobile header old -->
  <mat-toolbar-row fxShow.xs="true"  fxShow.sm="true" fxShow.md="false" fxShow.lg="false" fxShow.xl="false" fxLayout="row" class="mobile-header" [class.search-mode]="searchMode">
    <ng-container *ngIf="!searchMode">
      <button mat-icon-button id="hamburger_menu_drawer" class = "sidebar-menu" (click) = "siwtchAppMenuOption()">
        <mat-icon>menu</mat-icon>
      </button>
      <span id="header-brand-name" *ngIf="!isBlueTheme && !isGreenTheme" class="brand_name">
        <ng-container  *ngIf="!calendarOnly">
          <span class="vnc-mail-mobile">{{brandName}}</span><span class="vnc-mail-mobile-sub" fxFlex="1 1 auto"><span class="brand_mail">mail</span><span id="mail_unread_count" class="mail_count" *ngIf="siteHeaderMenu[0]['badge'] && siteHeaderMenu[0]['badge'] !== '0'">{{ siteHeaderMenu[0]["badge"] > 99 ? "99+" : siteHeaderMenu[0]["badge"] }}</span></span>
        </ng-container>
        <ng-container  *ngIf="calendarOnly">
          <span class="vnc-mail-mobile">{{brandName}}</span><span class="vnc-mail-mobile-sub" fxFlex="1 1 auto"><span class="brand_mail">calendar</span></span>
        </ng-container>
      </span>
      <span *ngIf="isGreenTheme" class="bluetheme">
        <img [src]="appLogo" class="logo">
        <div [innerHTML]="textLogo" class="brand-name"></div>
        <span class="mail_count" *ngIf="siteHeaderMenu[0]['badge'] && siteHeaderMenu[0]['badge'] !== '0'">
          {{ siteHeaderMenu[0]["badge"] > 99 ? "99+" : siteHeaderMenu[0]["badge"] }}
        </span>
      </span>
      <div *ngIf="isBlueTheme" class="bluetheme">
        <img [src]="appLogo" class="logo">
        <div [innerHTML]="textLogo" class="brand-name"></div>
        <span class="mail_count" *ngIf="siteHeaderMenu[0]['badge'] && siteHeaderMenu[0]['badge'] !== '0'">
          {{ siteHeaderMenu[0]["badge"] > 99 ? "99+" : siteHeaderMenu[0]["badge"] }}
        </span>
      </div>
      <span *ngIf="domainSpecificLogoAvailable" class="domain-logo-mobile">
        <img [src]="domainSpecificUserLogo" alt="domainLogo">
      </span>
      <button mat-icon-button (click)="toggleMobileSearch()" id="mail_search_header_btn">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-icon-button *ngIf = "isSearchQueryExist">
        <mat-icon [matMenuTriggerFor]="searchMenu">more_vert</mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="searchMode">
      <button mat-icon-button (click)="toggleMobileSearch()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <input matInput (focus)="searchFocus()" (focusout)="searchFocusOut()" id = "mobileSearchInput" type="text" fxFlex="1 1 auto" [(ngModel)]="searchKeyword" (keydown)="searchFromText($event)"
      placeholder="{{ (searchFor === 'mail' ? 'SEARCH_EMAILS' : (searchFor === 'contact' ? 'SEARCH_CONTACTS' :  'SEARCH_BRIEFCASES') ) | translate}}" [matAutocomplete]="autoSearch" (keyup)="searchOnKeyUp()"/>
      <button id="clearSearchTextBtn" mat-icon-button (click)="clearSearchText()">
        <mat-icon>clear</mat-icon>
      </button>
      <button id="moreOptionBtn" mat-icon-button (click)="toggleAdvancedSearchForm()">
        <mat-icon>tune</mat-icon>
      </button>
      <mat-autocomplete #autoSearch="matAutocomplete" class="solr-search-result-autocomplete">
        <mat-option *ngFor="let item of autoSearchResult" [value]="item" (click)="selectSearchItem($event, item)">{{item}}</mat-option>
      </mat-autocomplete>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
<!-- Main menu -->
<mat-menu #headerMenuNew="matMenu" class="header-menu-common extended-width-280">

  <div class="user-section user-section-menu" (click)="openProfileDialog()">
    <!-- add avatarFallback to show initials-->
    <vnc-avatar
      defaultAvatar="assets/images/profile.png"
      customSize="48px"
      [imgSrc]="avatarURL"
    ></vnc-avatar>

    <div class="user-info">
      <div class="name">{{ currentUser?.fullName || currentUser?.firstName + " " + currentUser?.lastName }}</div>
      <div class="email">{{currentUser?.email}}</div>
    </div>
  </div>

  <div *ngIf="quotaMsg" class="quota-section quota-section-menu">
    <div class="quota-message-block">
      <div class="quota-message-text" [innerHTML]="quotaMsg"></div>
      <span class="quota-info-icon">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/info-outline</title>
          <g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z" id="icon" fill="#8B96A0"></path>
          </g>
      </svg>
      </span>
    </div>
      <mat-progress-bar class="quota-progress-bar" [ngClass]="{'dfb-theme': currentTheme === 'dfb'}" mode="determinate" [value]="quotaPercent"></mat-progress-bar>
  </div>

  <div mat-menu-item class="menu-item expandble" [matMenuTriggerFor]="headerPreferenceMenu" *ngIf="isPreferenceEnabled">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="wrench-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'ACCOUNT_PREFERENCES' | translate }}</span>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="keyboard-arrow-right-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
  </div>

  <div mat-menu-item class="menu-item expandble" [matMenuTriggerFor]="headerHelpMenu" *ngIf="!hideFAQMenu">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="help-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'HELP_LABEL' | translate }}</span>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="keyboard-arrow-right-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
  </div>

  <div mat-menu-item class="menu-item expandble" [matMenuTriggerFor]="headerLegalNoticeMenu" *ngIf="!hideLegalNoticeMenu">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="file-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'LEGAL_NOTICE' | translate }}</span>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="keyboard-arrow-right-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
  </div>

  <div class="menu-action-buttons">
    <vnc-button
      class="themed-control"
      shape="rectangle"
      [height]="'small'"
      [type]="'link'"
      label="{{ 'LOGOUT' | translate }}"
      (click)="logout()">
    </vnc-button>
  </div>

</mat-menu>

<!-- TODO: a DBF menu - check it -->
<!-- <mat-menu #headerMenuQuote="matMenu" class="quota-header-menu">
  <div [innerHTML]="quotaMsg" class="quota-msg">
  </div>
</mat-menu> -->

<!-- Settings submenu -->
<mat-menu #headerPreferenceMenu="matMenu" class="header-menu-common">

  <div (click)="navigatorTo('/preferences/general')" mat-menu-item>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="wrench-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'MAIN_PREFERENCES' |translate }}</span>
  </div>
  <div (click)="changeAppearance()" mat-menu-item *ngIf="!themeChangeRestricted">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="appearance-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'APPEARANCE' |translate }}</span>
  </div>
  <div (click)="navigatorTo('/preferences/accounts')" mat-menu-item>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="user-profile-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'ACCOUNTS' | translate }}</span>
  </div>
  <div (click)="changePassword()" mat-menu-item *ngIf="zimbraFeatureChangePasswordEnabled && !isCordovaOrElectron">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="lock-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'CHANGE_PASSWORD_LBL' |translate }}</span>
  </div>

  <mat-divider class="setting-menu-divider"></mat-divider>

  <ng-container *ngIf="!calendarOnly">
    <div (click)="navigatorTo('/preferences/mail')" mat-menu-item>
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="wrench-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'MAIL_PREFERENCES' | translate }}</span>
    </div>

    <div (click)="navigatorTo('/preferences/contact-settings')" mat-menu-item *ngIf="!isUseDirectoryAuth">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="users-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'PREFERENCE_CONTACT_LBL' | translate }}</span>
    </div>

    <div (click)="navigatorTo('/preferences/filters')" mat-menu-item *ngIf="isMailFilterFeatureEnabled">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="filter-list" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'FILTERS' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/signatures')" mat-menu-item>
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="gesture" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'SIGNATURES' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/out-of-office')" mat-menu-item *ngIf="isOutOfOfficeFeatureEnabled">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="time-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'OUT_OF_OFFICE' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/trustee_address')" mat-menu-item *ngIf="!hideTrustedAddressesFromSettings">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="spellcheck" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'TRUSTEE_ADDRESS' | translate }}</span>
    </div>
  </ng-container>

  <div (click)="navigatorTo('/preferences/calendar-settings')" mat-menu-item>
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="date-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'CALENDAR_PREF_LBL' | translate }}</span>
  </div>
  <ng-container *ngIf="!calendarOnly">
    <div (click)="navigatorTo('/preferences/notifications')" mat-menu-item>
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="add-alert-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'NOTIFICATIONS_PREFERENCES' | translate }}</span>
    </div>
  </ng-container>

  <div (click)="navigatorTo('/preferences/briefcase-notification')" mat-menu-item *ngIf="!calendarOnly && isBriefcaseNotificationEnabled">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="portfolio-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'BRIECASE_NOTIFICATIONS_PREFERENCES' | translate }}</span>
  </div>

  <mat-divider class="setting-menu-divider"></mat-divider>

  <ng-container *ngIf="!calendarOnly">
    <div (click)="navigatorTo('/preferences/import-export')" mat-menu-item>
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="arrow-mport-export" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'IMPORT_EXPORT' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/keyboard-shortcuts')" mat-menu-item>
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="keyboard-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'KEYBOARD_SHORTCUTS' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/sharing')" mat-menu-item *ngIf="isSharingFeatureEnabled">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="share-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'PREFRENCES_SHARING' | translate }}</span>
    </div>
    <div (click)="navigatorTo('/preferences/security')" mat-menu-item *ngIf="isSecurityFeatureEnabled">
      <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="lock-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div>
      <span>{{ 'SECURITY_PREF_LBL' | translate }}</span>
    </div>
    <div (click)="startResync()" mat-menu-item >
      <!-- <div class="menu-icon-wrapper">
        <vnc-icon type="product" name="lock-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
      </div> -->
      <span class="menu-icon-resync">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/sync</title>
          <g id="Icon/product/sync" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                  <path d="M8,3 L8,0 L4,4 L8,8 L8,5 C11.31,5 14,7.69 14,11 C14,12.01 13.75,12.97 13.3,13.8 L14.76,15.26 C15.54,14.03 16,12.57 16,11 C16,6.58 12.42,3 8,3 L8,3 Z M8,17 C4.69,17 2,14.31 2,11 C2,9.99 2.25,9.03 2.7,8.2 L1.24,6.74 C0.46,7.97 0,9.43 0,11 C0,15.42 3.58,19 8,19 L8,22 L12,18 L8,14 L8,17 L8,17 Z" id="Shape"></path>
              </g>
          </g>
        </svg>
      </span>
      <span>{{ 'RESYNC_LBL' | translate }}</span>
    </div>

  </ng-container>

</mat-menu>

<!-- Help submenu -->
<mat-menu #headerHelpMenu="matMenu" class="header-menu-common">

  <div mat-menu-item class="menu-item" (click)="aboutDialog()">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="info-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'APP_INFO' |translate }}</span>
  </div>
  <a href="{{userManual}}" target="_blank" mat-menu-item class="menu-item open-new-window">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="file-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'USER_MANUAL' | translate }}</span>
  </a>
  <a href="{{serviceDeskURL}}" target="_blank" mat-menu-item class="menu-item open-new-window">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="helpdesk-new" [width]="menuIconSide " [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ serviceDeskMenuItemName }}</span>
  </a>
  <a href="{{faqURL}}" target="_blank" mat-menu-item class="menu-item open-new-window">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="help-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'FAQ_LABEL' |translate }}</span>
  </a>
</mat-menu>

<!-- Legal notice submenu -->
<mat-menu #headerLegalNoticeMenu="matMenu" class="header-menu-common">

  <a href="{{termsOfUse}}" target="_blank" mat-menu-item class="menu-item open-new-window">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="file-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'TERMS_OF_USE' | translate }}</span>
  </a>
  <a href="{{dataPrivacy}}" target="_blank" mat-menu-item class="menu-item open-new-window">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="lock-outline" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'DATA_PRIVACY' |translate }}</span>
  </a>
  <a href="{{imprintLink}}" target="_blank" mat-menu-item class="menu-item open-new-window" *ngIf="!hideImprintMenuItem">
    <div class="menu-icon-wrapper">
      <vnc-icon type="product" name="file-new" [width]="menuIconSide" [height]="menuIconSide" [color]="menuIconColor" [hoverColor]="menuIconColor"></vnc-icon>
    </div>
    <span>{{ 'IMPRINT' |translate }}</span>
  </a>

</mat-menu>

<!-- NEW Mobile Header start: TODO -->
<!-- <div *ngIf="screen === 'mobile'" style="width: 100vw" (click)="switchApplication($event)" >
  <span>
    <vnc-mobile-header
      [bgColor]="themeColor"
      [color]="color"
      (onMenuClick)="mobileHemburgerMenu($event)"
      (onSearchClick)="onMobileHeaderSearchPress($event)"
      (onProfileClick)="onMobileHeaderProfilePress($event)"
      [showBellIcon]="(isChannelModuleEnabled && settings.enableChannels) || (isSocialModuleEnabled && settings.enableSocials)"
      (onBellClick)="onBellNotification()"
      [useAvatar]="true"
      [totalUnread]="notificationCount"
      [isShowSearchIcon]="false"
    >
      <vp-avatar vnc-mobile-header-right-avatar *ngIf="currentUser" [jid]="currentUser?.email"
                  [disableTooltip]="true"
                  [size]="'small-header'"
                  [hidestatus]="true"
                  (loadedAvatar)="loadLoggedInUserAvatar($event)"
                  ></vp-avatar>
    </vnc-mobile-header>
  </span>
</div> -->
<!-- NEW Mobile Header End -->

<div class="user-menu" *ngIf="showUserMenu" [ngClass]="{'has-active-call': showActiveCall}">
<div class="user-menu__mask" id="closeMainMenu" (click)="showUserMenu = false; updateMailListScrollBroadcast(false)"></div>
  <div class="user-menu__profile" id="mobile-user-menu-profile" [style.backgroundImage]="profileBackground" [class.hide-avatar]="!displayHeaderAvatar">
    <div *ngIf="displayHeaderAvatar" class="user-menu__profile__avatar" id="mobile-user-menu-profile-avtar" (click) = "openProfileDialog()">
      <vp-avatar [type] = "'profile'" [user]="{firstLastCharacters: getFirstLastCharacters(currentUser), email: currentUser.email}"> </vp-avatar>
    </div>
    <h5 class="screen-name-mobile" id="mobile-screen-user-email" (click)="showQuotaInformation()">{{currentUser?.email}}</h5>
  </div>
  <div class="user-menu__options" [class.is-safari]="isSafari" [class.is-browser]="!isCordovaApp">

    <mat-list class="option-list">
      <mat-list-item  *ngIf="!hideAppsMenu" id="appSwitchBtn">
          <mat-icon (click) = "openAppSwitcherDialog()">apps</mat-icon>
          <span class="option-name" (click) = "openAppSwitcherDialog()">{{ 'APPS_TITLE' | translate }}</span>
      </mat-list-item>


      <ng-container *ngIf="calendarOnly">
        <mat-list-item id="mobileSidebarDayView" class="cal-sidebar-row" [class.selected]="calendarView === CalendarView.Day"
        (click)="setView(CalendarView.Day)">
        <mat-icon fontSet="mdi" fontIcon="mdi-view-day"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.DAY_VIEW" | translate }}
        </span>
      </mat-list-item>
      <mat-list-item id="mobileSidebarThreeDayView" class="cal-sidebar-row"
        [class.selected]="calendarView === CalendarView.DayThree"
        (click)="setView(CalendarView.DayThree)">
        <mat-icon fontSet="mdi" fontIcon="mdi-view-column"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.THREE_DAY_VIEW" | translate }}
        </span>
      </mat-list-item>
      <mat-list-item id="mobileSidebarWeekView" class="cal-sidebar-row" [class.selected]="calendarView === CalendarView.Week"
        (click)="setView(CalendarView.Week)">
        <mat-icon fontSet="mdi" fontIcon="mdi-reorder-vertical"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.WEEK_VIEW" | translate }}
        </span>
      </mat-list-item>
      <mat-list-item id="mobileSidebarWorkWeekView" class="cal-sidebar-row"
        [class.selected]="calendarView === CalendarView.Workweek"
        (click)="setView(CalendarView.Workweek)">
        <mat-icon fontSet="mdi" fontIcon="mdi-reorder-vertical"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.WORK_WEEK_VIEW" | translate }}
        </span>
      </mat-list-item>
      <mat-list-item id="mobileSidebarMonthView" class="cal-sidebar-row" [class.selected]="calendarView === CalendarView.Month"
        (click)="setView(CalendarView.Month)">
        <mat-icon fontSet="mdi" fontIcon="mdi-view-module"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.MONTH_VIEW" | translate }}
        </span>
      </mat-list-item>
      <mat-list-item id="mobileSidebarListView" class="cal-sidebar-row" [class.selected]="calendarView === CalendarView.List" (click)="setView(CalendarView.List)">
        <mat-icon fontSet="mdi" fontIcon="mdi-view-list"></mat-icon>
        <span class="option-name">
          {{ "CALENDARS.LIST_VIEW" | translate }}
        </span>
      </mat-list-item>
      </ng-container>
      <ng-container *ngIf="!calendarOnly">
        <mat-list-item id="emailsMenuBtn" (click) = "updateEmailExpanderPanel();">
          <div class="expander-mat-content">
            <div class="expander-panel"><mat-icon>email</mat-icon>
            <span class="option-name">{{ 'EMAILS_TITLE' | translate }}</span>
            <div class="expander-icon" >
              <mat-icon style = "float: right;" id="mobile_sidebar_epandbtn" *ngIf= "!emailOptionExpanded">expand_more</mat-icon>
              <mat-icon style = "float: right;" id="mobile_sidebar_collapse" *ngIf= "emailOptionExpanded">expand_less</mat-icon>
            </div>
            </div>
            <div class = "sub-list" *ngIf= "emailOptionExpanded">
              <div class="sidebar-item" id="mobile_sidebar_inbox" (click) = "routeToSystemFolder('/inbox')">
                <div class="sub-item-icon"> <mat-icon>inbox</mat-icon> </div>
                <div class="item-title">{{ 'INBOX' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_drafts" (click) = "routeToSystemFolder('/drafts')">
                <div class="sub-item-icon"> <mat-icon>drafts</mat-icon> </div>
                <div class="item-title">{{ 'DRAFTS' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_sent" (click) = "routeToSystemFolder('/sent')">
                  <div class="sub-item-icon"> <mat-icon>send</mat-icon> </div>
                  <div class="item-title">{{ 'SENT' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_starred" (click) = "routeToSystemFolder('/starred')">
                  <div class="sub-item-icon"> <mat-icon>star</mat-icon> </div>
                  <div class="item-title">{{ 'STARRED' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_spam" (click) = "routeToSystemFolder('/junk')">
                  <div class="sub-item-icon"> <mat-icon>report</mat-icon> </div>
                  <div class="item-title">{{ 'SPAM' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_trash" (click) = "routeToSystemFolder('/trash')">
                  <div class="sub-item-icon"> <mat-icon>delete</mat-icon> </div>
                  <div class="item-title">{{ 'TRASH' | translate }}</div>
              </div>
              <div class="sidebar-item" id="mobile_sidebar_email-template" (click)="routeToEmailTemplateFolder()" *ngIf="isEmailFormsZimletEnabled">
                <div class="sub-item-icon">
                  <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
                </div>
                <div class="item-title">Email Vorlagen</div>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item id="mailFolders" (click) = "openSubFolders()">
          <mat-icon>folder</mat-icon>
          <span class="option-name">{{ 'FOLDERS' | translate }}</span>
        </mat-list-item>
        <mat-list-item id="mobile-sidebar-tag-list" (click) = "openTags()">
          <mat-icon>local_offer</mat-icon>
          <span class="option-name">{{ 'TAGS' | translate }}</span>
        </mat-list-item>
        <mat-list-item id="mobile-sidebar-contact-list" (click)="openContact()" *ngIf="!isUseDirectoryAuth">
          <mat-icon >person</mat-icon>
          <span class="option-name">{{ 'CONTACTS' | translate }}</span>
        </mat-list-item>
        <mat-list-item id="calendarMenu" (click)="openCalendar()">
          <mat-icon >date_range</mat-icon>
          <span class="option-name">{{'CALENDAR' | translate}}</span>
        </mat-list-item>
        <mat-list-item id="briefcaseMenu" (click)="openBriefcase()" *ngIf="config.isZimbraBriefcaseEnabled">
          <mat-icon >work</mat-icon>
          <span class="option-name">{{ 'BRIEFCASE_TITLE' | translate }}</span>
        </mat-list-item>
      </ng-container>

      <mat-list-item id="mailPreferences" (click) = "openPreferenceDialog()" *ngIf="isPreferenceEnabled">
        <mat-icon>settings</mat-icon>
        <span class="option-name">{{ 'PREFERENCES.SETTINGS' | translate }}</span>
      </mat-list-item>

      <mat-list-item id="aboutsMenu" (click)="mobileAboutDialog()">
          <mat-icon >info_outline</mat-icon>
          <span class="option-name">{{ 'ABOUT_TITLE' | translate }}</span>
      </mat-list-item>
      <span *ngIf="zimbraFeatureChangePasswordEnabled">
        <mat-list-item *ngIf="!displayHeaderAvatar" id="changePassMenu" (click)="changePassword()">
              <mat-icon>lock</mat-icon>
              <span class="option-name">{{ 'CHANGE_PASSWORD_LBL' | translate }}</span>
        </mat-list-item>
      </span>

      <mat-list-item id="logoutMenuBtn" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span class="option-name">{{ 'LOGOUT_TITLE' | translate }}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
<mat-menu #searchMenu="matMenu">
  <button mat-menu-item (click)="saveSearch()"><span>{{ 'SAVE_SEARCH' | translate}}</span></button>
  <button mat-menu-item (click) = "toggleRefineForm()"><span>{{ 'CONTEXT_REFINE_SEARCH_LBL' | translate}}</span></button>
</mat-menu>
<vp-app-switcher (closeSwitch)="switchApp = false" [switchApp]="switchApp"></vp-app-switcher>
<vp-tabs *ngIf="!(calendarOnly && currentUrl.startsWith('/calendar')) && (isCordovaApp || currentUrl)" [menu]="siteHeaderMenu"
[ngClass]="{'in_search_view': this.currentUrl.lastIndexOf('/search')!==-1}"
[class.hide-tabs-mobile]="currentUrl.startsWith('/preferences') || currentUrl.startsWith('/briefcase') ||  currentUrl.indexOf('/detail/') !== -1 || currentUrl.indexOf('/compose') !== -1">
</vp-tabs>
<vp-search-tabs [ngClass]="{'search_tab_view': this.currentUrl.indexOf('/mail/search')!==-1}" [class.hide-tabs-mobile]="currentUrl.indexOf('/detail/') !== -1"></vp-search-tabs>
<div class="overlay" (click)="closeAppAwitcher()" *ngIf="switchApp"></div>
