
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { getSelectedMailTag, MailRootState } from "src/app/mail/store";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { Subject, Observable } from "rxjs";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { takeUntil, take, debounceTime, filter } from "rxjs/operators";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfirmationData, ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ContextMenuComponent, ContextMenuService } from "ngx-contextmenu";
import { CreateTagComponent } from "src/app/mail/create-tag/create-tag.component";
import { SetSelectedMailTag } from "src/app/mail/store/actions";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { getOnlineStatus, RootState, getShowGlobalTags } from "src/app/reducers";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ContextMenuFixService } from "src/app/shared/contextMenu.service";
import { MailService } from "../../services/mail-service";

const DEFAULT_COLOR = "#a2aab3";
const COLOR_LIST = [DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];
@Component({
    selector: "vp-tags-list",
    templateUrl: "./tags-list.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsListComponent implements OnInit, OnDestroy {
    tags: MailTag[] = [];
    defaultColor = DEFAULT_COLOR;
    expanded: boolean = false;
    isFullView: boolean = false;
    searchTag: string = "";
    isSearchTag: boolean = false;
    @ViewChild("tagMenu", {static: false}) contextMenu: ContextMenuComponent;
    @Input() moduleType = "mail";
    @Input() selectedTagName;
    @Output() selectTagEvent = new EventEmitter<MailTag>();
    @Output() deleteTagEvent = new EventEmitter<MailTag>();
    @Output() isFullViewOfTags = new EventEmitter<boolean>();
    @ViewChild("tagNameSearchInput", {static: false}) tagNameSearchInput: ElementRef<HTMLInputElement>;
    CONTACT_FAVORITE_TAG: string = "contact_favourite";
    ColorPickerValue: string = "";
    contextMenuActions = {
        "isEnableNewTag": true,
        "isEnableMarkRead": false,
        "isEnableRename": true,
        "isEnableDelete": true,
        "isEnableColorControl": true,
        "isEnableColorEdit": true
    };
    tagColor: string = DEFAULT_COLOR;
    selectedTag: MailTag;
    showEditColor: boolean = true;
    private isAlive$ = new Subject<boolean>();
    private changeColor = new Subject();
    currentTab: MailTag;
    currentTagName: unknown;
    showGlobalTags: boolean = false;
    currentTheme: string = "default";
    constructor(
        private commonRepository: CommonRepository,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<MailRootState | RootState>,
        private router: Router,
        private dialog: MatDialog,
        private mailService: MailService,
        private contextMenuService: ContextMenuFixService,
        private mailBroadcaster: MailBroadcaster,
        private hotKeyService: NgxHotkeysService,
        private configService: ConfigService
    ) {
        const currentName = localStorage.getItem("theme") || environment.theme;
        if (currentName === "dfb") {
          this.currentTheme = "dfb";
        } else {
          this.currentTheme = "default";
        }
        this.store.select(getOnlineStatus).pipe(filter(v => !!v), take(1)).subscribe(v => {
            if (this.configService.useVNCdirectoryAuth && this.showGlobalTags) {
                this.loadTags();
            } else {
                setTimeout(() => {
                    this.loadTags();
                }, environment.isCordova || environment.isElectron ? 3000 : 1000);
            }
        });
        this.mailService.currentTagName.asObservable().pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.currentTagName = res;
            this.changeDetectionRef.markForCheck();
        });

        this.store.select(getSelectedMailTag).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.currentTab = res;
            this.changeDetectionRef.markForCheck();
          });

        this.store.select(getShowGlobalTags).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            console.log("[TagListGetShowGlobalTags: ", res);
            const newShowGlobalTags = res === "true";

            if (newShowGlobalTags !== this.showGlobalTags) {
                this.showGlobalTags = newShowGlobalTags;
                if (this.configService.useVNCdirectoryAuth && this.showGlobalTags) {
                    this.loadTags();
                } else {
                    setTimeout(() => {
                        this.loadTags();
                    }, environment.isCordova || environment.isElectron ? 3000 : 1000);
                }
            }
        });
    }

    ngOnInit() {
        this.changeColor.pipe(
            debounceTime(1000)
        ).subscribe(e => {
            // update color
            this.closeContextMenuOnScroll();
            this.commonRepository.updateTagColor(this.selectedTag.id, this.tagColor, this.moduleType);
            this.changeDetectionRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    openTag(tag: MailTag): void {
        if (this.moduleType === "mail") {
          this.store.dispatch(new SetSelectedMailTag(tag));
            this.router.navigate(["/mail/tag", tag.name]);
        }
        this.selectTagEvent.emit(tag);
    }

    deleteTag(tag: MailTag): void {
        if (this.commonRepository.showNoInternetToastIfRequired()) {
            return;
        }

        const data: ConfirmationData = {
            action: "delete",
            titleKey: "WARNING",
            contentKey: "DELETE_TAG_CONFIRMATION",
            contentParam: { tagName: tag.name },
            actionKey: "YES"
        };
        this.openConfirmationDialog(data, { width: "300px" }).pipe(take(1)).subscribe(result => {
            if (result && result.confirmed) {
                this.commonRepository.deleteTag(tag.id).subscribe(tagId => {
                    console.log("[deleteTag]", tagId);
                    if (tagId !== null) {
                        if (this.moduleType === "mail") {
                            this.mailBroadcaster.broadcast(MailConstants.REMOVED_TAG, tag);
                        }
                        this.deleteTagEvent.emit(tag);
                    }
                });
            }
        });
    }

    deleteSelectedTag(): void {
        this.deleteTag(this.selectedTag);
    }

    private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: data,
            ...style
        });
        return dialogRef.afterClosed();
    }

    onContextMenu($event: MouseEvent, tag: MailTag) {
        $event.stopPropagation();
        $event.preventDefault();
        console.log("[onContextMenu]", tag);
        this.selectedTag = tag;
        this.tagColor = tag.rgb || tag.color || DEFAULT_COLOR;
        this.contextMenuService.show.next({
            contextMenu: this.contextMenu,
            event: $event,
            item: tag,
        });
    }

    updateDefaultColor(color: string) {
        this.tagColor = color;
        this.changeColor.next(true);
    }

    handleColorChange(event: any) {
        this.ColorPickerValue = event.color.hex;
    }

    saveColor(event) {
        event.preventDefault();
        event.stopPropagation();
        this.tagColor = this.ColorPickerValue;
        this.changeColor.next(true);
        this.contextMenuService.closeAllContextMenus({
            eventType: "cancel",
        });
    }

    closePicker(event, type) {
        event.preventDefault();
        event.stopPropagation();
        if (type === "cancel") {
            this.contextMenuService.closeAllContextMenus({
                eventType: "cancel",
            });
        }
        if (type === "leave") {
            const allcontextMenu = document.querySelectorAll(".ngx-contextmenu");
            if (allcontextMenu.length > 0)  {
                allcontextMenu.forEach( (elem) => {
                    const element = elem.querySelector(".expanded-color-palet-menu");
                    if (element) {
                        const dropdown = elem.querySelector(".dropdown-menu");
                        if (dropdown) {
                            dropdown["style"].visibility = "hidden";
                        }
                    }
                });
            }
        }
    }

    createNewTag() {
        if (this.commonRepository.showNoInternetToastIfRequired()) {
          return;
        }

        this.dialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "tag_create_dialog",
            data: {isRename: false }
        });
    }

    renameTag() {
        if (this.commonRepository.showNoInternetToastIfRequired()) {
          return;
        }

        this.dialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "tag_create_dialog",
            data: { tag: this.selectedTag, isRename: true }
        });
    }

    openSubMenu(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        const allcontextMenu = document.querySelectorAll(".ngx-contextmenu");
        if (allcontextMenu.length > 0)  {
            allcontextMenu.forEach( (elem) => {
                const element = elem.querySelector(".expanded-color-palet-menu");
                if (element) {
                    const dropdown = elem.querySelector(".dropdown-menu");
                    if (dropdown) {
                        dropdown["style"].visibility = "visible";
                    }
                }
            });
        }
    }

    closeContextMenuOnScroll() {
        if (MailUtils.isIE() && this.contextMenuService && document.querySelector(".ngx-contextmenu")) {
            const keyEvent = new KeyboardEvent("keydown", { key: "Escape" });
            this.contextMenuService.closeAllContextMenus({
                eventType: "cancel",
                event: keyEvent
            });
        }
    }

    getTagColor(color) {
        const isDark = MailUtils.lightOrDark(color) === "dark";
        if (isDark) {
            return "#ffffff";
        } else {
            return "#000000";
        }
    }

    showOnlyTagsList(val) {
        this.isFullView = val;
        if (val) {
            this.expanded = true;
        }
        this.isFullViewOfTags.emit(val);
    }

    viewSearchTagOption(val) {
        this.searchTag = "";
        this.isSearchTag = val;
        if (this.isSearchTag) {
            this.hotKeyService.pause(this.hotKeyService.hotkeys);
            this.setFocus();
        } else {
           this.hotKeyService.unpause(this.hotKeyService.hotkeys);
        }
    }

    setFocus() {
        setTimeout(() => {
        if (this.tagNameSearchInput) {
            this.tagNameSearchInput.nativeElement.focus();
        }
        }, 200);
    }

    getMaxHeight() {
        let maxHeight = "200px";
        if (this.tags.length < 7) {
            maxHeight = this.tags.length * 30 + "px";
        }
        if (this.isFullView) {
            maxHeight = "unset";
        }
        return maxHeight;
    }

    loadTags(): void {
        console.log("[tag-list][loadTags]:", this.configService.useVNCdirectoryAuth, this.showGlobalTags);
        this.commonRepository.getTagsListWithParam(this.showGlobalTags).pipe(takeUntil(this.isAlive$))
            .subscribe(tags => {
                // this.tags = [];
                this.tags = tags.map(tag => {
                    if (tag.color && COLOR_LIST[tag.color]) {
                        tag.color = COLOR_LIST[tag.color];
                    }
                    if (tag.rgb) {
                        tag.rgb = tag.rgb.toLowerCase();
                        if (tag.rgb === "#999") {
                            tag.rgb = "#999999";
                        }
                    }
                    return tag;
                });
                this.tags = _.sortBy(this.tags, t => t.name.toLowerCase());
                this.changeDetectionRef.markForCheck();
            });
    }
}
