
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "../../common/providers/toast.service";
import { Subject } from "rxjs/internal/Subject";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailService } from "../shared/services/mail-service";
import { SearchRequest } from "../shared/models";
import { ErrorService } from "../../common/providers/error-service";
import { ErrorType } from "../../common/enums/mail-enum";
import { take, takeUntil, distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { RootState, getAllUserContacts, getVNCDContactList, getOnlineStatus, getZimbraFeatures } from "src/app/reducers";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { getContactsList } from "src/app/contacts/store";
import { ConfigService } from "src/app/config.service";
import { DatabaseService } from "src/app/services/db/database.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "vp-select-addresses",
  templateUrl: "./select-addresses-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SelectAddressesDialogComponent implements OnInit, OnDestroy {
  maxTagNameLength: number = 128;
  private isAlive$ = new Subject<boolean>();
  allEmailAddresses: any[] = [];
  selectedAllEmailAddresses: any[] = [];
  finalEmailAddresses: any[] = [];
  selectedFinalEmailAddresses: any[] = [];
  selectedShowOption: string = "";
  searchText: string = "";
  allUserContacts: any[] = [];
  offset: number = 0;
  limit: number = 50;
  isContact: boolean = false;
  isOnline: boolean = false;
  isLoading = false;
  iszimbraFeatureGalEnabled: boolean = false;
  selectedVal: string = "To";
  isMoreContact: boolean = true;
  contactsList: any[] = [];
  currentTheme: string = "default";
  constructor(
    private dialogRef: MatDialogRef<SelectAddressesDialogComponent>,
    public toastService: ToastService,
    private mailService: MailService,
    public configService: ConfigService,
    private changeDetectionRef: ChangeDetectorRef,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private dbService: DatabaseService,
    private store: Store<RootState>
  ) {
    const currentName = localStorage.getItem("theme") || environment.theme;
    if (currentName === "dfb") {
      this.currentTheme = "dfb";
    } else {
      this.currentTheme = "default";
    }
    this.finalEmailAddresses = data;
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(o => {
      this.isOnline = o;
      if (!this.isOnline) {
        if (this.selectedShowOption !== "contacts") {
          this.selectedShowOption = "contacts";
          this.changeShowNames(this.selectedShowOption);
          this.changeDetectionRef.markForCheck();
        }
      }
    });
    if (this.configService.useVNCdirectoryAuth) {
      this.store.select(getVNCDContactList).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.contactsList = res;
      });
    }
    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.iszimbraFeatureGalEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_GAL_ENABLED);
      this.resetOffset();
      if (this.iszimbraFeatureGalEnabled && this.isOnline) {
        this.selectedShowOption = "global";
        this.changeShowNames(this.selectedShowOption);
      } else {
        this.selectedShowOption = "contacts";
        this.changeShowNames(this.selectedShowOption);
      }
    });
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit(): void {
    this.store.select(getAllUserContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserContacts = res;
      // console.log("[getAllCuserContacts] : ", this.allUserContacts);
      this.changeDetectionRef.markForCheck();
    });
  }

  closeDialog(): void {
    this.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  close(): void {
    this.dialogRef.close();
  }

  setSelected(): void {
    const groupEmailAddress: any[] = [];
    this.finalEmailAddresses.map( item => {
      if (item.isGroup) {
        const emails = item.email.split(",");
        emails.map( emailItem => {
          const user = {
            title: "",
            name: "",
            email: "",
          };
          if (emailItem.indexOf("<") === -1) {
            user.name = emailItem;
            user.email = emailItem;
          } else {
            user.title = emailItem ? emailItem.replace(/"/g, "").trim() : "";
            user.name = emailItem ? emailItem.substring(emailItem.indexOf(""), emailItem.indexOf("<") - 1) : "";
            user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
            user.email = emailItem ? emailItem.substring(emailItem.indexOf("<") + 1, emailItem.indexOf(">")) : "";
            user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
          }
          const dataItem = { d: user.name, name: user.name, email: user.email, t: item.t };
          groupEmailAddress.push(dataItem);
        });
      }
    });
    if (groupEmailAddress && groupEmailAddress.length > 0) {
      this.finalEmailAddresses = [...this.finalEmailAddresses, ...groupEmailAddress];
    }
    this.finalEmailAddresses = this.finalEmailAddresses.filter( finalEmail => !finalEmail.isGroup && finalEmail.email !== "");
    console.log("[Selected EmailAddress]: ", this.finalEmailAddresses);
    this.dialogRef.close({ selectedAddress: this.finalEmailAddresses });
  }

  changeShowNames(value, isSearch?: boolean) {
    if (value === "global") {
      this.isLoading = true;
      const query = {
        name: this.searchText !== "" ? this.searchText : "",
        limit: this.limit,
        sortby: "nameAsc",
        offset: this.offset
      };
      this.mailService.searchGalRequest(query).pipe(take(1)).subscribe( res => {
        this.isLoading = false;
        this.isMoreContact = res.more;
        if (res.cn) {
          res.cn.forEach(ele => {
            if (ele.m) {
              this.addGroupMemberToEmailList(ele);
            } else {
              const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
              const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
              const fullNameUser = !!fullName ? fullName : ele._attrs.email;
              this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
            }
          });
          this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
          this.changeDetectionRef.markForCheck();
          this.sortByNameAddress();
        } else {
          this.isContact = false;
        }
        this.changeDetectionRef.markForCheck();
      }, error => {
        this.errorService.emit({ id: ErrorType.Generic, messages: error });
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
      });

    } else if (value === "personalandshared") {
      this.getContactFolders(isSearch);
    } else {
      this.isLoading = true;
      if (this.isOnline) {
        const query: SearchRequest = {
          field: "contact",
          limit: this.limit,
          needExp: 1,
          offset: this.offset,
          query: this.searchText !== "" ? "(\"" + this.searchText + "\") (is:local)" : "(is:local)",
          sortBy: "nameAsc",
          types: "contact"
        };
        this.mailService.searchRequest(query).pipe(take(1)).subscribe(
          res => {
            this.isMoreContact = res.more;
            this.isLoading = false;
            if (res.cn) {
              res.cn.forEach(ele => {
                if (ele.m) {
                  this.addGroupMemberToEmailList(ele);
                } else {
                  const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                  const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                  const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
                  this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                }
              });
              this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
            } else {
              this.isContact = false;
            }
            this.changeDetectionRef.markForCheck();
          }, error => {
            this.isLoading = false;
            this.changeDetectionRef.markForCheck();
            this.errorService.emit({ id: ErrorType.Generic, messages: error });
          });
      } else {
        this.dbService.searchContacts(this.searchText.trim()).subscribe(res => {
          console.log("[selectEmailDBResults: ", res);
          this.isMoreContact = res.more;
          this.isLoading = false;

            res.forEach(ele => {
              if (ele.m) {
                this.addGroupMemberToEmailList(ele);
              } else {
                const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
                this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
              }
            });
            this.allEmailAddresses = this.allEmailAddresses.filter(e => !!e.email && e.email !== "");

          this.changeDetectionRef.markForCheck();
        }, error => {
          this.isLoading = false;
          this.changeDetectionRef.markForCheck();
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
      }
    }
  }

  getContactsFromList(id: number) {
    this.isLoading = true;
      const query: any = {
        field: "contact",
        limit: this.limit,
        needExp: 1,
        offset: this.offset,
        query: this.searchText || "",
        sortBy: "nameAsc",
        types: "contact",
      };
      if (!!id) {
        query.contact_list = id;
      }
      this.mailService.searchRequest(query).pipe(take(1)).subscribe(
        res => {
          this.isMoreContact = res.more;
          this.isLoading = false;
          if (res.cn) {
            res.cn.forEach(ele => {
              if (ele.m) {
                this.addGroupMemberToEmailList(ele);
              } else {
                const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
                this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
              }
            });
            this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
          } else {
            this.isContact = false;
          }
          this.changeDetectionRef.markForCheck();
        }, error => {
          this.isLoading = false;
          this.changeDetectionRef.markForCheck();
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
  }

  getContactFolders(isSearch) {
    this.isLoading = true;
    this.changeDetectionRef.markForCheck();
    const body = {
      view: "contact",
    };
    this.mailService.getContactFolders(body).pipe(take(1)).subscribe(
      res => {
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
        let que = "";
        if (res.folder[0].link) {
          for (let i = 0; i < res.folder[0].link.length; i++) {
            if (i === 0) {
              que += "inid:" + res.folder[0].link[i].id;
            } else {
              que += " OR inid:" + res.folder[0].link[i].id;
            }
          }
        }
        if (que.length > 0) {
          que = "(" + que + " OR is:local)";
        } else {
          que = "(is:local)";
        }
        if (this.searchText !== "") {
          que = "(" + this.searchText + ")" + que;
        }
        const query: SearchRequest = {
          field: "contact",
          limit: this.limit,
          needExp: 1,
          offset: this.offset,
          query: que,
          sortBy: "nameAsc",
          types: "contact"
        };
        this.isLoading = true;
        this.changeDetectionRef.markForCheck();
        this.mailService.searchRequest(query).pipe(take(1)).subscribe(searchRes => {
          this.isLoading = false;
          this.isMoreContact = searchRes.more;
          if (searchRes.cn) {
            searchRes.cn.forEach(ele => {
              if (ele.m) {
                this.addGroupMemberToEmailList(ele);
              } else {
                const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
                this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
              }
            });
            this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
          } else {
            this.isContact = false;
          }
          this.changeDetectionRef.markForCheck();
        }, error => {
          this.isLoading = false;
          this.changeDetectionRef.markForCheck();
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
      },
      err => {
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }
  selectContactItem(data: any, index: number, $event: MouseEvent): void {
    $event.preventDefault();
    const objCopy = Object.assign({}, data);
    objCopy["t"] = this.selectedVal;
    if (!this.isAlreadyAdded(objCopy)) {
      this.finalEmailAddresses.push(objCopy);
    }
    this.changeDetectionRef.markForCheck();
  }

  selectEmailAddress(data: any, index: number, $event: MouseEvent): void {
    $event.preventDefault();
    if (this.selectedAllEmailAddresses.indexOf(data) === -1) {
      if ($event.ctrlKey) {
        this.selectedAllEmailAddresses.push(data);
      } else {
        this.selectedAllEmailAddresses = [];
        this.selectedAllEmailAddresses.push(data);
      }
    } else {
      if (this.selectedAllEmailAddresses.length > 1) {
        this.selectedAllEmailAddresses.splice(this.selectedAllEmailAddresses.indexOf(data), 1);
      }
    }
  }

  selectFinalEmailAddresses(data: any, index: number, $event: MouseEvent): void {
    $event.preventDefault();
    if (this.selectedFinalEmailAddresses.indexOf(data) === -1) {
      if ($event.ctrlKey) {
        this.selectedFinalEmailAddresses.push(data);
      } else {
        this.selectedFinalEmailAddresses = [];
        this.selectedFinalEmailAddresses.push(data);
      }
    } else {
      if (this.selectedFinalEmailAddresses.length > 1) {
        this.selectedFinalEmailAddresses.splice(this.selectedFinalEmailAddresses.indexOf(data), 1);
      }
    }
  }

  isSelectedEmailAddress(email: any): boolean {
    return this.selectedAllEmailAddresses.indexOf(email) !== -1;
  }

  isselectFinalEmailAddresses(email: any): boolean {
    return this.selectedFinalEmailAddresses.indexOf(email) !== -1;
  }

  isAlreadyAdded(add) {
    let isAdded = false;
    for (let i = 0; i < this.finalEmailAddresses.length; i++) {
      if (this.finalEmailAddresses[i].email === add.email && this.finalEmailAddresses[i].t === add.t) {
        isAdded = true;
        break;
      }
    }
    return isAdded;
  }

  removeSelectedAddresses(contactItem) {
      const addIndex = this.finalEmailAddresses.findIndex((e) => e.email === contactItem.email && e.t === contactItem.t);
      if (addIndex !== -1) {
        this.finalEmailAddresses.splice(addIndex, 1);
      }
  }

  onUserScroll(event): void {
    const allEmailAddressesList = event.target;
  }

  searchEmailAddresses() {
    this.resetOffset();
    this.changeShowNames(this.selectedShowOption, true);
  }

  getGroupMember(item: any): string {
    let emailAndName: string = "";
    if (item.type === "C") {
      const id = item.value;
      if (!!id && id !== null) {
        const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.id === id)[0];
        if (!!contactItem && contactItem !== null && contactItem._attrs) {
          const name = contactItem._attrs.fullName || contactItem._attrs.firstName;
          let fullName = "";
          if (!!name) {
            fullName = `"${name}"` + " " + `<${contactItem._attrs.email}>`;
          } else {
            fullName = contactItem._attrs.email;
          }
          emailAndName = fullName;
        }
      }
    } else if (item.type === "I") {
      emailAndName = item.value;
    } else if (item.type === "G") {
      const id = item.value;
      if (!!id && id !== null) {
        const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.value && c.value === id)[0];
        if (!!contactItem && contactItem !== null && contactItem._attrs) {
          const name = contactItem._attrs.fullName || contactItem._attrs.firstName;
          let fullName = "";
          if (!!name) {
            fullName = `"${name}"` + " " + `<${contactItem._attrs.email}>`;
          } else {
            fullName = contactItem._attrs.email;
          }
          emailAndName = fullName;
        }
      }
    }
    return emailAndName;
  }

  addGroupMemberToEmailList(ele: any): void {
    let fullName = "";
    if (ele._attrs) {
      fullName = ele._attrs.fullName || ele._attrs.nickname;
    }
    const members = ele.m;
    const memberNameAndEmail: any [] = [];
    members.map( item => {
      const memberEmailName = this.getGroupMember(item);
      if (memberEmailName !== "") {
        memberNameAndEmail.push(memberEmailName);
      }
    });
    this.allEmailAddresses.push({ d: fullName, name: fullName, email: memberNameAndEmail.toString(), isGroup: true });
    this.changeDetectionRef.markForCheck();
  }

  sortByNameAddress(): void {
    this.allEmailAddresses = this.allEmailAddresses.sort((a, b) => {
      return b.name.toLowerCase() < a.name.toLowerCase() ?  1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
    });
    this.changeDetectionRef.markForCheck();
  }

  resetOffset(): void {
    this.offset = 0;
    this.allEmailAddresses = [];
    this.changeDetectionRef.markForCheck();
  }

  onValChange(val: string) {
    this.selectedVal = val;
  }

  scrollMoreContacts(event: any): void {
    if (event && event.target && !this.isLoading) {
        if (Math.ceil(event.target.scrollTop) + event.target.offsetHeight >= event.target.scrollHeight) {
            if (this.isMoreContact) {
              this.offset += this.limit;
              this.changeShowNames(this.selectedShowOption);
            }
        }
    }
  }
}
