
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, OnDestroy, NgZone, ViewChild, ChangeDetectorRef, ElementRef, HostListener, AfterViewInit } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { FormControl } from "@angular/forms";
import { DateAdapter as DateAdapter2, CalendarView, DAYS_OF_WEEK } from "../vp-calendar/vp-calendar.module";
import * as moment from "moment-timezone";
import { CalendarRepository } from "../repositories/calendar.repository";
import { CalendarComposeViewDefaultControl, KeyValue, CalendarFolder } from "src/app/common/models/calendar.model";
import { AppointmentInCompose, Appointment } from "src/app/common/models/appoinment.model";
import { take, filter, takeUntil } from "rxjs/operators";
import { AutocompleteComponent } from "src/app/shared/components/autocomplete/autocomplete.component";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { UserProfile } from "src/app/shared/models";
import { getUserProfile, getCalendarFolders, getZimbraFeatures, getOnlineStatus } from "src/app/reducers";
import { CommonUtils } from "src/app/common/utils/common-util";
import { isArray } from "util";
import jstimezonedetect from "jstimezonedetect";
import { ToastService } from "src/app/common/providers/toast.service";
import { forkJoin, Observable } from "rxjs";
import * as _ from "lodash";
import { SaveConfirmationDialogComponent } from "../save-appointment-confirmation/save-appointment-confirmation.component";
import { SendUpdateAttendeeDialogComponent } from "../send-update-attendee/send-update-attendee.component";
import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { SuggestLocationDialogComponent } from "../suggest-location-dialog/suggest-location-dialog.component";
import { CommonService } from "src/app/services/ common.service.";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { EmailInformation } from "src/app/mail/shared/models";
import { CalenderUtils } from "../utils/calender-utils";
import { ElectronService } from "src/app/services/electron.service";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { registerLocaleData, DatePipe } from "@angular/common";
import { CalendarConstants } from "src/app/common/utils/calendar-constants";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ConfigService } from "src/app/config.service";
import TIMEZONE from "src/app/common/utils/timezone";
import { CalendarEquipmentDialogComponent } from "src/app/shared/components/calendar-equipment-dialog/calendar-equipment-dialog.component";
import { ConflictEquipmentDialogComponent } from "src/app/shared/components/conflict-equipment-dialog/conflict-equipment-dialog.component";
import {
  CalendarEquipmentAutoCompleteComponent
} from "src/app/shared/components/calendar-equipment-auto-complete/calendar-equipment-autocomplete.component";
import { SaveChangeAppointmentDialogComponent } from "src/app/shared/components/save-change-appointment/save-change-appointment.component";
import { CalenderAutocompleteComponent } from "../calender-autocomplete/calender-autocomplete.component";
import {
  CalendarFindLocationDialogComponent
} from "src/app/shared/components/calendar-find-location-dialog/calendar-find-location-dialog.component";
import {
  CalendarSelectAddressesDialogComponent
} from "src/app/shared/components/calendar-select-addresses-dialog/select-addresses-dialog.component";
import {
  CalendarMobileSelectAddressDialogComponent
} from "src/app/shared/components/calendar-mobile-select-address-dialog/mobile-select-address-dialog.component";
import { Router } from "@angular/router";
import { CustomRepeatDialogComponent } from "src/app/shared/components/custom-repeat-dialog/custom-repeat-dialog.component";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { DatePickAdapterService } from "../service/date-picker-service";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { EmailNotificationConfigureComponent } from "src/app/shared/components/email-notification-configure/email-notification-configure.component";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { SchedulerComponent } from "../vp-calendar/scheduler/scheduler.component";
import { ScheduleAssistantComponent } from "../vp-calendar/schedule-assistant/schedule-assistant.component";
import { DatabaseService } from "src/app/services/db/database.service";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { SharedCalendarService } from "../service/shared-calendar-service";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";


@Component({
  selector: "vp-calender-edit-appointment-dialog",
  templateUrl: "./edit-appointment-dialog.component.html",
  providers: [{
      provide: DateAdapter, useClass: DatePickAdapterService
  }]
})
export class EditAppointmentDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  private isAlive$ = new Subject<boolean>();
  startDateFormControl: FormControl = new FormControl(new Date());
  endDateFormControl: FormControl = new FormControl(new Date());
  passwordControl: FormControl = new FormControl("");
  urlControl: FormControl = new FormControl("");
  isVNCTalkCall = false;
  startTimeControl: Date;
  endTimeControl: Date;
  calendarComposeView: CalendarComposeViewDefaultControl;
  editAppointmentModel: AppointmentInCompose;
  event: any;
  @ViewChild("attendeeAutoComplete", { static: false }) attendeeAutoComplete: AutocompleteComponent;
  @ViewChild("optionalAutoComplete", { static: false }) optionalAutoComplete: AutocompleteComponent;
  @ViewChild("vpSuggestLocation", {static: false}) vpSuggestLocation: SuggestLocationDialogComponent;
  @ViewChild("mobileAttachmentUpload", { static: false }) mobileAttachmentUpload: ElementRef;
  @ViewChild("toAttendeeAutoComplete", { static: false }) toAttendeeAutoComplete: AutocompleteComponent;
  @ViewChild("equipmentAutoComplete", { static: false }) equipmentAutoComplete: CalendarEquipmentAutoCompleteComponent;
  @ViewChild("locationAutoComplete", { static: false }) locationAutoComplete: CalendarEquipmentAutoCompleteComponent;
  @ViewChild(SchedulerComponent, { static: false }) schedulerComponent: SchedulerComponent;
  @ViewChild(ScheduleAssistantComponent, { static: false }) scheduleAssistantComponent: ScheduleAssistantComponent;
  attendeeLbl: string = "";
  toLBL: string = "";
  optionalAttendeeslbl: string = "";
  locationPlaceholder: string = "";
  appointment: Appointment;
  displayOptions: KeyValue[];
  calendarFolders: CalendarFolder[];
  currentUser: UserProfile;
  reminderUnit = [0, 0, 1, 5, 10, 15, 30, 45, 60, 120, 180, 240, 300, 1080, 1440, 2880, 4320, 5760, 10080, 20160];
  reminderLbl = [0, -1, 1, 5, 10, 15, 30, 45, 60, 2, 3, 4, 5, 18, 1, 2, 3, 4, 1, 2];
  alarmReminder: string = "0";
  alarmReminderOption: any;
  alaramList: KeyValue[] = [];
  attachedFileList: any[] = [];
  selectedUploadFiles: any[] = [];

  organizerFromAddress: string;
  editorModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }],
      ["link"],
      ["image"]
    ]
  };
  oldAppointment: Appointment;
  appointmentBodyData: string = "";
  isPlainTextFormat: boolean = false;
  htmlEditor: boolean = true;
  isMobileView: boolean = false;
  browserLang: string = "en";
  browseFileds: any[] = [];
  isShowAppointmentTimeZone: boolean = false;
  startTimeZone: any = {};
  endTimeZone: any = {};
  isForwardAppointment: boolean = false;
  isProposeNewTime: boolean = false;
  timeZone = Object.keys(TIMEZONE).map(key => {
    return { key: key, value: TIMEZONE[key] };
  });
  triggerMenuControlFocus: boolean = false;
  equipmentlbl: string = "";
  allFolders: CalendarFolder[] = [];
  isPastDate: boolean = false;
  hideResourceCalendar: boolean = false;
  isReminderConfigClick: boolean = false;
  isAppointmentChange: boolean = false;
  recurringData: any;
  disableRepeat: boolean = false;

  repeatWeeklyDays = [];
  repeatYearlyMonthsList = 1;
  _startDate: Date;
  repeatCustomCount = 1;
  repeatCustom = "0";
  repeatCustomMonthDay: any;
  repeatCustomType = "S";
  repeatMonthlyDayList = null;
  repeatWeekday = false;
  repeatCustomDayOfWeek = "SU";
  repeatCustomDays = [];
  repeatCustomOrdinal: any;
  repeatBySetPos: any;
  repeatEndType = "N";
  repeatEndDate = null;
  repeatEndCount = 1;
  repeatType: string = "DAI";
  weekList: string[] = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  blurb: string = "";
  isSaveSendClick: boolean = false;
  foundMisspelled: boolean;
  misspelledBody: string;
  spelling: any;
  isChecking: boolean;
  ignoreList: any[];
  suggestions: any;
  clickedWord: string;
  clickedTarget: HTMLElement;
  iszimbraFeatureGalEnabled: boolean = false;
  isRsvp: boolean = true;
  rsvpDisabled: boolean = true;
  isPrefCalendarReminderEmail: boolean = false;
  prefCalendarreminderEmail: string = "";
  messageItem: any = "";
  isMoreDetail: boolean = false;
  showScheduler: boolean = false;
  initScheduler: boolean = false;
  isConflicted: boolean = false;
  conflictEmails = {};
  locationConflict: any = false;
  currentLocale: string;
  isOnline = false;
  showTimeSuggestion: boolean = false;
  meetingJid: any;
  sendButtonLocked: boolean = false;
  newAppointmentModel: AppointmentInCompose;
  CalendarConstants = CalendarConstants;
  postEditMoveToFolderId: string = "";
  validStartTime: boolean = true;
  validEndTime: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<EditAppointmentDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter2,
    private calendarRepository: CalendarRepository,
    private commonService: CommonService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<CalendarState>,
    private toastService: ToastService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private datePickerAdapter: DateAdapter<any>,
    private electronService: ElectronService,
    private preferenceService: PreferenceService,
    private broadcaster: MailBroadcaster,
    private configService: ConfigService,
    private router: Router,
    private datepipe: DatePipe,
    private hotKeyService: NgxHotkeysService,
    private convRepository: ConversationRepository,
    private databaseService: DatabaseService,
    private commonRepository: CommonRepository,
    public mailService: MailService,
    public sharedCalendarService: SharedCalendarService
  ) {
    console.log("[EditAppointmentDialogComponent]: ", data);
    this.configService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res) {
        if (res === "en") {
          this.currentLocale = "en";
        } else {
          this.currentLocale = "de";
        }
      } else {
        this.currentLocale = "en";
      }
      console.log("[CALENDAR CONFIGURE LANGUAGE]: ", this.currentLocale);
      this.changeDetector.markForCheck();
    });
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.setLocale();
    this.translateService.get("TO_MAIL_LABLE").pipe(take(1)).subscribe(res => {
      this.toLBL = res;
      this.changeDetector.markForCheck();
    });
    this.translateService.get("CALENDARS.ATTENDEES").pipe(take(1)).subscribe(res => {
      this.attendeeLbl = res;
      this.changeDetector.markForCheck();
    });
    this.translateService.get("CALENDARS.OPTIONAL_ATTENDESS").pipe(take(1)).subscribe(res => {
      this.optionalAttendeeslbl = res;
      this.changeDetector.markForCheck();
    });
    this.translateService.get("CALENDARS.EQUIPMENT_LBL").pipe(take(1)).subscribe(res => {
      this.equipmentlbl = res;
      this.changeDetector.markForCheck();
    });
    this.translateService.get("CALENDARS.SUGGEST_LOCATION").pipe(take(1)).subscribe(res => {
      this.locationPlaceholder = res;
      this.changeDetector.markForCheck();
    });
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
      let tempProfile = null;
      console.log("[getUserProfile] header", res);
      if (!!res) {
        tempProfile = res;
        tempProfile.email = this.getSingleEmail(res.email);
        this.currentUser = tempProfile;
      } else {
        const contactUser = this.electronService.isElectron
        ? this.electronService.getFromStorage("profileUser") : localStorage.getItem("profileUser");
        if (!!contactUser) {
          this.currentUser = MailUtils.parseUserProfile(contactUser);
        }
      }
    });
    this.calendarComposeView = calendarRepository.calendarComposeViewDefaultControl;
    this.editAppointmentModel = calendarRepository.appointmentInComposeModel;
    this.alaramList = this.calendarRepository.reminderOptions();
    this.appointment = this.data.appointment;
    if (this.appointment && this.appointment.xprop
      && this.appointment.xprop.find(v => v.name === "X-VNC-MEETING")) {
      this.meetingJid = this.appointment.xprop.find(v => v.name === "X-VNC-MEETING").value;
      this.isVNCTalkCall = true;
      this.urlControl.patchValue(this.appointment.loc);
    }
    this.isForwardAppointment = false;
        if (this.data.isForward) {
      this.isForwardAppointment = true;
    }

    this.isProposeNewTime = this.data?.isProposeNewTime;
    if (this.data.attachments && this.data.attachments.length > 0) {
      this.attachedFileList = MailUtils.getAttachments(this.data.attachments);
    }

    if (this.data.isInstance) {
      this.disableRepeat = true;
    }
    this.hideResourceCalendar = this.configService.hideResourceOptionCalendar;
    if (this.data.attendee) {
      setTimeout(() => {
        const attendee = this.data.attendee;
        attendee.map( item => {
          const emailInfo: EmailInformation = { a: item, t: "", d: item };
          try {
            this.attendeeAutoComplete.setEmailField(emailInfo);
          } catch (e) {
            console.log("attendeeAutoComplete.setEmailField error: ", e);
            setTimeout(() => {
              this.attendeeAutoComplete.setEmailField(emailInfo);
            }, 300);
          }
        });
      }, 100);
    }
    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.iszimbraFeatureGalEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_GAL_ENABLED);
    });
    if (this.data && this.data.messageItem) {
      this.messageItem = this.data.messageItem;
    }
    this.store.select(getOnlineStatus).subscribe(res => {
      this.isOnline = res;
    });

    if (this.data.instanceDragData) {
      setTimeout(() => {
        this.startDateFormControl.patchValue(new Date(this.data.instanceDragData.startDate));
        this.endDateFormControl.patchValue(new Date(this.data.instanceDragData.endDate));
      }, 1500);
    }
    this.changeDetector.markForCheck();
  }

  calendarTimeChangedOnDatePicker(ev: any): void {
    console.log("[calendarTimeChangedOnDatePicker]", ev);
    this.startDateFormControl.patchValue(ev.day.date);
    this.endDateFormControl.patchValue(ev.day.date);
    this.startTimeControl.setDate(ev.day.date.getDate());
    this.startTimeControl.setMonth(ev.day.date.getMonth());
    this.startTimeControl.setFullYear(ev.day.date.getFullYear());
    this.endTimeControl.setDate(ev.day.date.getDate());
    this.endTimeControl.setMonth(ev.day.date.getMonth());
    this.endTimeControl.setFullYear(ev.day.date.getFullYear());
    if (CalenderUtils.isPastDate(moment(this.startDateFormControl.value).toDate(), this.appointment.allDay)) {
      this.isPastDate = true;
    } else {
      this.isPastDate = false;
    }
    this.changeDetector.markForCheck();
    if (this.schedulerComponent) {
      this.schedulerComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
      setTimeout(() => {
        this.schedulerComponent.postUpdateHandler();
      }, 1000);
    }
  }

  showSuggestion() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.scheduleAssistantComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
    this.scheduleAssistantComponent._showTimeSuggestions();
  }

  ngOnInit() {
    this.organizerFromAddress = this.getFromAddress();
    this.browseFileds = [];
    const timeZoneInCalendar = this.configService.prefs.zimbraPrefUseTimeZoneListInCalendar;
    if (!!timeZoneInCalendar && timeZoneInCalendar !== null) {
     let timeZoneDetect = "";
     console.log("[zimbraPrefTimeZoneId]: ", this.configService.prefs.zimbraPrefTimeZoneId);
     if (!!this.configService.prefs.zimbraPrefTimeZoneId && this.configService.prefs.zimbraPrefTimeZoneId !== null
        && this.configService.prefs.zimbraPrefTimeZoneId !== ""
      ) {
        timeZoneDetect = this.configService.prefs.zimbraPrefTimeZoneId;
        console.log("[zimbraPrefTimeZoneId]: ", timeZoneDetect);
      } else {
          timeZoneDetect = jstimezonedetect.determine().name();
          console.log("[jstimezonedetect]: ", timeZoneDetect);
      }
      timeZoneDetect = timeZoneDetect.replace("Asia/Calcutta", "Asia/Kolkata");
      console.log("[DetectTimeZone]: ", timeZoneDetect);
      this.startTimeZone.key = timeZoneDetect;
      this.endTimeZone.key = timeZoneDetect;
      this.startTimeZone.value = this.getTimeZoneValue(this.startTimeZone.key);
      this.endTimeZone.value = this.getTimeZoneValue(this.endTimeZone.key);
      console.log("[startTimeZone]", this.startTimeZone);
      console.log("[endTimeZone]", this.endTimeZone);
      console.log("[timeZoneInCalendar]", timeZoneInCalendar);
      if (timeZoneInCalendar === "TRUE" && this.startTimeZone.value !== "") {
        this.isShowAppointmentTimeZone = true;
        console.log("[isShowAppointmentTimeZone]", this.isShowAppointmentTimeZone);
        this.changeDetector.markForCheck();
      }
    }
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null) {
        this.calendarFolders = res;
        this.allFolders = this.getCalendarFolders(this.calendarFolders).filter(f => f.view === "appointment");
        this.calendarFolders = this.calendarFolders.filter(f => f.id !== "3");
        this.calendarFolders.map( f => {
          if (f.perm && (f.perm === "r" || f.perm === "rp" )) {
            this.calendarFolders.splice(this.calendarFolders.indexOf(f), 1);
          }
          const childFolders = CalenderUtils.getChildFolders([f]).filter(f => f.view === "appointment");
          if (childFolders.length > 0) {
            childFolders.map( fp => {
              this.calendarFolders.push(fp);
              if (fp.perm && (fp.perm === "r" || fp.perm === "rp")) {
                this.calendarFolders.splice(this.calendarFolders.indexOf(fp), 1);
              }
            });
          }
        });
      }
    });
    this.breakpointObserver
      .observe(["(max-width: 763px)"]).pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         this.isMobileView = true;
        } else {
          this.isMobileView = false;
        }
      });
    this.displayOptions = this.calendarRepository.displayOptions();
    console.log("[editAppointment]: ", this.appointment);
    this.oldAppointment = _.clone(this.appointment, true);
    if (this.appointment.startDateData) {
      const startDate = this.appointment.startDateData[0].d;
      if (this.isShowAppointmentTimeZone && this.appointment.startDateData[0].tz) {
        this.startTimeZone.key =  this.appointment.startDateData[0].tz;
        this.startTimeZone.value = this.getTimeZoneValue(this.startTimeZone.key);
        this.changeDetector.markForCheck();
      }
      this.startDateFormControl = new FormControl(moment(startDate).toDate());
      this.startTimeControl = moment(startDate).toDate();
      this.calendarRepository.initCalendarComposeViewForTimePoint(moment(startDate).toDate());
    }
    if (this.appointment.endDateData) {
      const endDate = this.appointment.endDateData[0].d;
      this.endDateFormControl = new FormControl(moment(endDate).toDate());
      this.endTimeControl = moment(endDate).toDate();
      if (this.isShowAppointmentTimeZone && this.appointment.endDateData[0].tz) {
        this.endTimeZone.key =  this.appointment.endDateData[0].tz;
        this.endTimeZone.value = this.getTimeZoneValue(this.endTimeZone.key);
        this.changeDetector.markForCheck();
      }
    }
    if (this.appointment.or?.a) {
      console.log("setOrganizer: ", this.appointment.or.a);
      this.organizerFromAddress = this.appointment.or.a;
    }
    if (this.data.ridZ) {
      /* Set Date for the instance appointment */
      const startDate = moment(moment(this.appointment.startDateData[0].d).toDate());
      const endDate = moment(moment(this.appointment.endDateData[0].d).toDate());
      const diffDay = endDate.diff(startDate, "days");
      const diffHours = endDate.diff(startDate, "hours");

      const newStartDate = moment(this.data.ridZ).toDate();
      this.startDateFormControl = new FormControl(newStartDate);
      const newEndDate = moment(moment(this.data.ridZ).toDate()).add(diffDay, "days").add(diffHours, "hours").toDate();
      this.endDateFormControl = new FormControl(newEndDate);
    }
    if (this.appointment.at && this.appointment.at.length > 0) {
      setTimeout(() => {
        this.appointment.at.map(item => {
          const emailInfo: EmailInformation = { a: item.a, t: "", d: item.d };
          if (item.role === "REQ") {
            this.attendeeAutoComplete.setEmailField(emailInfo);
          } else if (item.role === "OPT") {
            this.optionalAutoComplete.setEmailField(emailInfo);
          } else if (item.role === "NON") {
            const info: any = { email: item.a, t: "", fullName: item.d || item.a };
            this.equipmentAutoComplete?.setEmailField(info);
          }
        });
      }, 100);
    }

    if (this.data.isNewAppointment) {
      this.appointment.recur = this.appointment.recur;
      this.recurringData = "";
    } else {
      if (this.appointment.recur) {
        this.recurringData = this.appointment.recur;
        this.appointment.recur = "CUSTOM";
        this.parseData(this.recurringData);
        this.getBlurb();
      } else {
        this.appointment.recur = "NON";
        this.recurringData = this.appointment.recur;
      }
    }
    if (this.data.isVNCTalkCall) {
      this.isVNCTalkCall = true;
    }
    if (!!this.data.vncTalkMeetingPass) {
      this.passwordControl.patchValue(this.data.vncTalkMeetingPass);
    }
    if (!!this.configService.prefs.zimbraPrefCalendarReminderEmail && this.configService.prefs.zimbraPrefCalendarReminderEmail !== "") {
      this.prefCalendarreminderEmail = this.configService.prefs.zimbraPrefCalendarReminderEmail;
      this.changeDetector.markForCheck();
    }
    if (this.data.isNewAppointment) {
      this.alarmReminderOption = this.appointment.alarmData;
      if (this.appointment.name !== "") {
        this.setAlarmToCompose();
      }
      if (!!this.data.isReminderEmailCheck) {
        if (this.data.isReminderEmailCheck === true) {
          this.isPrefCalendarReminderEmail = true;
        }
      }
    } else {
      this.setAlarmToCompose();
      if (!!this.data && !!this.data.appointment && this.data.appointment.alarmData) {
        const isAvailable = this.data.appointment.alarmData.filter(item => item.action === "EMAIL")[0];
        if (!!isAvailable && this.prefCalendarreminderEmail !== "") {
          this.isPrefCalendarReminderEmail = true;
          this.changeDetector.markForCheck();
        }
      }
    }
    if (this.appointment.mp) {
      this.attachedFileList = MailUtils.getAttachments(this.appointment.mp);
    }
    this.appointmentBodyData = this.getAppointmentBody(this.appointment);
    if (this.isForwardAppointment) {
      this.setForwardAppointmentBody();
    }
    this.dialogRef.backdropClick().pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.close();
    });
    this.dialogRef.keydownEvents().pipe(takeUntil(this.isAlive$)).subscribe( res => {
      if (res.key && res.key === "Escape") {
        this.close();
      }
    });
    if (this.isProposeNewTime) {
      this.setProposeNewTimeData();
    }
    this.setLocationToField();
    if (CalenderUtils.isPastDate(moment(this.startDateFormControl.value).toDate(), this.appointment.allDay)) {
      this.isPastDate = true;
    }
    if (this.data.openFromSidebar) {
      this.setPrivatePublicVisibility();
      const reminderWarningTimeFromPref = this.configService.prefs.zimbraPrefCalendarApptReminderWarningTime;
      if (!!reminderWarningTimeFromPref && reminderWarningTimeFromPref !== null) {
        this.setReminderTimeFromPreference(reminderWarningTimeFromPref);
      }
    }
    if (this.data && this.data.isMoreDetail) {
      this.isMoreDetail = true;
    }
    if (this.appointment.startDateData[0].tz === undefined && !this.appointment.allDay) {
      const date = moment(this.appointment.startDateData[0].d).toDate();
      const endDate = moment(this.appointment.endDateData[0].d).toDate();
      if (!this.isMoreDetail) {
        this.calculateDateTimeDefault(true, date, 30, endDate);
      }
    }
    this.broadcaster.on<any>(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.valueChange();
    });
    this.broadcaster.on<any>("AUTO_COMPLETE_REMOVE_ITEM").pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.valueChange();
    });
    this.ignoreList = [];
    setTimeout(() => {
      if (this.appointment.at && this.appointment.at.length > 0) {
        this.rsvpDisabled = false;
        const requestee = this.appointment.at.filter(item => item.rsvp && item.rsvp === true)[0];
        if (!!requestee) {
          this.isRsvp = true;
        } else {
          this.isRsvp = false;
        }
        this.changeDetector.markForCheck();
      }

      this.appointment.key = this.mailService.getFolderNameKey(this.getFolderNameById(this.appointment.ciFolder), CalendarConstants.SYSTEM_FOLDERS, true);
      this.appointment.folderColor = this.getFolderColorById(this.appointment.ciFolder);
    }, 1000);
    this.changeDetector.markForCheck();
  }

  ngAfterViewInit(): void {
    if (!this.initScheduler && this.schedulerComponent) {
      this.initScheduler = true;
      this.schedulerComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
      if (this.schedulerComponent.attendees.length === 0) {
        this.schedulerComponent.addAttendee(this.calendarRepository.userProfile.email, "PERSON");
      }
      setTimeout(() => {
        this.schedulerComponent.postUpdateHandler();
      }, 1000);
    }

    setTimeout(() => {
      const titleInputelement = <HTMLElement>document.querySelector(".title-field input");
      if (titleInputelement) {
        titleInputelement.focus();
      }
    }, 500);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  cancel(): void {
    this.close();
  }

  close(): void {
    if (this.data.isNewAppointment) {
      const inComposeView = this.getInComposeViewData();
      this.calendarRepository.genNewAppoinmententAndUpdateChangeInCalendar(inComposeView, false);
    }
    if (this.isReminderConfigClick) {
      this.routeToPreferenceReminder();
    }
    this.dialogRef.close();
  }

  handleStartDateChanges(matEvent: MatDatepickerInputEvent<Date>): void {
    this.onDateTimeControlUpdated(matEvent.value);
    this.valueChange();
  }

  handleEndDateChanges(matEvent: MatDatepickerInputEvent<Date>): void {
    this.onDateTimeControlUpdated(matEvent.value, false);
  }

  handleStartTimeChanges(date: Date): void {
    this.validStartTime = true;
    const sFormControl = moment(this.startDateFormControl.value);
    const sTimeControl = moment(date);
    sTimeControl.set("month", sFormControl.month());
    sTimeControl.set("year", sFormControl.year());
    sTimeControl.set("date", sFormControl.date());
    this.startTimeControl = sTimeControl.toDate();
    date = this.startTimeControl;
    this.onDateTimeControlUpdated(date, true, false);
    this.isAppointmentChange = true;
    this.changeDetector.markForCheck();
  }

  handleEndTimeChanges(date: Date): void {
    this.validEndTime = true;
    const eFormControl = moment(this.endDateFormControl.value);
    const eTimeControl = moment(date);
    eTimeControl.set("month", eFormControl.month());
    eTimeControl.set("year", eFormControl.year());
    eTimeControl.set("date", eFormControl.date());
    this.endTimeControl = eTimeControl.toDate();
    date = this.endTimeControl;
    this.valueChange();
    this.onDateTimeControlUpdated(date, false, false);
  }

  handleCalendarRepeatChanges(repeatOption: KeyValue): void {
    this.appointment.recur = repeatOption.value.toString();
    this.valueChange();
    this.blurb = "";
    this.changeDetector.markForCheck();
  }

  private onDateTimeControlUpdated(newDate: Date, isStart: boolean = true, isDateValue: boolean = true): void {
    const durationPref = this.getPrefDuration();
    if (durationPref !== "" && durationPref !== "0") {
      this.editAppointmentModel.calendarTimeSpanDuration = +durationPref;
    }
    if (this.data.isCopyAppointment) {
      const start = moment(this.appointment.startDateData[0].d);
      const end = moment(this.appointment.endDateData[0].d);
      const duration = moment.duration(end.diff(start));
      const milliseconds = duration.asMilliseconds();
      const minutes = Math.floor(milliseconds / 60000);
      this.editAppointmentModel.calendarTimeSpanDuration = minutes;
      console.log("[Duration] [Minutes]", minutes);
    }
    if (isDateValue) {
      const currentSelectedHours = this.startTimeControl.getHours();
      const currentSelectedMinutes = this.startTimeControl.getMinutes();

      if (isStart) {
        this.startTimeControl = new Date(newDate);
        this.startTimeControl.setHours(currentSelectedHours, currentSelectedMinutes, 0, 0);
        if (moment(this.startDateFormControl.value).isAfter(this.endDateFormControl.value)) {
          // if (this.data.isNewAppointment) {
          //   this.endTimeControl = this.dateAdapter
          //   .addMinutes(new Date(this.startTimeControl), this.editAppointmentModel.calendarTimeSpanDuration);
          // } else {
            const _minutes = new Date(this.endTimeControl).getMinutes();
            const _hours = new Date(this.endTimeControl).getHours();
            const dateItem = moment(this.startTimeControl);
            dateItem.set({hour: _hours, minute: _minutes, second: 0, millisecond: 0});
            this.endTimeControl = dateItem.toDate();
         // }
          this.endDateFormControl = new FormControl(new Date(this.endTimeControl));
        }
        if (moment(this.startDateFormControl.value).isBefore(this.endDateFormControl.value)) {
          // if (this.data.isNewAppointment) {
          //   this.endTimeControl = this.dateAdapter
          //   .addMinutes(new Date(this.startTimeControl), this.editAppointmentModel.calendarTimeSpanDuration);
          // } else {
            const _minutes = new Date(this.endTimeControl).getMinutes();
            const _hours = new Date(this.endTimeControl).getHours();
            const dateItem = moment(this.startTimeControl);
            dateItem.set({hour: _hours, minute: _minutes, second: 0, millisecond: 0});
            this.endTimeControl = dateItem.toDate();
         // }
          this.endDateFormControl = new FormControl(new Date(this.endTimeControl));
          this.calendarRepository.updateTimePointOptions(newDate, false);
        }
        this.startDateFormControl = new FormControl(new Date(this.startTimeControl));
        this.endDateFormControl = new FormControl(this.endTimeControl);
      } else {
        if (moment(this.endDateFormControl.value).isBefore(this.startDateFormControl.value)) {
            const _minutes = new Date(this.endTimeControl).getMinutes();
            const _hours = new Date(this.endTimeControl).getHours();
            const dateItem = moment(newDate);
            dateItem.set({hour: _hours, minute: _minutes, second: 0, millisecond: 0});
            this.endTimeControl = dateItem.toDate();

            this.startTimeControl.setMonth(this.endTimeControl.getMonth());
            this.startTimeControl.setDate(this.endTimeControl.getDate());
            this.startTimeControl.setFullYear(this.endTimeControl.getFullYear());
            this.startDateFormControl = new FormControl(this.startTimeControl);
        } else {
            const _minutes = new Date(this.endTimeControl).getMinutes();
            const _hours = new Date(this.endTimeControl).getHours();
            const dateItem = moment(newDate);
            dateItem.set({hour: _hours, minute: _minutes, second: 0, millisecond: 0});
            this.endTimeControl = dateItem.toDate();
        }
      }
      this.startDateFormControl = new FormControl(new Date(this.startTimeControl));
      this.endDateFormControl = new FormControl(this.endTimeControl);
    } else {
      const timeDiffInMinutes = this.dateAdapter.differenceInMinutes(this.endTimeControl, this.startTimeControl);
      if (isStart) {
        if (timeDiffInMinutes <= this.editAppointmentModel.calendarTimeSpanDuration) {
          this.endTimeControl = this.dateAdapter
            .addMinutes(new Date(this.startTimeControl), this.editAppointmentModel.calendarTimeSpanDuration);
          this.endDateFormControl = new FormControl(new Date(this.endTimeControl));
        }
        this.startDateFormControl = new FormControl(new Date(this.startTimeControl));
      } else {
        if (timeDiffInMinutes <= 0) {
          this.startTimeControl = this.dateAdapter
            .addMinutes(new Date(this.endTimeControl), -1 * this.editAppointmentModel.calendarTimeSpanDuration);
          this.startDateFormControl = new FormControl(new Date(this.startTimeControl));
        } else {
          if (timeDiffInMinutes !== this.editAppointmentModel.calendarTimeSpanDuration) {
            this.editAppointmentModel.calendarTimeSpanDuration = timeDiffInMinutes;
          }
        }
        this.endDateFormControl = new FormControl(this.endTimeControl);
      }
    }
    this.editAppointmentModel.startDate = new Date(this.startTimeControl);
    this.editAppointmentModel.endDate = new Date(this.endTimeControl);
    this.isPastDate = false;
    if (CalenderUtils.isPastDate(this.editAppointmentModel.startDate, this.appointment.allDay)) {
      this.isPastDate = true;
    }

    if (this.schedulerComponent) {
      this.schedulerComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
    }
    if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
      this.scheduleAssistantComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
      this.scheduleAssistantComponent._showTimeSuggestions();
    }
    this.changeDetector.markForCheck();
  }

  getTimeList(): any[] {
    const timeList = [];
    timeList.push("12:00 AM");
    let value;
    for (const j of [3, 6, 9]) {
      value = "12:" + j * 5 + " AM";
      timeList.push(value);
    }
    for (let i = 1; i < 12; i++) {
      value = i + ":00 AM";
      timeList.push(value);
      for (const j of [3, 6, 9]) {
        value = i + ":" + j * 5 + " AM";
        timeList.push(value);
      }
    }
    timeList.push("12:00 PM");
    for (const j of [3, 6, 9]) {
      value = "12:" + j * 5 + " PM";
      timeList.push(value);
    }
    for (let i = 1; i < 12; i++) {
      value = i + ":00 PM";
      timeList.push(value);
      for (const j of [3, 6, 9]) {
        value = i + ":" + j * 5 + " PM";
        timeList.push(value);
      }
    }
    return timeList;
  }

  getFreeBusyOptions(): KeyValue[] {
    return this.calendarRepository.displayOptions();
  }

  handleFreeBusyChangesChanges(option: KeyValue): void {
    this.appointment.fb = option.value.toString();
    this.valueChange();
    this.changeDetector.markForCheck();
  }

  getFolderNameById(folderId: string): string {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let folderName: string = "";
    if (!!folderId) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        folderName = f.name;
                    }
                } else {
                    if (f.id === folderId) {
                        folderName = f.name;
                    }
                }
            } else {
                if (f.id === folderId) {
                    folderName = f.name;
                }
            }
        });
    }
    return folderName;
 }

  getFolderColorById(folderId: string): string {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let folderColor: string = "#000099";
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        folderColor = f.folderColor;
                    }
                } else {
                    if (f.id === folderId) {
                        folderColor = f.folderColor;
                    }
                }
            } else {
                if (f.id === folderId) {
                    folderColor = f.folderColor;
                }
            }
        });
    }
    return folderColor;
 }

  changeFolder(folder: CalendarFolder): void {
    console.log("changeFolder: ", folder, this.appointment);
    if (!!this.appointment.at && (this.appointment.at.length > 0)) {
      if (!!folder.owner && !!this.appointment.or && !!this.appointment.or.a && (this.appointment.or.a !== folder.owner)) {
        this.toastService.show("CALENDARS.CANNOT_MOVE_DIFF_ORGANIZER_OWNER");
        return;
      }
    }
    this.maybeSetOrganizerFrom(folder.owner);
    this.appointment.ciFolder = folder.id;
    this.appointment.key = this.mailService.getFolderNameKey(this.getFolderNameById(this.appointment.ciFolder), CalendarConstants.SYSTEM_FOLDERS, true);
    this.appointment.folderColor = this.getFolderColorById(this.appointment.ciFolder);
    this.valueChange();
  }

  changeClass(ev): void {
    if (ev.checked) {
      this.appointment.class = "PRI";
    } else {
      this.appointment.class = "PUB";
    }
    this.valueChange();
    this.changeDetector.markForCheck();
  }

  changeAllDay(ev): void {
    this.appointment.allDay = ev.checked;
    this.valueChange();
    if (this.schedulerComponent) {
      this.schedulerComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
    }
    this.changeDetector.markForCheck();
  }

  getSingleEmail(emails) {
    if (isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }


  saveAppointment(): void {
    this.isAppointmentChange = false;
    console.log("SAVEAPPOINTMENT", this.appointment);
    if (this.data.instanceDragData) {
      this.changeinstanceWithDrag(this.data.instanceDragData.value, this.data.instanceDragData.event, this.data.instanceDragData.startDate, this.data.instanceDragData.endDate);
      return;
    }
    this.changeDetector.markForCheck();
    if (this.selectedUploadFiles.length > 0) {
      const fileUploads: any = [];
      if (this.selectedUploadFiles.length > 0) {
        for (let i = 0; i < this.selectedUploadFiles.length; i++) {
          fileUploads.push(this.commonService.uploadAttachment(this.selectedUploadFiles[i]));
        }
        forkJoin(fileUploads).subscribe(
          (response: any) => {
            const attachmentIds: any[] = [];
            response.forEach(attachmentInfo => {
              const json = JSON.parse(attachmentInfo.toString().replace("200,\'null\',", ""));
              attachmentIds.push(json[0].aid);
            });
            if (this.isEquipmentDataAvailable()) {
              this.checkRecurConflict(false, attachmentIds);
            } else if (this.isLocationDataAvailable()) {
              this.checkLocationRecurConflict(false, attachmentIds);
            } else {
              this.saveAppointmentRequest(attachmentIds);
            }
          },
          error => {
            this.toastService.showPlainMessage(error);
          }
        );
      }
    } else {
      if (this.isEquipmentDataAvailable()) {
        this.checkRecurConflict();
      } else if (this.isLocationDataAvailable()) {
        this.checkLocationRecurConflict();
      } else {
        this.saveAppointmentRequest();
      }
    }
  }

  sendAppointment(): void {
    console.log("sendAppointment: ", this.data, this.sendButtonLocked, this.organizerFromAddress);
    if (this.sendButtonLocked) {
      return;
    }
    this.sendButtonLocked = true;

    if (this.data.instanceDragData) {
      this.changeinstanceWithDrag(this.data.instanceDragData.value, this.data.instanceDragData.event, this.data.instanceDragData.startDate, this.data.instanceDragData.endDate);
      return;
    }
    if (this.selectedUploadFiles.length > 0) {
      const fileUploads: any = [];
      if (this.selectedUploadFiles.length > 0) {
        for (let i = 0; i < this.selectedUploadFiles.length; i++) {
          fileUploads.push(this.commonService.uploadAttachment(this.selectedUploadFiles[i]));
        }
        forkJoin(fileUploads).subscribe(
          (response: any) => {
            const attachmentIds: any[] = [];
            response.forEach(attachmentInfo => {
              const json = JSON.parse(attachmentInfo.toString().replace("200,\'null\',", ""));
              attachmentIds.push(json[0].aid);
            });
            if (this.isEquipmentDataAvailable()) {
              this.checkRecurConflict(true, attachmentIds);
            } else if (this.isLocationDataAvailable()) {
              this.checkLocationRecurConflict(false, attachmentIds);
            } else {
              this.sendAppointmentRequest(attachmentIds);
            }
          },
          error => {
            console.log("sendAppointment error: ", error);
            this.toastService.showPlainMessage(error);
            this.sendButtonLocked = false;
          }
        );
      }
    } else {
      if (this.isEquipmentDataAvailable()) {
        this.checkRecurConflict(true);
      } else if (this.isLocationDataAvailable()) {
        this.checkLocationRecurConflict(true);
      } else {
        if (this.isConflicted || this.locationConflict) {
          const dialog = this.dialog.open(ConflictEquipmentDialogComponent, {
            maxWidth: "95%",
            autoFocus: false,
            panelClass: "conflict-equipment-dialog",
            id: "conflict-equipment-dialog",
            data: { startTime: this.startTimeControl, endTime: this.endTimeControl, conflictEmails: this.conflictEmails, selectedAttendees: this.attendeeAutoComplete.getSelectedEmail() }
          });
          dialog.afterClosed().pipe(take(1)).subscribe( resp => {
            this.sendButtonLocked = false;
            if (!!resp && resp.value) {
              const value = resp.value;
              if (resp.value === "yes") {
                this.sendAppointmentRequest();
              }
            }
          });
        } else {
          this.sendAppointmentRequest();
        }
      }
    }
  }
  getFromAddress() {
    let emailFromAddress = this.currentUser.email;
    try {
      const rawPrefs = localStorage.getItem("preferences");
      const prefs = JSON.parse(rawPrefs);
      console.log("getFromAddress prefs: ", prefs);
      if (!!prefs && !!prefs.zimbraPrefFromAddress) {
        emailFromAddress = prefs.zimbraPrefFromAddress;
      }
    } catch (error) {
      console.error("getFromAddress error: ", error);
    }
    return emailFromAddress;
  }

  getSaveSendBody(): any {
    this.isRequestResponsesEnabled();
    let emailFromAddress = this.getFromAddress();
    const requestee = [
      ...this.getAppointmentRequestee(),
      ...this.getAppointmentOptionalRequestee(),
      ...this.getAppointmentEquipmentRequestee(),
      ...this.getAppointmentLocationRequestee()
    ];
    let startTimeStamp: string;
    let endTimeStamp: string;
    if (this.appointment.allDay) {
      startTimeStamp = moment(this.startDateFormControl.value).format("YYYYMMDD");
      endTimeStamp = moment(this.endDateFormControl.value).format("YYYYMMDD");
    } else {
      const selectedStartTime =  new Date(this.startDateFormControl.value);
      const selectedEndTime = new Date(this.endDateFormControl.value);
      startTimeStamp = moment(selectedStartTime).format("YYYYMMDDTHHmmss");
      endTimeStamp = moment(selectedEndTime).format("YYYYMMDDTHHmmss");
    }
    const timezone = jstimezonedetect.determine().name();
    const body: any = {
      id: this.appointment.id,
      subject: this.appointment.name,
      uid: this.appointment.uid,
      emailInfo: this.getEmailInformation(),
      folderId: this.appointment.ciFolder,
      comp: "0",
      ms: this.appointment.ms,
      rev: this.appointment.rev,
      mp: [{
        ct: "text/html",
        content: this.appointmentBodyData
      }],
      inviteInfo: [{
        allDay: this.appointment.allDay ? "1" : "0",
        at: requestee,
        class: this.appointment.class,
        e: {
          tz: this.isShowAppointmentTimeZone ? this.endTimeZone.key : timezone,
          d: endTimeStamp
        },
        s: {
          tz: this.isShowAppointmentTimeZone ? this.startTimeZone.key : timezone,
          d: startTimeStamp
        },
        or: {
          a: emailFromAddress,
        },
        loc: this.getLocationString(),
        status: "CONF",
        transp: this.appointment.transp,
        fb: this.appointment.fb,
        name: this.appointment.name
      }]
    };
    if (this.appointment.recur !== "NONE" && !this.data.disableRepeat) {
      const recurrence = this.getRecurrence();
      body.inviteInfo[0].recur = recurrence;
    }
    // if (this.data.seriesCopy) {
    //   body.inviteInfo[0].recur = this.appointment.recur;
    // }
    const index = this.alaramList.indexOf(this.alarmReminderOption);
    if (index !== 0) {
      body.inviteInfo[0].alarm = [{ action: "DISPLAY", trigger: { rel: { m: this.reminderUnit[index], related: "START", neg: "1" } } }];
      if (this.isPrefCalendarReminderEmail) {
        body.inviteInfo[0].alarm.push({
          action: "EMAIL",
          trigger: {
            rel: {
              m: this.reminderUnit[index],
              related: "START",
              neg: "1"
            }
          },
          "at": {
            "a": this.prefCalendarreminderEmail
          }
        });
      }
    }
    const folder = this.getFolderById(this.appointment.ciFolder);
    const oldFolder = this.getFolderById(this.oldAppointment.ciFolder);
    if (!!this.oldAppointment && !!this.oldAppointment.or && !!this.oldAppointment.or.a) {
      body.inviteInfo[0].or = {
        a: this.oldAppointment.or.a
      };
    }
    console.log("SAVEAPPOINTMENT getSaveSendBody folder, oldFolder: ", folder, oldFolder, this.data.isNewAppointment);
    if (!this.data.isNewAppointment) {
      if (folder.id !== oldFolder.id) {
        this.postEditMoveToFolderId = folder.id;
        body.folderId = oldFolder.id;
      }
    } else {
      if (folder.perm) {
        const fromA = this.getFromAddress();

        // body.inviteInfo[0].or.a = (fromA !== orA) ? orA : folder.owner;
        body.inviteInfo[0].or.a = this.organizerFromAddress;
        body.inviteInfo[0].or.sentBy = fromA;
      }
    }
    if (oldFolder.perm) {
      const rootFolder = this.calendarRepository.getRootFolder(folder);
      body.inviteInfo[0].or.sentBy = this.getFromAddress();
    }
    console.log("SAVEAPPOINTMENT getSaveSendBody: ", body);
    return body;
  }

  getEmailInformation(): any {
    const emailInfo: any[] = [];
    const folder = this.getFolderById(this.appointment.ciFolder);
    // const folder = this.getFolderById(this.appointment.l);
    console.log("getEmailInformation old, new: ", this.oldAppointment, this.appointment);
    let emailFromAddress = this.getFromAddress();
    if (!this.appointment.at) {
      emailInfo.push({
        a: emailFromAddress,
        t: "f"
      });

      // return emailInfo;
    }
    if (folder.perm) {
      const rootFolder = this.calendarRepository.getRootFolder(folder);
      if (folder.owner) {
        emailInfo.push({
          a: rootFolder.owner,
          t: "f"
        }, {
          a: emailFromAddress,
          t: "s"
        });
      } else {
        emailInfo.push({
          a: emailFromAddress,
          t: "f"
        }, {
          a: emailFromAddress,
          t: "s"
        });
      }
    } else {
      emailInfo.push({ a: emailFromAddress, t: "f" });
    }
    return emailInfo;
  }

  getAppointmentRequestee(): any[] {
    const requestee: any[] = [];
    const emails = this.attendeeAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map((e: any) => {
      requestee.push({
        a: e.email,
        d: e.name,
        ptst: "NE",
        role: "REQ",
        rsvp: this.isRsvp ? "1" : "0"
      });
    });
    return requestee;
  }

  getAppointmentOptionalRequestee(): any[] {
    const requestee: any[] = [];
    const emails = this.optionalAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map((e: any) => {
      requestee.push({
        a: e.email,
        d: e.name,
        ptst: "NE",
        role: "OPT",
        rsvp: this.isRsvp ? "1" : "0"
      });
    });
    return requestee;
  }

  printAppointment(): void {
    this.calendarRepository.printAppointment(this.appointment.id);
  }

  getRecurrence(): any {
    let recurrence: any[] = [];
    const recur = this.appointment.recur;
    switch (recur) {
      case "DAI":
        recurrence = [{
          "add": [
            {
              "rule": [
                {
                  "freq": "DAI",
                  "interval": [
                    {
                      "ival": 1
                    }
                  ]
                }
              ]
            }
          ]
        }
        ];
        break;
      case "WEE":
        recurrence = [
          {
            "add": [
              {
                "rule": [
                  {
                    "freq": "WEE",
                    "interval": [
                      {
                        "ival": 1
                      }
                    ],
                    "byday": [
                      {
                        "wkday": [
                          {
                            "day": moment(this.startDateFormControl.value).format("dd").toUpperCase()
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ];
        break;
      case "MON":
        recurrence = [
          {
            "add": [
              {
                "rule": [
                  {
                    "freq": "MON",
                    "interval": [
                      {
                        "ival": 1
                      }
                    ],
                    "bymonthday": [
                      {
                        "modaylist": moment(this.startDateFormControl.value).format("M").toString()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ];
        break;
      case "YEA":
        recurrence = [
          {
            "add": [
              {
                "rule": [
                  {
                    "freq": "YEA",
                    "interval": [
                      {
                        "ival": 1
                      }
                    ],
                    "bymonthday": [
                      {
                        "modaylist": moment(this.startDateFormControl.value).format("D").toString()
                      }
                    ],
                    "bymonth": [
                      {
                        "molist": moment(this.startDateFormControl.value).format("M").toString()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ];
        break;
        case "CUSTOM":
          recurrence = [
            this.recurringData
          ];
          break;
    }
    return recurrence;
  }

  setAlarmToCompose(): void {
    const alaramOptions = this.alaramList;
    this.alarmReminder = "0";
    if (this.appointment.alarmData) {
      this.alarmReminder = this.getAlarmUnit(this.appointment.alarmData[0]);
    }
    this.alarmReminder = !!this.alarmReminder ? this.alarmReminder : "-1";
    this.alarmReminder = this.alarmReminder.toString();
    this.alarmReminderOption = this.sharedCalendarService.switchAlarm(this.alarmReminder, alaramOptions, this.alarmReminderOption);
  }

  getAlarmUnit(tmp): string {
    if (!tmp) { return; }
    let m, h, d, w;
    const trigger = tmp.trigger;
    let _reminderMinutes;
    const rel = (trigger && (trigger.length > 0)) ? trigger[0].rel : null;
    m = (rel && (rel.length > 0)) ? rel[0].m : null;
    d = (rel && (rel.length > 0)) ? rel[0].d : null;
    h = (rel && (rel.length > 0)) ? rel[0].h : null;
    w = (rel && (rel.length > 0)) ? rel[0].w : null;
    _reminderMinutes = 0;
    if (tmp.action === "DISPLAY") {
      if (m !== null) {
        _reminderMinutes = m;
      }
      if (h !== null && !!h) {
        h = parseInt(h, 10);
        _reminderMinutes = h * 60;
      }
      if (d !== null && !!d) {
        d = parseInt(d, 10);
        _reminderMinutes = d * 24 * 60;
      }
      if (w !== null && !!w) {
        w = parseInt(w, 10);
        _reminderMinutes = w * 7 * 24 * 60;
      }
    }
    return _reminderMinutes;
  }

  handleSetAlarmChanges(option: KeyValue): void {
    this.alarmReminderOption = option;
    this.valueChange();
  }

  removeAttachment(index): void {
    this.attachedFileList.splice(index, 1);
  }

  downloadAttachment(file: any) {
    this.calendarRepository.openAttachment(this.appointment.id, file.part);
  }

  trackByIndex(index: number, value: any) {
    return index;
  }

  uploadAttachment(files: any) {
    this.selectedUploadFiles = files;
  }

  saveAppointmentRequest(attachIds?: any): void {
    console.log("saveAppointmentRequest");
    if (!this.isValidDuration()) {
      this.toastService.show("CALENDARS.TIME_ZONE_APPOINTMENT_CREATE_ERROR");
      return;
    }
    if (this.appointment.name === "") {
      this.toastService.show(CalendarConstants.SUBJECT_REQUIRE_MSG);
      return;
    }
    if (this.isSaveSendClick) {
      return;
    }
    this.isSaveSendClick = true;
    this.changeDetector.markForCheck();
    let body = this.getSaveSendBody();
    const attach: any = {};
    if (attachIds) {
      attach.aid = attachIds.toString();
    }
    if (this.attachedFileList.length > 0) {
      attach.mp = this.getAttachmentMultiPartUpload();
    }
    body.attach = attach;
    if (this.data.isNewAppointment) {
      body = _.pickBy(body, _.identity);
      console.log("body appointment", body);
      body.inviteInfo[0].draft = 1;
      if (this.isOnline) {
        this.commonService.createNewAppointment(body).pipe(take(1)).subscribe(res => {
          const inComposeView = this.getInComposeViewData();
          this.calendarRepository.getAllAppointments();
          this.dialogRef.close();
          if (res && res.echo) {
            if (res?.echo[0]?.m[0]?.inv[0]?.comp[0]?.neverSent) {
              this.toastService.show("CALENDARS.NEVER_SENT_INVITATION");
            } else {
              this.toastService.show("CALENDARS.APPOINTMENT_CREATE_MSG");
            }
          }
          this.clearUploadArray();
        }, err => {
          this.toastService.showPlainMessage(err);
          this.isSaveSendClick = false;
          this.changeDetector.markForCheck();
        });
      } else {
          body.inviteInfo[0].draft = 1;
          let requestBody = { ...body };
          if (this.commonService.isFakeId(requestBody.id)) {
            delete requestBody.id;
          }
          if (this.commonService.isFakeId(requestBody.did)) {
            delete requestBody.did;
          }
          // requestBody.eventId = this.appointment.eventId;
          const request = {
            "url": "/api/createAppointment",
            "method": "post",
            "body":  requestBody
          };

          // fake id
          body.id = this.commonService.createFakeId(body?.mp[0].content);
          this.databaseService.addPendingOperation(body.id, "createAppointment", request).subscribe(res => {
            const existingNewAppointment = this.calendarRepository.createLocalAppointment(body);
            console.log("EDITAPPOINTMENT save ", request, existingNewAppointment);
            this.saveAppointmentRequestApplyToDB(existingNewAppointment).subscribe(() => {

              this.calendarRepository.getAllAppointments();
              this.dialogRef.close();
            });
        });
      }
    } else {
      const newAttendee = this.getNewAttendee(this.oldAppointment, body.inviteInfo[0].at);
      if (this.isAppointmentSignificantChange()) {
        const dialog = this.dialog.open(SaveConfirmationDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "save-appointment-confirm-dialog",
          id: "save-appointment-confirm-dialog",
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res) {
            this.changeNotifyAll(res, body, newAttendee);
          }
        });
        return;
      }
        if (newAttendee.length > 0 && !this.appointment.neverSent) {
          const dialog = this.dialog.open(SaveConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "save-appointment-confirm-dialog",
            id: "save-appointment-confirm-dialog",
          });
          dialog.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res) {
              this.confirmationOpration(res, body, newAttendee);
            }
          });
          return;
        }
      if (!this.data.isInstance) {
        if (this.isOnline) {
          body.inviteInfo[0].draft = 1;
          this.commonService.modifyAppointment(body).pipe(take(1)).subscribe(async res => {
            console.log("SAVEAPPOINTMENT modifyRes: ", res, this.postEditMoveToFolderId);
            if (this.postEditMoveToFolderId !== "") {
              const opBody = { op: "move", id: res.apptId, l: this.postEditMoveToFolderId };
              let moved = await this.moveAppointmentOp(opBody);
              console.log("SAVEAPPOINTMENT moved: ", moved);
              this.postEditMoveToFolderId = "";
            }
            if (this.calendarRepository.selectingCalendarView !== "list") {
              const selectedStartTime = !!this.editAppointmentModel.startDate ?
              this.editAppointmentModel.startDate : new Date(this.startDateFormControl.value);
              this.calendarRepository.getAllAppointments();
            }
            if (res && res.echo) {
              if (res?.echo[0]?.m[0]?.inv[0]?.comp[0]?.neverSent) {
                this.toastService.show("CALENDARS.NEVER_SENT_INVITATION");
              } else {
                this.toastService.show("CALENDARS.APPOINTMENT_SAVED_MSG");
              }
            }
            if (res.echo[0].m[0].mp) {
              this.attachedFileList = MailUtils.getAttachments(res.echo[0].m[0].mp);
              this.selectedUploadFiles = [];
              this.clearUploadArray();
            }
            this.broadcaster.broadcast("UPDATE_LIST_VIEW");
            if (this.data && this.data.isFromMail) {
              this.close();
              this.router.navigate(["/mail/inbox"]);
              setTimeout(() => {
                this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
                this.broadcaster.broadcast("MAIL_ACTION_FROM_APPOINTMENT", {
                  msgId: this.data.msgId
                });
              }, 1000);
            }
            this.dialogRef.close();
            // this.close();
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.changeDetector.markForCheck();
          });
        } else {
            let requestBody = { ...body };
            let url = "/api/modifyAppointment";
                  let op = "modifyAppointment";
                  if (this.commonService.isFakeId(requestBody.id)) {
                    delete requestBody.id;
                    url = "/api/createAppointment";
                    op = "createAppointment";
                  }
            if (this.commonService.isFakeId(requestBody.did)) {
              delete requestBody.did;
            }
            const request = {
              "url": url,
              "method": "post",
              "body":  requestBody
            };

            // fake id
            if (!body.id) {
              body.id = this.commonService.createFakeId(body?.subject);
            }

            body.eventId = this.appointment.eventId;
            this.databaseService.addPendingOperation(body.id, op, request).subscribe(res => {

              const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
              this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                const updatedAppointment = {...resp, ...existingNewEvent};
                this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                  this.calendarRepository.getAllAppointments();
                  this.dialogRef.close();
                });
              });
          });
        }
      } else {
        const oldDateTime = moment(this.appointment.startDateData[0].d).format("HHmmss");
        const selectedStartTime =  new Date(this.startDateFormControl.value);
        const selectDate = moment(selectedStartTime).format("YYYYMMDD");
        body.inviteInfo[0].exceptId = {
          d: selectDate + "T" + oldDateTime,
          tz: this.appointment.startDateData[0].tz
        };
        body.inviteInfo[0].draft = 0;
        if (this.isOnline) {
          this.commonService.createAppointmentException(body).pipe(take(1)).subscribe(res => {
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.close();
            if (res && res.echo) {
              if (res?.echo[0]?.m[0]?.inv[0]?.comp[0]?.neverSent) {
                this.toastService.show("CALENDARS.NEVER_SENT_INVITATION");
              } else {
                this.toastService.show("CALENDARS.APPOINTMENT_SAVED_MSG");
              }
            }
            this.broadcaster.broadcast("UPDATE_LIST_VIEW");
            this.clearUploadArray();
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.changeDetector.markForCheck();
          });
        } else {
            let requestBody = { ...body };
            if (this.commonService.isFakeId(requestBody.id)) {
              delete requestBody.id;
            }
            if (this.commonService.isFakeId(requestBody.did)) {
              delete requestBody.did;
            }
            const request = {
              "url": "/api/createAppointmentException",
              "method": "post",
              "body":  requestBody
            };

            // fake id
            if (!body.id) {
              body.id = this.commonService.createFakeId(body?.mp[0].content);
            }
            this.databaseService.addPendingOperation(body.id, "createAppointmentException", request).subscribe(res => {
              const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
              this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                const updatedAppointment = {...resp, ...existingNewEvent};
              this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                if (this.calendarRepository.selectingCalendarView !== "list") {
                  this.calendarRepository.getAllAppointments();
                }
                this.close();
                this.toastService.show("CALENDARS.APPOINTMENT_SAVED_MSG");
                this.broadcaster.broadcast("UPDATE_LIST_VIEW");
                this.clearUploadArray();
              });
            });
          });
        }

      }
    }
  }

  createOrUpdateMeeting() {
    if (this.appointment.name === "") {
      this.toastService.show(CalendarConstants.SUBJECT_REQUIRE_MSG);
      return;
    }
    if (this.isSaveSendClick) {
      return;
    }
    const attendees = [
      ...this.getAppointmentRequestee(),
      ...this.getAppointmentOptionalRequestee(),
      ...this.getAppointmentEquipmentRequestee(),
      ...this.getAppointmentLocationRequestee()
    ];
    let startTimeStamp: string;
    let endTimeStamp: string;
    const dateFormat = "YYYY-MM-DD-THH:mm:ss.000Z";
    if (this.appointment.allDay) {
      startTimeStamp = moment(this.startDateFormControl.value).format("YYYY-MM-DD-T00:00:00.000Z");
      endTimeStamp = moment(this.endDateFormControl.value).format("YYYY-MM-DD-T23:59:59.000Z");
    } else {
      const selectedStartTime =  new Date(this.startDateFormControl.value);
      const selectedEndTime = new Date(this.endDateFormControl.value);
//      startTimeStamp = moment(selectedStartTime).format(dateFormat);
//      endTimeStamp = moment(selectedEndTime).format(dateFormat);
      startTimeStamp = selectedStartTime.toISOString();
      endTimeStamp = selectedEndTime.toISOString();

    }
    let vnctalkPayload: any = {
      "name": this.appointment.name,
      "invid": this.appointment.id,
      "rev": this.appointment.rev,
      "ms": this.appointment.ms,
      "start": startTimeStamp,
      "end": endTimeStamp,
      "invitees": attendees.map(v => v.a),
      "password": this.passwordControl.value,
      "description": CommonUtils.processHTMLBody(this.appointmentBodyData)
    };
    if (this.meetingJid) {
      vnctalkPayload.jid = this.meetingJid;
      this.calendarRepository.updateScheduledMeeting(vnctalkPayload).subscribe(() => {
          this.toastService.show("CALENDARS.APPOINTMENT_SAVED_MSG");
        });
    } else {
      this.calendarRepository.createNewMeeting(vnctalkPayload).subscribe(v => {
        console.log("createNewMeeting", v);
        this.toastService.show("CALENDARS.APPOINTMENT_CREATE_MSG");
      });
    }
    this.dialogRef.close();
  }

  private saveAppointmentRequestApplyToDB(appointment): Observable<any> {
    const response = new Subject<any>();
    // appointment.l = "6"; // draft
    if (!!appointment.start) {
      if (appointment.allDay) {
        appointment.startDateData = [{
          d: moment(appointment.start).format("YYYYMMDD"),
          u: appointment.start.valueOf()
        }];

      } else {
        appointment.startDateData = [{
          d: moment(appointment.start).format("YYYYMMDDTHHmmss"),
          u: appointment.start.valueOf()
        }];
      }
    }

    if (!!appointment.end) {
      if (appointment.allDay) {
        appointment.endDateData = [{
          d: moment(appointment.end).format("YYYYMMDD"),
          u: appointment.end.valueOf()
        }];
      } else {
        appointment.endDateData = [{
          d: moment(appointment.end).format("YYYYMMDDTHHmmss"),
          u: appointment.end.valueOf()
        }];
      }
    }

    if (!!appointment.title) {
      appointment.name = appointment.title;
    }
    console.log("EDITAPPOINTMENT addAppointmentsToDB ", appointment);

    this.calendarRepository.addAppointmentsToDB([appointment]).subscribe(() => {
      response.next(true);
    }, err => {
      // parallel message adding issue,
      // e.g. when it's added via save draft form component and via noop - at the same time
      // so we simply ignore it and return a success responce.
      // TODO: need to implement DB transactions;
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  sendAppointmentRequest(attachIds?: any): void {
    let body = this.getSaveSendBody();
    console.log("saveappointment sendAppointmentRequest", body);
    if (this.appointment.name === "") {
      this.toastService.show(CalendarConstants.SUBJECT_REQUIRE_MSG);
      return;
    }
    if (this.isSaveSendClick) {
      return;
    }
    this.isSaveSendClick = true;
    this.changeDetector.markForCheck();
    const attach: any = {};
    if (attachIds) {
      attach.aid = attachIds.toString();
    }
    if (this.attachedFileList.length > 0) {
      attach.mp = this.getAttachmentMultiPartUpload();
    }
    body.attach = attach;
    console.log("saveappointment sendAppointmentRequest isNew", this.data.isNewAppointment, this.appointment.neverSent);
    if (!this.data.isNewAppointment) {
      const newAttendee = this.getNewAttendee(this.oldAppointment, body.inviteInfo[0].at);
      if (newAttendee.length > 0 && !this.appointment.neverSent) {
        console.log("saveappointment sendAppointmentRequest newAttendees", newAttendee);
        const dialog = this.dialog.open(SendUpdateAttendeeDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "send-update-confirm-dialog",
          id: "send-update-confirm-dialog",
          data: { attendees: newAttendee }
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res && res.value) {
            if (res.value === "selected") {
              newAttendee.map(e => {
                body.emailInfo.push({
                  a: e.a,
                  d: e.d,
                  t: "t"
                });
              });
            } else if (res.value === "all") {
              const attandees = [
                ...this.getAppointmentRequestee(),
                ...this.getAppointmentOptionalRequestee(),
                ...this.getAppointmentEquipmentRequestee(),
                ...this.getAppointmentLocationRequestee()
              ];
              attandees.map(e => {
                body.emailInfo.push({
                  a: e.a,
                  d: e.d,
                  t: "t"
                });
              });
            }
            if (!this.data.isInstance) {
              if (this.isOnline) {
                  this.commonService.modifyAppointment(body).pipe(take(1)).subscribe(async resp => {
                    console.log("SAVEAPPOINTMENT modifyRes: ", res, this.postEditMoveToFolderId);
                    if (this.postEditMoveToFolderId !== "") {
                      const opBody = { op: "move", id: res.apptId, l: this.postEditMoveToFolderId };
                      let moved = await this.moveAppointmentOp(opBody);

                      console.log("SAVEAPPOINTMENT moved: ", moved);
                      this.postEditMoveToFolderId = "";
                    }

                    if (this.calendarRepository.selectingCalendarView !== "list") {
                      this.calendarRepository.getAllAppointments();
                    }
                    this.broadcaster.broadcast("UPDATE_LIST_VIEW");
                    this.close();
                    this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                    this.clearUploadArray();
                  }, err => {
                    this.toastService.showPlainMessage(err);
                    this.isSaveSendClick = false;
                    this.sendButtonLocked = false;
                    this.changeDetector.markForCheck();
                  });
              } else {
                  let requestBody = { ...body };
                  let url = "/api/modifyAppointment";
                  let op = "modifyAppointment";
                  if (this.commonService.isFakeId(requestBody.id)) {
                    delete requestBody.id;
                    url = "/api/createAppointment";
                    op = "createAppointment";
                  }
                  if (this.commonService.isFakeId(requestBody.did)) {
                    delete requestBody.did;
                  }
                  const request = {
                    "url": url,
                    "method": "post",
                    "body":  requestBody
                  };

                  // fake id
                  if (!body.id) {
                    body.id = this.commonService.createFakeId(body?.subject);
                  }
                  body.eventId = this.appointment.eventId;
                  this.databaseService.addPendingOperation(body.id, op, request).subscribe(res => {
                    const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
                    this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                      const updatedAppointment = {...resp, ...existingNewEvent};
                      this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                        this.calendarRepository.getAllAppointments();
                        this.dialogRef.close();
                      });
                    });
                  });
              }
            } else {
              this.createExceptionReq(body);
              this.sendButtonLocked = false;
            }
          }
        });
        return;
      }
    }

    const attandees = [
      ...this.getAppointmentRequestee(),
      ...this.getAppointmentOptionalRequestee(),
      ...this.getAppointmentEquipmentRequestee(),
      ...this.getAppointmentLocationRequestee()
    ];
    attandees.map(e => {
      body.emailInfo.push({
        a: e.a,
        d: e.d,
        t: "t"
      });
    });

    if (this.data.isNewAppointment) {
      body = _.pickBy(body, _.identity);

      if (this.isOnline) {
        this.commonService.createNewAppointment(body).pipe(take(1)).subscribe(res => {
          const inComposeView = this.getInComposeViewData();
          this.calendarRepository.updateNewCalendarAppointment(inComposeView, res, true);
          this.dialogRef.close();
          this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
          this.clearUploadArray();
        }, err => {
          console.log("sendAppointmentRequest error: ", err);
          this.toastService.showPlainMessage(err);
          this.isSaveSendClick = false;
          this.sendButtonLocked = false;
          this.changeDetector.markForCheck();
        });
      } else {
          let requestBody = { ...body };
          if (this.commonService.isFakeId(requestBody.id)) {
            delete requestBody.id;
          }
          if (this.commonService.isFakeId(requestBody.did)) {
            delete requestBody.did;
          }
          // requestBody.eventId = this.appointment.eventId;
          const request = {
            "url": "/api/createAppointment",
            "method": "post",
            "body":  requestBody
          };

          // fake id
          body.id = this.commonService.createFakeId(body?.mp[0].content);

          this.databaseService.addPendingOperation(body.id, "createAppointment", request).subscribe(res => {
            const existingNewEvent = this.calendarRepository.createLocalAppointment(body);
            this.saveAppointmentRequestApplyToDB(existingNewEvent).subscribe(() => {

              this.calendarRepository.getAllAppointments();
              this.dialogRef.close();
            });
        });
      }
    } else {
      if (!this.data.isInstance) {


        if (this.isOnline) {
          this.commonService.modifyAppointment(body).pipe(take(1)).subscribe(async res => {
            console.log("SAVEAPPOINTMENT modifyRes: ", res, this.postEditMoveToFolderId);
            if (this.postEditMoveToFolderId !== "") {
              const opBody = { op: "move", id: res.apptId, l: this.postEditMoveToFolderId };
              let moved = await this.moveAppointmentOp(opBody);

              console.log("SAVEAPPOINTMENT moved: ", moved);
              this.postEditMoveToFolderId = "";
            }

            this.broadcaster.broadcast("UPDATE_LIST_VIEW");
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.close();
            this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
            this.clearUploadArray();
            if (this.data && this.data.isFromMail) {
              this.router.navigate(["/mail/inbox"]);
              setTimeout(() => {
                this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
                this.broadcaster.broadcast("MAIL_ACTION_FROM_APPOINTMENT", {
                  msgId: this.data.msgId
                });
              }, 1000);
            }
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.sendButtonLocked = false;
            this.changeDetector.markForCheck();
          });
      } else {
          let requestBody = { ...body };
          let url = "/api/modifyAppointment";
          let op = "modifyAppointment";
          if (this.commonService.isFakeId(requestBody.id)) {
            delete requestBody.id;
            url = "/api/createAppointment";
            op = "createAppointment";
          }
          if (this.commonService.isFakeId(requestBody.did)) {
            delete requestBody.did;
          }
          const request = {
            "url": url,
            "method": "post",
            "body":  requestBody
          };

          // fake id
          if (!body.id) {
            body.id = this.commonService.createFakeId(body?.mp[0].content);
          }

          body.apptId = this.appointment.apptId;
          console.log("editOfflineAppointment - appo: ", this.appointment);
          this.databaseService.addPendingOperation(body.apptId, op, request).subscribe(res => {
            const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
            this.databaseService.getAppointmentsById(body.apptId).subscribe(resp => {
              // ToDo: fix data mapping
              const updatedAppointment = {...resp, ...existingNewEvent };
              this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                this.calendarRepository.getAllAppointments();
                this.dialogRef.close();
              });
            });
          });
      }


      } else {
        const oldDateTime = moment(this.appointment.startDateData[0].d).format("HHmmss");
        const selectedStartTime =  new Date(this.startDateFormControl.value);
        const selectDate = moment(selectedStartTime).format("YYYYMMDD");
        body.inviteInfo[0].exceptId = {
          d: selectDate + "T" + oldDateTime,
          tz: this.appointment.startDateData[0].tz
        };
        if (this.isOnline) {
                    this.commonService.createAppointmentException(body).pipe(take(1)).subscribe(res => {
            this.broadcaster.broadcast("UPDATE_LIST_VIEW");
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.close();
            this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
            this.clearUploadArray();
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.changeDetector.markForCheck();
          });
        } else {
            let requestBody = { ...body };
            if (this.commonService.isFakeId(requestBody.id)) {
              delete requestBody.id;
            }
            if (this.commonService.isFakeId(requestBody.did)) {
              delete requestBody.did;
            }
            const request = {
              "url": "/api/createAppointmentException",
              "method": "post",
              "body":  requestBody
            };

            // fake id
            if (!body.id) {
              body.id = this.commonService.createFakeId(body?.mp[0].content);
            }
            this.databaseService.addPendingOperation(body.id, "createAppointmentException", request).subscribe(res => {

              const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
              this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                const updatedAppointment = {...resp, ...existingNewEvent};
                this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                  this.broadcaster.broadcast("UPDATE_LIST_VIEW");
                  if (this.calendarRepository.selectingCalendarView !== "list") {
                    this.calendarRepository.getAllAppointments();
                  }
                  this.close();
                  this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                  this.clearUploadArray();
                });
              });
          });
        }
      }
    }
  }

  getAttachmentMultiPartUpload(): any[] {
    const multiPart: any[] = [];
    this.attachedFileList.map(f => {
      multiPart.push({
        mid: this.appointment.id,
        part: f.part
      });
    });
    return multiPart;
  }

  getInComposeViewData(): AppointmentInCompose {
    const inComposeView: any = {
      calendarDisplay: {

      },
      calendarFolder: {

      }
    };
    inComposeView.isAllDay = this.appointment.allDay ? true : false;
    inComposeView.calendarDisplay.value = this.appointment.fb;
    inComposeView.subject = this.appointment.name;
    inComposeView.location = this.appointment.loc;
    inComposeView.calendarFolder.id = this.appointment.ciFolder;
    const selectedStartTime = !!this.editAppointmentModel.startDate ?
        this.editAppointmentModel.startDate : new Date(this.startDateFormControl.value);
    inComposeView.startDate = selectedStartTime;
    const selectedEndTime = !!this.editAppointmentModel.endDate ?
        this.editAppointmentModel.endDate : new Date(this.endDateFormControl.value);
    inComposeView.endDate = selectedEndTime;
    return inComposeView;
  }

  getNewAttendee(oldAttendee: any, newAttendee: any): any[] {
    const oldatd = oldAttendee.at;
    if (oldAttendee.at && oldAttendee.at.length > 0) {
      return _.differenceBy(newAttendee, oldatd, "a");
    }
    return [];
  }

  confirmationOpration(value: any, body: any, newAttendee: any): void {
    const val = value.value;
    if (val === "sendUpdate") {
      if (newAttendee.length > 0) {
        const dialog = this.dialog.open(SendUpdateAttendeeDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "send-update-confirm-dialog",
          id: "send-update-confirm-dialog",
          data: { attendees: newAttendee }
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res && res.value) {
            if (res.value === "selected") {
              newAttendee.map(e => {
                body.emailInfo.push({
                  a: e.a,
                  d: e.d,
                  t: "t"
                });
              });
            } else if (res.value === "all") {
              const attandees = [...this.getAppointmentRequestee(), ...this.getAppointmentOptionalRequestee()];
              attandees.map(e => {
                body.emailInfo.push({
                  a: e.a,
                  d: e.d,
                  t: "t"
                });
              });
            }
            if (this.isOnline) {
              this.commonService.modifyAppointment(body).pipe(take(1)).subscribe(resp => {
                if (this.calendarRepository.selectingCalendarView !== "list") {
                  this.calendarRepository.getAllAppointments();
                }
                this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                this.clearUploadArray();
                this.close();
              }, err => {
                this.toastService.showPlainMessage(err);
                this.isSaveSendClick = false;
                this.changeDetector.markForCheck();
              });
            } else {
                let requestBody = { ...body };
                if (this.commonService.isFakeId(requestBody.id)) {
                  delete requestBody.id;
                }
                if (this.commonService.isFakeId(requestBody.did)) {
                  delete requestBody.did;
                }
                const request = {
                  "url": "/api/modifyAppointment",
                  "method": "post",
                  "body":  requestBody
                };

                // fake id
                if (!body.id) {
                  body.id = this.commonService.createFakeId(body?.mp[0].content);
                }

                body.eventId = this.appointment.eventId;
                this.databaseService.addPendingOperation(body.eventId, "modifyAppointment", request).subscribe(res => {

                  const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
                  this.databaseService.getAppointmentsById(body.eventId).subscribe(resp => {
                    const updatedAppointment = {...resp, ...existingNewEvent};
                    this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                      this.calendarRepository.getAllAppointments();
                      this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                      this.clearUploadArray();
                      this.close();
                    });
                  });
              });
            }
          }
        });
      }
    } else if (val === "discardSave") {
      this.close();
    }
  }

  toggleScheduler(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    this.showScheduler = !this.showScheduler;
    this.changeDetector.markForCheck();
  }

  editorChange(appointment: any): void {
    if (appointment.checked) {
      this.isPlainTextFormat = false;
      this.appointmentBodyData = MailUtils.plainTextToHTML(this.appointmentBodyData);
    } else {
      this.isPlainTextFormat = true;
      this.appointmentBodyData = MailUtils.getPlainText(this.appointmentBodyData);
    }
  }

  isAppointmentSignificantChange(): boolean {
    let significantChange: boolean = false;
    if (!this.appointment.neverSent && this.appointment.at && this.appointment.at.length > 0) {
      const oldStartTime = moment(this.oldAppointment.startDateData[0].d).toDate().getTime();
      const oldEndTime = moment(this.oldAppointment.endDateData[0].d).toDate().getTime();
      const selectedStartTime = !!this.editAppointmentModel.startDate ?
        this.editAppointmentModel.startDate.getTime() : new Date(this.startDateFormControl.value).getTime();
      const selectedEndTime = !!this.editAppointmentModel.endDate ?
        this.editAppointmentModel.endDate.getTime() : new Date(this.endDateFormControl.value).getTime();
      if (oldStartTime !== selectedStartTime || oldEndTime !== selectedEndTime || this.oldAppointment.loc !== this.appointment.loc)  {
        significantChange = true;
      }
    }
    return significantChange;
  }

  changeNotifyAll(value: any, body: any, newAttendee: any): void {
    const val = value.value;
    if (val === "sendUpdate") {
      const attandees = [...this.getAppointmentRequestee(), ...this.getAppointmentOptionalRequestee()];
      attandees.map(e => {
        body.emailInfo.push({
          a: e.a,
          d: e.d,
          t: "t"
        });
      });
      if (!this.data.isInstance) {
        if (this.isOnline) {
          this.commonService.modifyAppointment(body).pipe(take(1)).subscribe(resp => {
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
            this.close();
            this.clearUploadArray();
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.changeDetector.markForCheck();
          });
        } else {
            let requestBody = { ...body };
            if (this.commonService.isFakeId(requestBody.id)) {
              delete requestBody.id;
            }
            if (this.commonService.isFakeId(requestBody.did)) {
              delete requestBody.did;
            }
            const request = {
              "url": "/api/modifyAppointment",
              "method": "post",
              "body":  requestBody
            };

            // fake id
            if (!body.id) {
              body.id = this.commonService.createFakeId(body?.mp[0].content);
            }
            this.databaseService.addPendingOperation(body.id, "modifyAppointment", request).subscribe(res => {
              const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
              this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                const updatedAppointment = {...resp, ...existingNewEvent};
                this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {
                  this.calendarRepository.getAllAppointments();
                  this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                  this.close();
                  this.clearUploadArray();
                });
              });
            });
        }
      } else {
        const oldDateTime = moment(this.appointment.startDateData[0].d).format("HHmmss");
        const selectedStartTime =  new Date(this.startDateFormControl.value);
        const selectDate = moment(selectedStartTime).format("YYYYMMDD");
        body.inviteInfo[0].exceptId = {
          d: selectDate + "T" + oldDateTime,
          tz: this.appointment.startDateData[0].tz
        };
        if (this.isOnline) {
          this.commonService.createAppointmentException(body).pipe(take(1)).subscribe(resp => {
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
            this.close();
            this.clearUploadArray();
          }, err => {
            this.toastService.showPlainMessage(err);
            this.isSaveSendClick = false;
            this.changeDetector.markForCheck();
          });
        } else {
            let requestBody = { ...body };
            if (this.commonService.isFakeId(requestBody.id)) {
              delete requestBody.id;
            }
            if (this.commonService.isFakeId(requestBody.did)) {
              delete requestBody.did;
            }
            const request = {
              "url": "/api/createAppointmentException",
              "method": "post",
              "body":  requestBody
            };

            // fake id
            if (!body.id) {
              body.id = this.commonService.createFakeId(body?.mp[0].content);
            }
            this.databaseService.addPendingOperation(body.id, "createAppointmentException", request).subscribe(res => {
              const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
              this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
                const updatedAppointment = {...resp, ...existingNewEvent};
              this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

                if (this.calendarRepository.selectingCalendarView !== "list") {
                  this.calendarRepository.getAllAppointments();
                }
                this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
                this.close();
                this.clearUploadArray();
              });
            });
          });
        }
      }
    } else if (val === "discardSave") {
      this.close();
    }
  }

  openLocationSuggestion(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    this.vpSuggestLocation.open();
  }

  onLocationSelect(data) {
    this.locationAutoComplete.setEmailField(data);
    this.vpSuggestLocation.close();
  }

  setLocale() {
    this.browserLang = this.translateService.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      this.browserLang = localLang;
    }
    this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
    this.datePickerAdapter.setLocale(this.browserLang);
    if (this.browserLang === "de") {
      registerLocaleData(localeDE, this.browserLang);
    } else {
      registerLocaleData(localEN, this.browserLang);
    }
  }

  addUploadFileItem(): void {
    this.browseFileds.push(new Date().getTime());
    this.changeDetector.markForCheck();
  }

  removeBrowse(index: number): void {
    this.browseFileds.splice(index, 1);
    this.changeDetector.markForCheck();
  }

  getFileListItem(): any[] {
    const fileList: any[] = [];
    const inputFile = document.querySelectorAll(".form-group input[type=file]");
    if (!!inputFile && inputFile.length > 0) {
      inputFile.forEach( (f: any) => {
        const files = f.files;
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
          }
        }
      });
    }
    return fileList;
  }

  clearUploadArray(): void {
    this.browseFileds = [];
    this.changeDetector.markForCheck();
  }

  handleStartTimeZoneChanges(startTimeZoneKey: any): void {
    this.startTimeZone.key = startTimeZoneKey.key;
    this.startTimeZone.value = startTimeZoneKey.value;
    this.endTimeZone.key = this.startTimeZone.key;
    this.endTimeZone.value = this.startTimeZone.value;
    this.isAppointmentChange = true;
    this.changeDetector.markForCheck();
  }

  handleEndTimeZoneChanges(endTimeZoneKey: any): void {
    this.endTimeZone.key = endTimeZoneKey.key;
    this.endTimeZone.value = endTimeZoneKey.value;
    this.valueChange();
    this.changeDetector.markForCheck();
  }

  getTimeZoneValue(key: string): string {
    const item = this.timeZone.filter(items => items.key === key);
    if (!!item && item.length > 0) {
      return item[0].value;
    }
    return "";
  }

  isValidDuration(): boolean {
    let validDuration: boolean = true;
    if (this.isShowAppointmentTimeZone) {
      let startTime = new Date(this.startDateFormControl.value).getTime();
      let endTime = new Date(this.endDateFormControl.value).getTime();
      if (this.endTimeZone && this.endTimeZone !== this.startTimeZone) {
        const startOffset = moment().tz(this.startTimeZone.key).utcOffset();
        const endOffset = moment().tz(this.endTimeZone.key).utcOffset();
        startTime = startTime - (startOffset * 60000);
        endTime = endTime - (endOffset * 60000);
      }
      validDuration = (startTime <= endTime);
    }
    return validDuration;
  }

  uploadMobileAttachment(files: any): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      this.selectedUploadFiles.push(files[i]);
    }
    this.valueChange();
    this.changeDetector.markForCheck();
  }

  removeMobileAttach(index: number): void {
    this.selectedUploadFiles.splice(index, 1);
    this.changeDetector.markForCheck();
  }

  sendForwardAppointment(): void {
    if (this.sendButtonLocked) {
      return;
    }
    this.sendButtonLocked = true;
    const emails = <any> this.toAttendeeAutoComplete.getSelectedEmail();
    const toAttendee: any[] = [];
    emails.filter(e => !!e).map(e => {
      toAttendee.push({
        a: e.email,
        p: e.name,
        t: "t"
      });
    });
    const body: any = {
      subject: this.appointment.name,
      mp: {
        ct: "multipart/alternative",
        mp: [
          {
            ct: "text/plain",
            content: MailUtils.getPlainText(this.appointmentBodyData)
          },
          {
            ct: "text/html",
            content: this.appointmentBodyData
          }
        ]
      },
      emailInfo: toAttendee,
      id: this.appointment.apptId
    };
    if (this.data.isForwardInstance) {
      body.instance = {
          tz: this.appointment.startDateData[0].tz,
          d: this.appointment.startDateData[0].d
      };
    }

    if (this.isOnline) {
      this.commonService.forwardAppointment(body).pipe(take(1)).subscribe(res => {
        this.close();
        this.toastService.show("CALENDARS.INVITE_FORWARD_MSG");
      }, error => {
        console.log("FORWARDAPPOERROR: ", error);
        if (error === "invalid request: Missing forwardees") {
          this.toastService.show("CALENDARS.MIN_ONE_RECEIPIENT_REQUIRED");
        } else {
          this.toastService.showPlainMessage(error);
        }
        this.sendButtonLocked = false;
      });
    } else {
        let requestBody = { ...body };
        if (this.commonService.isFakeId(requestBody.id)) {
          delete requestBody.id;
        }
        if (this.commonService.isFakeId(requestBody.did)) {
          delete requestBody.did;
        }
        const request = {
          "url": "/api/forwardAppointment",
          "method": "post",
          "body":  requestBody
        };

        // fake id
        if (!body.id) {
          body.id = this.commonService.createFakeId(body?.mp[0].content);
        }
        this.databaseService.addPendingOperation(body.id, "forwardAppointment", request).subscribe(res => {
          this.close();
          this.toastService.show("CALENDARS.INVITE_FORWARD_MSG");
      });
    }
  }

  setForwardAppointmentBody(): void {
    let forwardBody: string = "";
    const attendee: any[] = [];
        const startDate = this.appointment.startDateData[0].d;
    const endDate = this.appointment.endDateData[0].d;
    const start = moment(startDate).tz(this.appointment.startDateData[0].d).format("LLLL");
    const end = moment(endDate).tz(this.appointment.endDateData[0].d).format("LTS");
    if (this.appointment.at && this.appointment.at.length > 0) {
      this.appointment.at.map(atnd => {
        attendee.push(atnd.a);
      });
    }
    let translation: any = {};
    this.translateService.get([
      "CALENDARS.ORIGINAL_APPT_LBL", "CALENDARS.ORGANIZER_LBL",
      "CALENDARS.TIME_LBL", "CALENDARS.INVITEES_LBL", "CALENDARS.SUBJECT_LBL"]
    ).pipe(take(1)).subscribe(res => {
      let description = "";
      if (this.appointmentBodyData) description = this.appointmentBodyData;
      translation = res;
      forwardBody += "----- " + translation["CALENDARS.ORIGINAL_APPT_LBL"] + " -----<br/>" +
      translation["CALENDARS.SUBJECT_LBL"] + ":" + "Fwd: " + this.appointment.name + "<br/>" +
      translation["CALENDARS.ORGANIZER_LBL"] + ": " + this.appointment.or.a + "<br/>" +
      translation["CALENDARS.TIME_LBL"] + ": " + start + " - " + end + " <br/>" +
      translation["CALENDARS.INVITEES_LBL"] + ": " + attendee.toString() +
      "<br/><br/>";
      if (this.appointmentBodyData) forwardBody += description;
      this.appointmentBodyData = forwardBody;
      this.appointment.name = "Fwd: " + this.appointment.name;
      this.changeDetector.markForCheck();
    });
  }

  triggeredMenuControlFocus(value: boolean) {
    this.triggerMenuControlFocus = value;
    setTimeout(() => {
      const activeMenuItem = document.querySelector(".cal-dropdown-menu button.mat-menu-item.active");
      if (activeMenuItem) {
        activeMenuItem.scrollIntoView({ behavior: "smooth", block: "center" });
        this.changeDetector.markForCheck();
      }
    }, 100);
  }

  isEqual(dateLeft: Date, dateRight: Date): boolean {
    const left = moment(dateLeft).format("HH:mm");
    const right = moment(dateRight).format("HH:mm");
    return left === right;
  }

  openEquipmentDialog(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    const dialog = this.dialog.open(CalendarEquipmentDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-equipment-dialog",
      id: "calendar-equipment-dialog",
      data: { usersData : this.equipmentAutoComplete?.getSelectedEmail(), dateInfo: {startTime: this.startTimeControl, endTime: this.endTimeControl}}
    });
    dialog.afterClosed().pipe(take(1)).subscribe( res => {
      if (!!res && res.equipment) {
        this.equipmentAutoComplete?.resetEmail();
        const equip = res.equipment;
        this.valueChange();
        const emails = [];
        equip.map( data => {
          const info: any = { email: data.email, t: "", fullName: data.name || data.fullName };
          this.equipmentAutoComplete?.setEmailField(info);
          emails.push(data.email);
          this.valueChange();
        });
        this.schedulerComponent.updateAttendeesByType(emails, "EQUIPMENT");
      }
    });
  }

  isEquipmentDataAvailable(): boolean {
    const equipments = this.equipmentAutoComplete?.getSelectedEmail();
    if (equipments?.length > 0) {
      return true;
    }
    return false;
  }

  checkRecurConflict(isSend: boolean = false, attachmentId?: any): void {
    const appt = this.getSaveSendBody();
    const users: any[] = [];
    const allEquipments = this.equipmentAutoComplete?.getSelectedEmail();
    allEquipments.map( em => {
      users.push({
        name: em.email
      });
    });
    const request = {
      "CheckRecurConflictsRequest": {
          "@": {
              xmlns: "urn:zimbraMail"
          },
          "comp": [
            {
              "allDay":  appt.inviteInfo[0].allDay,
              "s": appt.inviteInfo[0].s,
              "e": appt.inviteInfo[0].e
            }
          ],
          "s": moment(appt.inviteInfo[0].s.d).toDate().getTime(),
          "e": moment(appt.inviteInfo[0].e.d).toDate().getTime(),
          "usr": users
      }
    };
    if (!!appt.uid) {
      request.CheckRecurConflictsRequest["excludeUid"] = appt.uid;
    }
    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      if (!!res && isArray(res.CheckRecurConflictsResponse) && res.CheckRecurConflictsResponse[0].inst) {
        const inst = res.CheckRecurConflictsResponse[0].inst;
        const dialog = this.dialog.open(ConflictEquipmentDialogComponent, { // checkRecurConflict
          maxWidth: "95%",
          autoFocus: false,
          panelClass: "conflict-equipment-dialog",
          id: "conflict-equipment-dialog",
          data: { inst: inst, startTime: this.startTimeControl, endTime: this.endTimeControl, conflictEmails: this.conflictEmails, selectedAttendees: this.attendeeAutoComplete.getSelectedEmail() }
        });
        dialog.afterClosed().pipe(take(1)).subscribe( resp => {
          if (!!resp && resp.value) {
            const value = resp.value;
            if (resp.value === "yes") {
              const usr = users;
              const usersItem: any[] = [];
              usr.map(item => {
                usersItem.push(item.name);
              });
              this.checkRightsRequest(usersItem, isSend, attachmentId);
            }
          }
        });
      } else {
        const usr = users;
        const usersItem: any[] = [];
        usr.map(item => {
          usersItem.push(item.name);
        });
        this.checkRightsRequest(usersItem, isSend, attachmentId);
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  getAppointmentEquipmentRequestee(): any[] {
    const requestee: any[] = [];
    const emails = this.equipmentAutoComplete?.getSelectedEmail();
    emails?.filter(e => !!e).map(e => {
      requestee.push({
        a: e.email,
        d: e.name,
        ptst: "NE",
        role: "NON",
        rsvp: this.isRsvp ? "1" : "0",
        cutype: "RES"
      });
    });
    return requestee;
  }

  checkRightsRequest(usersItem: any, isSend: boolean, attachmentId?: any): void {
    const allUsers: any[] = [];
    usersItem.map( item => {
      allUsers.push({
        "type": "account",
        "by": "name",
        "key": item,
        "right": "invite"
      });
    });
    if (this.isLocationDataAvailable()) {
      const allLocation = this.locationAutoComplete.getSelectedEmail();
      allLocation.map( item => {
        allUsers.push({
          "type": "account",
          "by": "name",
          "key": item.email,
          "right": "invite"
        });
      });
    }
    const request = {
      "CheckRightsRequest": {
          "@": {
              xmlns: "urn:zimbraAccount"
          },
          target: allUsers
      }
    };

    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe( res => {
      if (!!res && isArray(res.CheckRightsResponse) && res.CheckRightsResponse[0].target) {
         if (attachmentId) {
            if (isSend) {
              this.sendAppointmentRequest(attachmentId);
            } else {
              this.saveAppointmentRequest(attachmentId);
            }
          } else {
              if (isSend) {
                this.sendAppointmentRequest();
              } else {
                this.saveAppointmentRequest();
              }
          }
      }
    }, err => {
      this.toastService.showPlainMessage(err);
    });
  }

  closeAppointmentDialog(): void {
    if (this.isProposeNewTime || this.isForwardAppointment) {
      this.close();
      return;
    }
    if (this.isAppointmentChange) {
      const dialog = this.dialog.open(SaveChangeAppointmentDialogComponent, {
        maxWidth: "95%",
        autoFocus: false,
        panelClass: "save-change-appt-dialog",
        id: "save-change-appt-dialog"
      });
      dialog.afterClosed().pipe(take(1)).subscribe( resp => {
        if (resp === undefined) {
          this.isReminderConfigClick = false;
          this.changeDetector.markForCheck();
        }
        if (!!resp && resp.value) {
          const val = resp.value;
          if (val === "yes") {
            this.saveAppointment();
            this.close();
          } else if (val === "no") {
            this.close();
          } else if (val === "cancel") {
            if (this.isReminderConfigClick) {
              this.isReminderConfigClick = false;
              this.changeDetector.markForCheck();
            }
          }
        }
      });
    } else {
      this.close();
    }
  }

  setLocationToField(): void {
    const location = this.appointment.loc;
    console.log("[setLocationToField]", location);
    setTimeout(() => {
      if (!!location && location !== "" && !! this.locationAutoComplete) {
        this.locationAutoComplete.resetEmail();
        this.locationAutoComplete.resetInputValue();
        const loc = location.split(";");
        loc.map(item => {
          const parsedEmailData = CalenderUtils.parseEmailAddress(item);
          if ( parsedEmailData !== null) {
            const setData = {
              fullName: !!parsedEmailData.name ? parsedEmailData.name.replace(/"/g, "") : parsedEmailData.addr,
              email: parsedEmailData.addr
            };
            this.locationAutoComplete.setEmailField(setData);
          } else {
            console.log("[setLocationToField] setInputBox", item);
            this.locationAutoComplete.setInputBox(item);
          }
          if (this.locationAutoComplete.getSelectedEmail().length > 0 && this.equipmentAutoComplete?.getSelectedEmail().length > 0) {
            const equip = this.equipmentAutoComplete?.getSelectedEmail();
            const locs = this.locationAutoComplete.getSelectedEmail();
            const uniqEquip = equip;
            locs.forEach( l => {
              equip.forEach(eq => {
                if (l.email === eq.email) {
                  uniqEquip.splice(uniqEquip.indexOf(eq), 1);
                }
              });
            });
            this.equipmentAutoComplete?.resetEmail();
            this.equipmentAutoComplete?.resetInputValue();
            uniqEquip.map( eq => {
              const info: any = { email: eq.email, t: "", fullName: eq.email };
              this.equipmentAutoComplete?.setEmailField(info);
            });
          }
        });
      }
    }, 200);
  }

  getLocationString(): string {
    let location = "";
    if (this.locationAutoComplete) {
      if (this.locationAutoComplete.getSelectedEmail().length > 0) {
        const item = this.locationAutoComplete.getSelectedEmail();
        item.map(val => {
          location += `"${val.name}" <${val.email}>; `;
        });
      }
      location += this.locationAutoComplete.getInputValue();
      return location;
    }

  }

  isLocationDataAvailable(): boolean {
    if (this.locationAutoComplete) {
      const locations = this.locationAutoComplete.getSelectedEmail();
      if (locations.length > 0) {
        return true;
      }
      return false;
    }
  }

  checkLocationRecurConflict(isSend: boolean = false, attachmentId?: any): void {
    const appt = this.getSaveSendBody();
    const users: any[] = [];
    const allEquipments = this.locationAutoComplete.getSelectedEmail();
    allEquipments.map( em => {
      users.push({
        name: em.email
      });
    });
    const request = {
      "CheckRecurConflictsRequest": {
          "@": {
              xmlns: "urn:zimbraMail"
          },
          "comp": [
            {
              "allDay":  appt.inviteInfo[0].allDay,
              "s": appt.inviteInfo[0].s,
              "e": appt.inviteInfo[0].e
            }
          ],
          "s": moment(appt.inviteInfo[0].s.d).toDate().getTime(),
          "e": moment(appt.inviteInfo[0].e.d).toDate().getTime(),
          "usr": users
      }
    };
    if (!!appt.uid) {
      request.CheckRecurConflictsRequest["excludeUid"] = appt.uid;
    }
    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      if (!!res && isArray(res.CheckRecurConflictsResponse) && res.CheckRecurConflictsResponse[0].inst) {
        const inst = res.CheckRecurConflictsResponse[0].inst;
        const dialog = this.dialog.open(ConflictEquipmentDialogComponent, { // checkLocationRecurConflict
          maxWidth: "95%",
          autoFocus: false,
          panelClass: "conflict-equipment-dialog",
          id: "conflict-equipment-dialog",
          data: { inst: inst, startTime: this.startTimeControl, endTime: this.endTimeControl, conflictEmails: this.conflictEmails, selectedAttendees: this.attendeeAutoComplete.getSelectedEmail() }
        });
        dialog.afterClosed().pipe(take(1)).subscribe( resp => {
          if (!!resp && resp.value) {
            const value = resp.value;
            if (resp.value === "yes") {
              const usr = users;
              const usersItem: any[] = [];
              usr.map(item => {
                usersItem.push(item.name);
              });
              this.checkRightsRequest(usersItem, isSend, attachmentId);
            }
          }
        });
      } else {
        const usr = users;
        const usersItem: any[] = [];
        usr.map(item => {
          usersItem.push(item.name);
        });
        this.checkRightsRequest(usersItem, isSend, attachmentId);
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  getAppointmentLocationRequestee(): any[] {
    const requestee: any[] = [];
    if (this.isVNCTalkCall) return requestee;
    const emails = this.locationAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map(e => {
      requestee.push({
        a: e.email,
        d: e.name,
        ptst: "NE",
        role: "NON",
        rsvp: this.isRsvp ? "1" : "0",
        cutype: "RES"
      });
    });
    return requestee;
  }

  sendProposeNewTime(): void {
    if (this.sendButtonLocked) {
      return;
    }
    this.sendButtonLocked = true;

    const emails = <any> this.toAttendeeAutoComplete.getSelectedEmail();
    const appt = this.getSaveSendBody();
    let subject = "";
    this.translateService.get("CALENDARS.PROPOSED_NEW_TIME").pipe(take(1)).subscribe(text => {
      subject = text + ": " + appt.subject;
    });
    const name = appt.subject;
    const s = appt.inviteInfo[0].s;
    const e = appt.inviteInfo[0].e;
    const uid = appt.uid;
    const allDay = appt.inviteInfo[0].allDay;
    const or = this.appointment.or.a;
    const ms = appt.ms;
    const rev = appt.rev;
    const comp = appt.comp;
    const id = appt.id;
    const toAttendee: any[] = [];
    emails.map( e => {
      toAttendee.push({
        a: e.email,
        t: "t"
      });
    });
    let request = {};
    if (this.data.isWithoutId) {
      request = {
        "ModifyAppointmentRequest": {
            "@": {
                xmlns: "urn:zimbraMail"
            },
            "comp": comp,
            "m": {
             "e": toAttendee,
              "@": {
                "su": subject
              },
              "inv": {
                "@": {},
                "comp": [{
                  "name": name,
                  "uid": uid,
                  "allDay": allDay,
                  "s": s,
                  "e": e,
                  "or": {
                    "a": or
                  }
                }]
              },
              "mp": {
                "mp": [
                  {
                    "ct": "text/plain",
                    "content": "\n\n*~*~*~*~*~*~*~*~*~*\n\n\n" + MailUtils.HTMLToPlainText(this.appointmentBodyData)
                  },
                  {
                    "ct": "text/html",
                    "content": "<div>*~*~*~*~*~*~*~*~*~*</div><br>" + this.appointmentBodyData
                  }
                ],
                "ct": "multipart/alternative"
              }
            }
        }
      };
    } else {
      request = {
        "ModifyAppointmentRequest": {
            "@": {
                xmlns: "urn:zimbraMail"
            },
            "comp": comp,
            "id": id,
            "ms": ms,
            "rev": rev,
            "m": {
             "e": toAttendee,
              "@": {
                "su": subject
              },
              "inv": {
                "@": {},
                "comp": [{
                  "name": name,
                  "uid": uid,
                  "allDay": allDay,
                  "s": s,
                  "e": e,
                  "or": {
                    "a": or
                  }
                }]
              },
              "mp": {
                "mp": [
                  {
                    "ct": "text/plain",
                    "content": "\n\n*~*~*~*~*~*~*~*~*~*\n\n\n" + MailUtils.HTMLToPlainText(this.appointmentBodyData)
                  },
                  {
                    "ct": "text/html",
                    "content": "<div>*~*~*~*~*~*~*~*~*~*</div><br>" + this.appointmentBodyData
                  }
                ],
                "ct": "multipart/alternative"
              }
            }
        }
      };
    }
    if (this.isOnline) {
      this.commonService.createBatchRequest(request).pipe(take(1)).subscribe( res => {
        this.toastService.show("NEW_TIME_PROPOSED_SEND_MSG");
        // if (!this.configService.deleteInvitationOnProposeNewTime) { // We do not have this flow at zimbra
        //   this.convRepository.removeMailMessage(this.messageItem);
        // }
        this.close();
      }, error => {
        this.toastService.showPlainMessage(error);
        this.sendButtonLocked = false;
      });
    } else {
      let requestBody = { ...request };
      const payload = {
        "url": "/api/batchRequest",
        "method": "post",
        "body":  requestBody
      };
      this.databaseService.addPendingOperation(appt.id, "batchRequest", payload).subscribe(res => {
            this.toastService.show("NEW_TIME_PROPOSED_SEND_MSG");
            // if (!this.configService.deleteInvitationOnProposeNewTime) { // We do not have this flow at zimbra
            //   this.convRepository.removeMailMessage(this.messageItem);
            // }
            this.close();
      });
    }
  }

  setProposeNewTimeData(): void {
    const organizer = this.appointment.or.a;
    const emailInfo: EmailInformation = { a: organizer, t: "", d: organizer };
    setTimeout(() => {
      this.toAttendeeAutoComplete.setEmailField(emailInfo);
    }, 100);
    let body = "";
    if (this.appointment.descHTML) {
      body = this.appointment.descHTML;
    } else if (this.appointment.desc) {
      body = MailUtils.plainTextToHTML(this.appointment.desc);
    }
    if (!!body && body !== "") {
      if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
        body = body.replace("</body></html>", "").
            split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
            body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
      }
      if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
        body = body.replace("</body></html>", "").
            split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
      }
    }
    if (body === "</div><br /></body></html>") {
      if (this.appointment.descHTML) {
        body = this.appointment.descHTML;
      } else if (this.appointment.desc) {
        body = MailUtils.plainTextToHTML(this.appointment.desc);
      }
    }
    this.appointmentBodyData = body;
    this.changeDetector.markForCheck();
  }

  getFolderById(folderId: string): CalendarFolder {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let calFolder: CalendarFolder;
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        calFolder = f;
                    }
                } else {
                    if (f.id === folderId) {
                      calFolder = f;
                    }
                }
            } else {
                if (f.id === folderId) {
                  calFolder = f;
                }
            }
        });
    }
    return calFolder;
 }

  openLocationDialog(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    const dialog = this.dialog.open(CalendarFindLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-find-location-dialog",
      id: "calendar-find-location-dialog",
      data: { usersData : this.locationAutoComplete.getSelectedEmail(), dateInfo: {startTime: this.startTimeControl, endTime: this.endTimeControl}}
    });
    dialog.afterClosed().pipe(take(1)).subscribe( res => {
      if (!!res && res.location) {
        this.locationAutoComplete.resetEmail();
        this.locationAutoComplete.resetInputValue();
        const location = res.location;
        this.valueChange();
        this.schedulerComponent.updateAttendeesByType(location.map(v => v.email), "LOCATION");
        location.map( data => {
          const info: any = { email: data.email, t: "", fullName: data.name || data.fullName };
          this.locationAutoComplete.setEmailField(info);
          this.valueChange();
        });
      }
    });
  }

  openDesktopAttendeeDialog(): void {
    const alreadyAddemailInfo = [];
    const emails = this.attendeeAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map((e: any) => {
      alreadyAddemailInfo.push({
        d: e.name || e.title  || e.email,
        name: e.name || e.title  || e.email,
        email: e.email,
        t: "To"
      });
    });
    const dialogArgs = {
      width: "850px",
      height: "600px",
      autoFocus: true,
      panelClass: "calenda_select_addresses_dialog",
      data: alreadyAddemailInfo
    };
    const dialogRef = this.dialog.open(CalendarSelectAddressesDialogComponent, dialogArgs);
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.selectedAddress) {
        this.attendeeAutoComplete.resetEmail();
        const attendees = res.selectedAddress;
        if (!!this.schedulerComponent) {
          this.schedulerComponent.updateAttendeesByType(attendees.map(v => v.email || v.d), "PERSON");
          if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
            this.scheduleAssistantComponent.suggestAction(true, false);
          }
        }
        this.valueChange();
        attendees.map( atten => {
          const emailInfo: EmailInformation = { a: atten.email || atten.d , t: "", d: atten.d || atten.name || atten.email };
          this.attendeeAutoComplete.setEmailField(emailInfo);
        });
      }
    });
  }

  onAddAttendee(data, type = "PERSON") {
    console.log("[onAddAttendee]", data, type);
    if (!!this.schedulerComponent) {
      this.schedulerComponent.addAttendee(data?.email || data, type);
      if (type === "PERSON") {
        if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
          this.scheduleAssistantComponent.suggestAction();
        }
      }
    }
  }

  onRemoveAttendee(data) {
    console.log("[onRemoveAttendee]", data);
    this.isConflicted = false;
    this.locationConflict = false;
    this.changeDetector.markForCheck();
    if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
      this.scheduleAssistantComponent.suggestAction();
    }
    if (!!this.schedulerComponent) {
      if (typeof data === "object") {
        this.schedulerComponent.removeAttendee(data.email, true);
      } else {
        this.schedulerComponent.removeAttendee(data, true);
      }
    }
  }

  openDesktopOptionalAttendeeDialog(): void {
    const alreadyAddemailInfo = [];
    const emails = this.optionalAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map((e: any) => {
      alreadyAddemailInfo.push({
        d: e.name || e.title  || e.email,
        name: e.name || e.title  || e.email,
        email: e.email,
        t: "To"
      });
    });
    const dialogArgs = {
      width: "850px",
      height: "600px",
      autoFocus: true,
      panelClass: "calenda_select_addresses_dialog",
      data: alreadyAddemailInfo
    };
    const dialogRef = this.dialog.open(CalendarSelectAddressesDialogComponent, dialogArgs);
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.selectedAddress) {
        this.optionalAutoComplete.resetEmail();
        const attendees = res.selectedAddress;
        this.schedulerComponent.updateAttendeesByType(attendees.map(v => v.email || v.d), "PERSON");
        if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
          this.scheduleAssistantComponent.suggestAction(true, false);
        }
        this.valueChange();
        attendees.map( atten => {
          const emailInfo: EmailInformation = { a: atten.email || atten.d , t: "", d: atten.d || atten.name || atten.email };
          this.optionalAutoComplete.setEmailField(emailInfo);
          this.valueChange();
        });
      }
    });
  }

  openDesktopToAttendeeDialog(): void {
    const alreadyAddemailInfo = [];
    const emails = this.toAttendeeAutoComplete.getSelectedEmail();
    emails.filter(e => !!e).map((e: any) => {
      alreadyAddemailInfo.push({
        d: e.name || e.title  || e.email,
        name: e.name || e.title  || e.email,
        email: e.email,
        t: "To"
      });
    });
    const dialogArgs = {
      width: "850px",
      height: "600px",
      autoFocus: true,
      panelClass: "calenda_select_addresses_dialog",
      data: alreadyAddemailInfo
    };
    const dialogRef = this.dialog.open(CalendarSelectAddressesDialogComponent, dialogArgs);
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.selectedAddress) {
        this.toAttendeeAutoComplete.resetEmail();
        const attendees = res.selectedAddress;
        this.schedulerComponent.updateAttendeesByType(attendees.map(v => v.email || v.d), "PERSON");
        if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
          this.scheduleAssistantComponent.suggestAction(true, false);
        }
        attendees.map( atten => {
          const emailInfo: EmailInformation = { a: atten.email || atten.d , t: "", d: atten.d || atten.name || atten.email };
          this.toAttendeeAutoComplete.setEmailField(emailInfo);
          this.valueChange();
        });
      }
    });
  }

  openMobileAttendeeDialog(): void {
    const dialog = this.dialog.open(CalendarMobileSelectAddressDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar_mobile-selection-address-dialog",
      data: { type: "to" }
    });
    dialog.afterClosed().pipe(take(1)).subscribe( res => {
      if (!!res && !!res.address) {
        res.address.map( item => {
          const emailItem: EmailInformation = { a: item.email, d: item.d || item.name, p: item.name, t: "" } as EmailInformation;
          this.attendeeAutoComplete.setEmailField(emailItem);
          this.schedulerComponent.addAttendee(item.email);
        });
        this.changeDetector.markForCheck();
      }
    });
  }

  openMobileOptionalAttendeeDialog(): void {
    const dialog = this.dialog.open(CalendarMobileSelectAddressDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar_mobile-selection-address-dialog",
      data: { type: "to" }
    });
    dialog.afterClosed().pipe(take(1)).subscribe( res => {
      if (!!res && !!res.address) {
        res.address.map( item => {
          const emailItem: EmailInformation = { a: item.email, d: item.d || item.name, p: item.name, t: "" } as EmailInformation;
          this.optionalAutoComplete.setEmailField(emailItem);
          this.schedulerComponent.addAttendee(item.email);
        });
        this.schedulerComponent.updateAttendeesByType(res.address.map(v => v.email), "PERSON");
        if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
          this.scheduleAssistantComponent.suggestAction(true, false);
        }
        this.changeDetector.markForCheck();
      }
    });
  }

  openMobileToAttendeeDialog(): void {
    const dialog = this.dialog.open(CalendarMobileSelectAddressDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar_mobile-selection-address-dialog",
      data: { type: "to" }
    });
    dialog.afterClosed().pipe(take(1)).subscribe( res => {
      if (!!res && !!res.address) {
        res.address.map( item => {
          const emailItem: EmailInformation = { a: item.email, d: item.d || item.name, p: item.name, t: "" } as EmailInformation;
          this.toAttendeeAutoComplete.setEmailField(emailItem);
        });
        this.schedulerComponent.updateAttendeesByType(res.address.map(v => v.email), "PERSON");
        if (this.showTimeSuggestion && this.scheduleAssistantComponent) {
          this.scheduleAssistantComponent.suggestAction(true, false);
        }
        this.changeDetector.markForCheck();
      }
    });
  }

  getCalendarFolders(folders: CalendarFolder[]): CalendarFolder[] {
    const foldersWithoutTrash: CalendarFolder[] = [];
    folders.forEach((folder: CalendarFolder) => {
      if (folder.id !== "3" && folder.name !== "Trash" && !folder.url) {
        foldersWithoutTrash.push(folder);
        if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
          foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
        }
        const childFolders = CalenderUtils.getChildFolders([folder]);
        if (childFolders.length > 0) {
          childFolders.map( f => {
            foldersWithoutTrash.push(f);
            if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
              foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
            }
          });
        }
      }
    });
    foldersWithoutTrash.forEach(element => {
      element.key = this.mailService.getFolderNameKey(element.name, CalendarConstants.SYSTEM_FOLDERS, true);
    });
    return foldersWithoutTrash;
  }

  openConfigureReminder(): void {
    const dialog = this.dialog.open(EmailNotificationConfigureComponent, {
      autoFocus: false,
      panelClass: "email-notification-configure"
    });
    dialog.afterClosed().pipe(take(1)).subscribe(res => {
      console.log("[openNotification][aftrClosed]", this.configService.prefs.zimbraPrefCalendarReminderEmail);
      if (this.configService.prefs.zimbraPrefCalendarReminderEmail === "") {
        this.isPrefCalendarReminderEmail = false;
        this.prefCalendarreminderEmail = "";
      } else {
        this.prefCalendarreminderEmail = this.configService.prefs.zimbraPrefCalendarReminderEmail;
      }
      this.changeDetector.markForCheck();
    });
  }

  setPrivatePublicVisibility(): void {
    const appointmentVisibility = this.configService.prefs.zimbraPrefCalendarApptVisibility;
    if (!!appointmentVisibility && appointmentVisibility !== null) {
      this.appointment.class = appointmentVisibility === "private" ? "PRI" : "PUB";
      this.changeDetector.markForCheck();
    }
  }

  setReminderTimeFromPreference(reminderTime): void {
    const alaramOptions = this.alaramList;
    this.alarmReminderOption = this.sharedCalendarService.switchAlarm(reminderTime, alaramOptions, this.alarmReminderOption);
    this.changeDetector.markForCheck();
  }

  routeToPreferenceReminder(): void {
    this.router.navigate(["preferences/notifications"]);
    this.dialogRef.close();
  }

  private calculateDateTimeDefault(isSelectedFromGrid: boolean = true, startDate?: Date, timeSpanInMinutes: number = 30, endDateData?: Date): void {
    const duration = this.configService.prefs.zimbraPrefCalendarDefaultApptDuration;
    if (!!duration && duration !== null) {
      const value = duration.replace(/m/g, "");
      timeSpanInMinutes = value;
    }
    const newDate = new Date();
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    startDate = startDate || newDate;

    let endDate = new Date();
    if (endDateData) {
      endDate = endDateData;
    } else {
      endDate = this.dateAdapter.addMinutes(startDate, timeSpanInMinutes);
    }
    this.startDateFormControl = new FormControl(startDate);
    this.endDateFormControl = new FormControl(endDate);
    this.startTimeControl = startDate;
    this.endTimeControl = endDate;

    this.editAppointmentModel.startDate = startDate;
    this.editAppointmentModel.endDate = endDate;
    this.changeDetector.markForCheck();
  }

  private getPrefDuration(): string {
    let timeSpan = "";
    const duration = this.configService.prefs.zimbraPrefCalendarDefaultApptDuration;
    if (!!duration && duration !== null) {
      const value = duration.replace(/m/g, "");
      timeSpan = value;
    }
    return timeSpan;
  }

  openCustomRepeatDialog(): void {
    console.log("[openCustomRepeatDialog]", this.appointment);
    if (this.data.disableRepeat) {
      return;
    }
    if (this.recurringData === "NON") {
      this.recurringData = "";
      this.changeDetector.markForCheck();
    }
    let data: any;
    if (this.data.isNewAppointment) {
      const recurrence = this.getRecurrence();
      data = recurrence;
    } else {
      const recur = this.appointment.recur;
      if (recur === "CUSTOM") {
        data = isArray(this.recurringData) ? this.recurringData : [this.recurringData];
      } else {
        const recurrence = this.getRecurrence();
        data = recurrence;
      }
    }
    const dlg = this.dialog.open(CustomRepeatDialogComponent, {
      autoFocus: false,
      panelClass: "custom_repeat_dialog",
      data: {recur: data , startDate: this.startDateFormControl.value}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        const value = res.value;
        if (value === "") {
          this.appointment.recur = "NON";
          this.repeatType = "NONE";
          this.blurb = "";
        } else {
          this.appointment.recur = "CUSTOM";
          this.recurringData = value;
          this.parseData([value]);
          this.getBlurb();
          if (res.startDate !== null) {
            const dateItem = new Date(res.startDate);
            this.startDateFormControl = new FormControl(dateItem);
            this.handleStartTimeChanges(dateItem);
          }
        }
        this.changeDetector.markForCheck();
      } else {
        if (this.blurb === "") {
          this.changeDetector.markForCheck();
        }
      }
    });
  }

  parseData(recurRules): void {
    this.repeatEndType = "N";
    this.repeatCustomType = "S";
    this.repeatWeeklyDays = [];
    for (let k = 0; k < recurRules.length; ++k) {
        const adds = isArray(recurRules[k].add) ? recurRules[k].add : [recurRules[k].add];
        if (!adds) {
            continue;
        }
        this._startDate = new Date(this.startDateFormControl.value);
        this.repeatYearlyMonthsList = this._startDate.getMonth() + 1;
        for (let i = 0; i < adds.length; ++i) {
            const rules = isArray(adds[i].rule) ? adds[i].rule : [adds[i].rule];
            if (!rules) {
                continue;
            }
            for (let j = 0; j < rules.length; ++j) {
                const rule = rules[j];
                if (rule.freq) {
                    this.repeatType = rule.freq.substring(0, 3);
                    if (rule.interval && rule.interval.ival) {
                        this.repeatCustomCount = parseInt(rule.interval.ival, 10);
                        this.repeatCustom = "1";
                    }
                }
                if (rule.bymonth) {
                    this.repeatYearlyMonthsList = isArray(rule.bymonth) ? rule.bymonth[0].molist : rule.bymonth.molist;
                    this.repeatCustom = "1";
                }
                if (rule.bymonthday) {
                    if (this.repeatType === "YEA") {
                        this.repeatCustomMonthDay = <any> isArray(rule.bymonthday[0]) ?
                        rule.bymonthday[0].modaylist : rule.bymonthday.modaylist;

                        this.repeatCustomType = "S";
                    } else if (this.repeatType === "MON") {
                        this.repeatMonthlyDayList = isArray(rule.bymonthday) ? rule.bymonthday[0].modaylist.split(",")
                        : rule.bymonthday.modaylist.split(",");
                    }
                    this.repeatCustom = "1";
                }
                rule.byday = isArray(rule.byday) ? rule.byday[0] : rule.byday;
                if (rule.byday && rule.byday && rule.byday.wkday) {
                    this.repeatCustom = "1";
                    const wkday = rule.byday.wkday;
                    if (this.repeatType === "WEE" || (this.repeatType === "DAI" && wkday.length === 5)) {
                        this.repeatWeekday = this.repeatType === "DAI";
                        for (let x = 0; x < wkday.length; ++x) {
                            this.repeatWeeklyDays.push(wkday[x].day);
                        }
                    } else {
                        this.repeatCustomDayOfWeek = wkday.day;
                        const days = [];
                        for (let i = 0; i < wkday.length; i++) {
                            days.push(wkday[i].day);
                        }
                        this.repeatCustomDays = days;
                        this.repeatCustomOrdinal = wkday.ordwk;
                        if (rule.bysetpos) {
                          rule.bysetpos = isArray(rule.bysetpos) ? rule.bysetpos[0] : rule.bysetpos;
                        }
                        this.repeatBySetPos = (rule.bysetpos && (rule.bysetpos.length > 0)) ? rule.bysetpos.poslist : null;
                        if (this.repeatBySetPos === null && this.repeatCustomOrdinal) {
                            this.repeatBySetPos = this.repeatCustomOrdinal;
                        }
                        this.repeatCustomType = "O";
                    }
                }
                if (rule.until) {
                    this.repeatEndType = "D";
                    const dateItem = isArray(rule.until) ? rule.until[0].d : rule.until.d;
                    this.repeatEndDate = moment(dateItem).toDate();
                } else if (rule.count) {
                    this.repeatEndType = "A";
                    this.repeatEndCount = isArray(rule.count) ? rule.count[0].num : rule.count.num;
                }
            }
        }
    }
    this.changeDetector.markForCheck();
  }

  getBlurb() {
    if (this.repeatType === "NONE") {
      return "";
    }
    const every = [];
    switch (this.repeatType) {
      case "DAI": {
        if (this.repeatCustom === "1" && this.repeatWeekday) {
          this.translateService.get("CALENDARS.EVERY_WEEKDAY_LBL").pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        } else if (this.repeatCustomCount === 1) {
          this.translateService.get("CALENDARS.EVERYDAY_LBL").pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        } else {
          this.translateService.get("CALENDARS.EVERY_COUNT", {
            repeatCustomCount: this.repeatCustomCount
          }).pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        }
        break;
      }
      case "WEE": {
        if (this.repeatCustomCount === 1 && this.repeatWeeklyDays.length === 1) {
          const dayofweek = CalenderUtils.indexOf(this.weekList, this.repeatWeeklyDays[0]);
          const date = new Date();
          date.setDate(date.getDate() - date.getDay() + dayofweek);
          this.translateService.get("CALENDARS.EVERY_DATE", {
            date : this.datepipe.transform(date, "EEEE", "", this.browserLang)
          }).pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        } else {
          const weekdays = [];
          for (let i = 0; i < this.repeatWeeklyDays.length; i++) {
            const dayofweek = CalenderUtils.indexOf(this.weekList, this.repeatWeeklyDays[i]);
            const date = new Date();
            date.setDate(date.getDate() - date.getDay() + dayofweek);
            const formatDate = this.datepipe.transform(date, "EEEE", "", this.browserLang);
            weekdays.push(formatDate);
          }
          this.translateService.get("CALENDARS.EVERY_COUNT_WEEK_ON", {
            repeatCustomCount : this.repeatCustomCount,
            weekdays: weekdays
          }).pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        }
        break;
      }
      case "MON": {
        if (this.repeatCustomType === "S") {
          const count = Number(this.repeatCustomCount);
          const dates = this.repeatMonthlyDayList === null ? "" : Number(this.repeatMonthlyDayList[0]);
          this.translateService.get("CALENDARS.DAY_OF_EVERY_MONTH", {
            dates : dates,
            count: count
          }).pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        } else {
          const ordinal = !!this.repeatCustomOrdinal ? Number(this.repeatCustomOrdinal) : "";
          const bysetpos = !!this.repeatBySetPos ? Number(this.repeatBySetPos) : "";
          const dayofweek = CalenderUtils.indexOf(this.weekList, this.repeatCustomDayOfWeek);
          const day = new Date();
          day.setDate(day.getDate() - day.getDay() + dayofweek);
          const count = Number(this.repeatCustomCount);
          const days = this.repeatCustomDays.join(",");
          const workWeekDays = this.weekList.slice(1, 6).join(",");
          const weekEndDays = [this.weekList[0], this.weekList[6]].join(",");
          if (this.repeatCustomOrdinal !== null && this.repeatBySetPos !== null && this.repeatCustomOrdinal !== this.repeatBySetPos) {
            this.repeatCustomOrdinal = this.repeatBySetPos;
          }
          if ((this.weekList.join(",") === days) || (workWeekDays === days) || (weekEndDays === days)) {
            let dayType = -1;
            if (workWeekDays === days) {
              dayType = 1;
            } else if (weekEndDays === days) {
              dayType = 0;
            }
            this.translateService.get("CALENDARS.BY_POS_EVERY_MONTH", {
              bysetpos : (bysetpos || ordinal),
              dayType: dayType,
              count: count
            }).pipe(take(1)).subscribe(res => {
              every.push(res);
            });
          } else {
            const day = new Date();
            day.setDate(day.getDate() - day.getDay() + dayofweek);
            this.translateService.get("CALENDARS.BY_POS_EVERY_DAY_MONTH", {
              bysetpos : (bysetpos || ordinal),
              day: this.datepipe.transform(day, "MMMM", "", this.browserLang),
              count: count
            }).pipe(take(1)).subscribe(res => {
              every.push(res);
            });
          }
        }
        break;
      }
      case "YEA": {
        if (this.repeatCustomType === "S") {
          const month = new Date();
          month.setMonth(Number(this.repeatYearlyMonthsList) - 1);
          const dayss = this.repeatCustomMonthDay === undefined ? "" : Number(this.repeatCustomMonthDay);
          if (dayss !== "") {
            month.setDate(dayss);
          }
          this.translateService.get("CALENDARS.EVERY_YEAR_DAYS", {
            month : this.datepipe.transform(month, "MMMM d", "", this.browserLang),
            dayss: ""
          }).pipe(take(1)).subscribe(res => {
            every.push(res);
          });
        } else {
          const ordinal = !!this.repeatCustomOrdinal ? Number(this.repeatCustomOrdinal) : "";
          const bysetpos = !!this.repeatBySetPos ? Number(this.repeatBySetPos) : "";
          const dayofweek = CalenderUtils.indexOf(this.weekList, this.repeatCustomDayOfWeek);
          const month = new Date();
          month.setMonth(Number(this.repeatYearlyMonthsList) - 1 );
          const days = this.repeatCustomDays.join(",");
          const workWeekDays = this.weekList.slice(1, 6).join(",");
          const weekEndDays = [this.weekList[0], this.weekList[6]].join(",");
          if ((this.weekList.join(",") === days) || (workWeekDays === days) || (weekEndDays === days)) {
            let dayType = -1;
            if (workWeekDays === days) {
                dayType = 1;
            } else if (weekEndDays === days) {
                dayType = 0;
            }
            this.translateService.get("CALENDARS.EVERY_YEAR_POS", {
              bysetpos : (bysetpos || ordinal),
              month: month
            }).pipe(take(1)).subscribe(res => {
              every.push(res);
            });
          } else {
            const day = new Date();
            day.setDate(day.getDate() - day.getDay() + dayofweek);
            this.translateService.get("CALENDARS.YEAR_BY_DATES", {
              bysetpos : (bysetpos || ordinal),
              start: this.datepipe.transform(day, "MMMM d, y", "", this.browserLang),
              end: this.datepipe.transform(month, "MMMM", "", this.browserLang)
            }).pipe(take(1)).subscribe(res => {
              every.push(res);
            });
          }
        }
        break;
      }
    }
  const start = [];
  this.translateService.get("CALENDARS.EFFECTIVE_DATE", {
    effective: this.datepipe.transform(this._startDate, "MMMM d, y", "", this.browserLang)
  }).pipe(take(1)).subscribe(res => {
    every.push(res);
  });
  const end = [];
  switch (this.repeatEndType) {
    case "N": {
      this.translateService.get("CALENDARS.NO_END_DATE_MSG").pipe(take(1)).subscribe(res => {
        every.push(res);
      });
      break;
    }
    case "A": {
      this.translateService.get("CALENDARS.END_AFTER_END_COUNT", {
        repeatEndCount: this.repeatEndCount
      }).pipe(take(1)).subscribe(res => {
        every.push(res);
      });
      break;
    }
    case "D": {
      this.translateService.get("CALENDARS.END_BY_REPEAT_DATE", {
        repeatEndDate: this.datepipe.transform(this.repeatEndDate, "MMMM d, y", "", this.browserLang)
      }).pipe(take(1)).subscribe(res => {
        every.push(res);
      });
      break;
    }
  }
  const recurData: any[] = [];
  if (every.length > 0) {
    recurData.push(every.join(""));
  }
  if (end.length > 0) {
    recurData.push(end.join(""));
  }
  if (start.length > 0) {
    recurData.push(start.join(""));
  }
  this.blurb =  recurData.join(",");
  this.changeDetector.markForCheck();
  return this.blurb;
 }

 createExceptionReq(body: any): void {
    const oldDateTime = moment(this.appointment.startDateData[0].d).format("HHmmss");
    const selectedStartTime =  new Date(this.startDateFormControl.value);
    const selectDate = moment(selectedStartTime).format("YYYYMMDD");
    this.appointment.startDateData[0].d = selectDate + "T" + oldDateTime;
    body.inviteInfo[0].exceptId = {
      d: selectDate + "T" + oldDateTime,
      tz: this.appointment.startDateData[0].tz
    };
    if (this.isOnline) {
            this.commonService.createAppointmentException(body).pipe(take(1)).subscribe(res => {
        this.broadcaster.broadcast("UPDATE_LIST_VIEW");
        if (this.calendarRepository.selectingCalendarView !== "list") {
          this.calendarRepository.getAllAppointments();
        }
        this.close();
        this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
        this.clearUploadArray();
      }, err => {
        this.toastService.showPlainMessage(err);
        this.isSaveSendClick = false;
        this.changeDetector.markForCheck();
      });

    } else {
        let requestBody = { ...body };
        if (this.commonService.isFakeId(requestBody.id)) {
          delete requestBody.id;
        }
        if (this.commonService.isFakeId(requestBody.did)) {
          delete requestBody.did;
        }
        const request = {
          "url": "/api/createAppointmentException",
          "method": "post",
          "body":  requestBody
        };

        // fake id
        if (!body.id) {
          body.id = this.commonService.createFakeId(body?.mp[0].content);
        }
        this.databaseService.addPendingOperation(body.id, "createAppointmentException", request).subscribe(res => {
          const existingNewEvent = this.calendarRepository.createLocalAppointment(body, false);
          this.databaseService.getAppointmentsById(body.id).subscribe(resp => {
            const updatedAppointment = {...resp, ...existingNewEvent};
          this.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {

            this.broadcaster.broadcast("UPDATE_LIST_VIEW");
            if (this.calendarRepository.selectingCalendarView !== "list") {
              this.calendarRepository.getAllAppointments();
            }
            this.close();
            this.toastService.show("CALENDARS.APPOINTMENT_SENT_MSG");
            this.clearUploadArray();
          });
        });
      });
    }
  }

  valueChange(): void {
    this.isAppointmentChange = true;

    this.changeDetector.markForCheck();
  }

  changeEditor(): void {
    this.valueChange();
  }

  toggleSpelling() {
    const foundMisspelled = this.foundMisspelled;
    if (!!document.querySelector(".ql-editor")) {
        document.querySelector(".ql-editor").setAttribute("contenteditable", "true");
    }
    this.resetMisspelled();
    if (this.isPlainTextFormat && this.misspelledBody) {
        this.appointmentBodyData = this.misspelledBody;
    }
    if (!foundMisspelled) {
        this.checkSpelling();
    }
  }

  private resetMisspelled() {
    this.removeClickedWord();
    for (const misspelled of  Array.from(document.querySelectorAll(".misspelled"))) {
        const elm: HTMLElement = <HTMLElement> misspelled;
        elm.outerHTML = elm.innerText;
    }
    for (const misspelled of  Array.from(document.querySelectorAll(".misspelled-fixed"))) {
        const elm: HTMLElement = <HTMLElement> misspelled;
        elm.outerHTML = elm.innerText;
    }

    if (this.isPlainTextFormat && !!document.querySelector(".spell-check-plaintext")) {
        this.misspelledBody = document.querySelector(".spell-check-plaintext").innerHTML.replace(/<br>/gm, "\n");
    }
    this.spelling = [];
    this.isChecking = false;
    this.foundMisspelled = false;
    this.changeDetector.markForCheck();
  }

  resumeEditing() {
    this.resetMisspelled();
    if (this.isPlainTextFormat && this.misspelledBody) {
        this.appointmentBodyData = this.misspelledBody;
    }
    this.changeDetector.markForCheck();
    if (!!document.querySelector(".ql-editor")) {
        document.querySelector(".ql-editor").setAttribute("contenteditable", "true");
    }
  }

  @HostListener("document:click", ["$event"])
    handleClick(event: MouseEvent) {
      console.log("[handleClick]", event);
      const target = (<HTMLElement>event.target);
      this.removeClickedWord();
      if (target.className === "misspelled") {
        const word = atob(target.getAttribute("word"));
        const res = this.spelling.find(v => v.word === word);
        if (!!res) {
            let suggestions = res.suggestions.split(",");
            if (suggestions.length > 5) {
                suggestions = suggestions.splice(0, 5);
            }
            this.suggestions = suggestions;
            this.clickedWord = word;
            this.clickedTarget = target;
            this.changeDetector.markForCheck();
            target.classList.add("clicked-word");
            if (!CommonUtils.isOnMobileDevice()) {
                setTimeout(() => {
                    if (!!document.querySelector(".suggestions-list")) {
                        console.log("suggestions check top", this.suggestions);
                        const suggestionsList = <HTMLElement> document.querySelector(".suggestions-list");
                        suggestionsList.style.top = (event.pageY - target.offsetHeight + 30) + "px";
                        suggestionsList.style.left = (event.pageX - target.offsetWidth) + "px";
                        if (document.querySelector("body").clientHeight < (suggestionsList.offsetTop + suggestionsList.clientHeight)) {
                            const newTop =  (document.querySelector("body").clientHeight - suggestionsList.clientHeight) + "px";
                            suggestionsList.style.top = newTop;
                        }
                        if (document.querySelector("body").clientWidth < (suggestionsList.offsetLeft + suggestionsList.clientWidth)) {
                            suggestionsList.style.left = (document.querySelector("body").clientWidth
                            - suggestionsList.clientWidth - 20) + "px";
                        }
                        if (suggestionsList.offsetLeft < 12) {
                            suggestionsList.style.left = "12px";
                        }
                        suggestionsList.style.visibility = "visible";
                    }
                });
            } else {
                const suggestionsList = <HTMLElement> document.querySelector(".suggestions-list");
                if (!!suggestionsList) {
                    suggestionsList.style.visibility = "visible";
                }
            }
        } else {
            if (!!document.querySelector(".suggestions-list")) {
                const suggestionsList = <HTMLElement> document.querySelector(".suggestions-list");
                suggestionsList.style.visibility = "hidden";
                this.suggestions = [];
                this.changeDetector.markForCheck();
            }
        }
      } else {
        if (!!document.querySelector(".suggestions-list")) {
            const suggestionsList = <HTMLElement> document.querySelector(".suggestions-list");
            suggestionsList.style.visibility = "hidden";
            this.suggestions = [];
            this.changeDetector.markForCheck();
        }
    }
    }

    private removeClickedWord() {
        if (!!document.querySelector(".clicked-word")) {
            const clickedWord = <HTMLElement> document.querySelector(".clicked-word");
            clickedWord.classList.remove("clicked-word");
        }
    }

    ignoreWord() {
        this.fixWord();
        if (!this.ignoreList.includes(this.clickedWord)) {
            this.ignoreList.push(this.clickedWord);
        }
    }

    addWord() {
        this.fixWord();
        this.commonService.zimbraPrefSpellIgnoreWord(this.clickedWord).subscribe(res => {
            console.log("[zimbraPrefSpellIgnoreWord]", res);
        });
    }

    fixWord() {
        this.removeClickedWord();
        this.suggestions = [];
        if (!!this.clickedTarget) {
            this.clickedTarget.className = "misspelled-fixed";
        }
        const suggestionsList = <HTMLElement> document.querySelector(".suggestions-list");
        if (!!suggestionsList) {
            suggestionsList.style.visibility = "hidden";
        }
        this.changeDetector.markForCheck();
    }

    selectCorrectWord(word: string) {
        if (this.clickedTarget) {
            this.clickedTarget.innerText = word;
        }
        console.log("[selectCorrectWord]", word, this.spelling);
        this.fixWord();
        this.changeDetector.markForCheck();
    }

    checkSpelling() {
        this.isChecking = true;
        const selector = (<HTMLElement>document.querySelector(".ql-editor"));
        const plainTextEditor = (<HTMLElement>document.querySelector(".plain_text_editor textarea"));
        const spellCheckArea = (<HTMLElement>document.querySelector(".spell-check-plaintext"));
        let checkWord = "";
        if ( selector !== null || plainTextEditor !== null) {
            let request: any = {};
            let innerHTML = "";
            if (!!selector) {
                checkWord = selector.innerText;
                innerHTML = selector.innerHTML;
                request = {word: checkWord};
            } else {
                innerHTML = this.appointmentBodyData.replace(/\n/gm, "<br>");
                request = {word: this.appointmentBodyData};
            }
            if (this.ignoreList) {
                request.ignore = this.ignoreList.join(",");
            }
            console.log("[checkSpelling]", innerHTML);
            this.commonService.checkSpelling(request).pipe(takeUntil(this.isAlive$)).subscribe(res => {
                if ( res.misspelled && res.misspelled.length > 0) {
                    this.spelling = res.misspelled;
                    this.foundMisspelled = true;
                    innerHTML = MailUtils.encryptLinks(innerHTML);
                    console.log("[checkSpelling] 2", innerHTML);
                    res.misspelled.forEach(misspelled => {
                        const word = misspelled.word;
                        innerHTML = innerHTML.replace(new RegExp("\\b" + word + "\\b", "igm"),
                                `<misspelled class="misspelled" word="${btoa(word)}">${word}</misspelled>`);
                    });
                    innerHTML = MailUtils.decryptLinks(innerHTML);
                    if (!!selector) {
                        this.appointmentBodyData = innerHTML;
                    } else {
                        if (!!spellCheckArea) {
                            spellCheckArea.innerHTML = innerHTML;
                        }
                    }
                    this.changeDetector.markForCheck();
                    console.log("[checkSpelling] 3", res, innerHTML);
                } else {
                    this.foundMisspelled = false;
                    this.spelling = [];
                    this.changeDetector.markForCheck();
                    this.toastService.show("SPELLING_CORRECT");
                }
            }, error => {
                this.foundMisspelled = false;
                this.changeDetector.markForCheck();
                this.toastService.showPlainMessage(error);
                if (!!document.querySelector(".ql-editor")) {
                    document.querySelector(".ql-editor").setAttribute("contenteditable", "true");
                }
            });
        }
    }

    checkAgain() {
      console.log("[checkAgain]");
      this.resetMisspelled();
      if (this.isPlainTextFormat && this.misspelledBody) {
          this.appointmentBodyData = this.misspelledBody;
      }
      this.checkSpelling();
    }

    getAppointmentBody(appointment: Appointment): string {
      let body: string = "";
      if (appointment.descHTML) {
        body = appointment.descHTML;
      } else if (appointment.desc) {
        body = CalenderUtils.plainTextToHTML(appointment.desc);
      }
      if (body.indexOf("~*~*~*~*~*~*~*~*") > -1 || body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") > -1) {
        if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
          body = body.replace("</body></html>", "").split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
          body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
        }
        if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
          body = body.replace("</body></html>", "").split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
        }
      }
      const description = MailUtils.addClassToAnchor(MailUtils.replaceLinkToAnchor(body));
      return description;
    }

    isRequestResponsesEnabled(): void {
      const requestee = [
        ...this.getAppointmentRequestee(),
        ...this.getAppointmentOptionalRequestee(),
        ...this.getAppointmentEquipmentRequestee(),
        ...this.getAppointmentLocationRequestee()
      ];
      if (!!requestee && requestee.length > 0) {
        this.rsvpDisabled = false;
      } else {
        this.rsvpDisabled = true;
      }

    }

    onConflict(data) {
      console.log("[onConflict]", data);
      this.isConflicted = data?.isConflicted;
      this.locationConflict = data?.locationConflict;
      if (this.attendeeAutoComplete) this.attendeeAutoComplete.setConflictEmails(data?.conflictEmails || {});
      if (this.optionalAutoComplete) this.optionalAutoComplete.setConflictEmails(data?.conflictEmails || {});
      if (this.locationAutoComplete) this.locationAutoComplete.setConflictEmails(data?.conflictEmails || {});
      this.conflictEmails = data?.conflictEmails;
      this.changeDetector.markForCheck();
    }

    selectSuggestedTime(data) {
      this.startDateFormControl.patchValue(new Date(data.startTime));
      this.endDateFormControl.patchValue(new Date(data.endTime));
      this.startTimeControl = new Date(data.startTime);
      this.endTimeControl = new Date(data.endTime);
      console.log("[selectSuggestedTime]", data);
      this.isConflicted = false;
      this.locationConflict = false;
      this.conflictEmails = {};
      this.attendeeAutoComplete.setConflictEmails({});
      this.optionalAutoComplete.setConflictEmails({});
      this.locationAutoComplete.setConflictEmails({});
      this.changeDetector.markForCheck();
      if (this.schedulerComponent) {
        this.schedulerComponent.setDateInfo(this.startDateFormControl.value, this.endDateFormControl.value, this.getTimeZoneValue(this.startTimeZone.key), this.startTimeControl, this.endTimeControl, !this.appointment.allDay, this.appointment.allDay);
        setTimeout(() => {
          this.schedulerComponent.postUpdateHandler();
        }, 1000);
      }
    }

    get requiredAttendees() {
      return this.attendeeAutoComplete ? this.attendeeAutoComplete.getSelectedEmail() : [];
    }

    changeLocation(location) {
      this.locationAutoComplete.resetEmail();
      this.locationAutoComplete.resetInputValue();
      const info: any = { email: location.email, t: "", fullName: location.locationObj?.fullName };
      this.locationAutoComplete.setEmailField(info);
      this.valueChange();
      this.schedulerComponent.resetAttendeesByType([location.email], "LOCATION");
    }

    copyCallURL(): void {
      MailUtils.copyToClipboard([this.appointment.loc]);
    }

    handleUploadClick () {
      if (this.commonRepository.showNoInternetToastIfRequired()) {
        return;
      }
      this.mobileAttachmentUpload.nativeElement.click();
    }

    videoToggle(event) {
      console.log("videoToggle event", event.checked);
    }

    changeinstanceWithDrag(value: string, event: any, startDate: Date, endDate: Date) {
      let bodyItem = {
        id:   event.invId,
        ridz: event.inst[0].ridZ
      };
      this.commonService.getMsgRequest(bodyItem).pipe(take(1)).subscribe(res => {
        if (!!res && res.m && isArray(res.m)) {
          const appt = this.calendarRepository.mapAppointmentFromMsg(res.m[0]);
          const timezone = jstimezonedetect.determine().name();
          const body = this.getAppoitmentData(appt, startDate, endDate);

          delete body.inviteInfo[0].recur;
          const todayDate = moment(event.inst[0].ridZ);
          const sDate = moment(appt.startDateData[0].d);
          const hours = parseInt(sDate.format("HH"), 10);
          const mm = parseInt(sDate.format("mm"), 10);
          todayDate.set({ hour: hours, minute: mm });
          const exceptDate = todayDate.format("YYYYMMDDTHHmmss");
          console.log("moment(appt.startDateData[0].d", moment(appt.startDateData[0].d));
          body.inviteInfo[0].exceptId = {
            tz: timezone,
              d: exceptDate
          };
          body.inviteInfo[0].draft = 0;
           this.commonService.createAppointmentException(body).pipe(take(1)).subscribe(resp => {
              this.calendarRepository.getAllAppointments();
              this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
                this.calendarRepository.updateCalendarAppointment(event);
            });
          }
      });


    }

    getAppoitmentData(appt: any, startDate: Date, endDate: Date): any {
      let appointmentBody: string = "";
      if (appt.descHTML) {
        appointmentBody = appt.descHTML;
      } else if (appt.desc) {
       appointmentBody = MailUtils.plainTextToHTML(appt.desc);
      }
      const timezone = jstimezonedetect.determine().name();
      const startTimeStamp = moment(startDate).format("YYYYMMDDTHHmmss");
      const endTimeStamp = moment(endDate).format("YYYYMMDDTHHmmss");
      const folder = this.getFolderById(appt.ciFolder);

      const body: any = {
        id: this.appointment.id,
        subject: this.appointment.name,
        uid: appt.uid,
        emailInfo: this.getEmailInformation(),
        folderId: appt.ciFolder,
        comp: "0",
        ms: appt.ms,
        rev: appt.rev,
        mp: [{
          ct: "text/html",
          content: this.appointmentBodyData
        }],
        inviteInfo: [{
          allDay: appt.allDay ? "1" : "0",
          at: !!appt.at ? appt.at : [],
          class: appt.class,
          e: {
            tz: timezone,
            d: endTimeStamp
          },
          s: {
            tz: timezone,
            d: startTimeStamp
          },
          or: {
            a: appt.or.a
          },
          loc: appt.loc,
          status: "CONF",
          transp: appt.transp,
          fb: appt.fb,
          name: this.appointment.name,
          recur: appt.recur
        }]
      };

      if (folder.perm) {
        const rootFolder = this.calendarRepository.getRootFolder(folder);
        const fromA = this.getFromAddress();
        body.inviteInfo[0].or.sentBy = fromA;

        body.inviteInfo[0].or = {
          a: this.organizerFromAddress,
          sentBy: fromA
        };
      }
      return body;
    }

    getEmailInformationInstance(appt: any): any {
      const emailInfo: any[] = [];
      const folder = this.getFolderById(appt.ciFolder);
      if (folder.perm) {
        const rootFolder = this.calendarRepository.getRootFolder(folder);
        if (folder.owner) {
          emailInfo.push({
            a: rootFolder.owner,
            t: "f"
          }, {
            a: this.currentUser.email,
            t: "s"
          });
        } else {
          emailInfo.push({
            a: this.currentUser.email,
            t: "f"
          }, {
            a: this.currentUser.email,
            t: "s"
          });
        }
      } else {
        emailInfo.push({ a: this.currentUser.email, t: "f" });
      }
      return emailInfo;
    }
    onChangeTime(inputValue: string, type): void {
      if (type === "start") {
        this.validStartTime = false;
      } else {
        this.validEndTime = false;
      }

      if (this.browserLang === "en") {
        const timePatternEn = /^(0[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i;
        if (timePatternEn.test(inputValue)) {
          const [_, hour, minute, period] = inputValue.match(timePatternEn)!;
          const hours = period.toUpperCase() === "PM" ? +hour % 12 + 12 : +hour % 12;
          if (type === "start") {
            this.startTimeControl.setHours(hours, +minute, 0);
            this.validStartTime = true;
            this.handleStartTimeChanges(this.startTimeControl);
          } else {
            this.endTimeControl.setHours(hours, +minute, 0);
            this.validEndTime = true;
            this.handleEndTimeChanges(this.endTimeControl);
          }
        }
      }
      if (this.browserLang === "de") {
        const timePatternDe = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
        if (timePatternDe.test(inputValue)) {
          const [_, hour, minute] = inputValue.match(timePatternDe)!;
          if (type === "start") {
            this.startTimeControl.setHours(+hour, +minute, 0);
            this.validStartTime = true;
            this.handleStartTimeChanges(this.startTimeControl);
          } else {
            this.endTimeControl.setHours(+hour, +minute, 0);
            this.validEndTime = true;
            this.handleEndTimeChanges(this.endTimeControl);
          }
        }
      }
    }

    moveAppointmentOp(body) {
      return new Promise((resolve, reject) => {
        this.commonService.itemAction(body).pipe(take(1)).subscribe(res => {
          resolve(res);
        }, err => {
          console.log("moveAppointmentOp Error: ", err);
          if (err.indexOf("operation requires change of organizer") > -1) {
            this.toastService.show("CALENDARS.CANNOT_MOVE_DIFF_ORGANIZER_OWNER");
          } else {
            this.toastService.show("CALENDARS.CANNOT_MOVE");
          }
          reject(err);
        });
      });
    }

  maybeSetOrganizerFrom(owner) {
    if (!!owner) {
      if (this.isOnline) {
        const payload = {
          name: owner,
          limit: 50
        }
        this.mailService.searchZGalRequest(payload).subscribe(res => {
          if (!!res && !!res.cn && !!res.cn[0] && !!res.cn[0]._attrs) {
            console.log("changeFolder addFolderOwner searchZGal res: ", res.cn[0]._attrs, owner);
            const attrs = Object.keys(res.cn[0]._attrs);
            const filteredAttrs = attrs.filter(attr => attr.startsWith("email"));
            console.log("changeFolder addFolderOwner searchZGal filtered: ", filteredAttrs);
            let emails = [];
            filteredAttrs.forEach(attr => {
              emails.push(res.cn[0]._attrs[attr]);
            });
            console.log("changeFolder addFolderOwner searchZGal filtered emails: ", emails);
            if (this.preferenceService.sendAsAndBehalf.length > 0) {
              const sendAsAndBehalf = this.preferenceService.sendAsAndBehalf.map(a => a.value);
              console.log("changeFolder addFolderOwner sendAsAndBehalf: ", sendAsAndBehalf);
              let haveSendAsSet = false;
              emails.forEach(email => {
                if (sendAsAndBehalf.indexOf(email) > -1) {
                  haveSendAsSet = true;
                  this.organizerFromAddress = email;
                }
              });
              if (!haveSendAsSet) {
                this.organizerFromAddress = owner;
              }
            } else {
              this.organizerFromAddress = owner;
            }
          }
        }, err => {
          console.log("err", err);
        });
      } else {
        this.organizerFromAddress = owner;
      }
    }
    // console.log("changeFolder addFolderOwnerToFromMenu", owner, this.externalAccountFrom);
  }
}
